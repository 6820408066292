import React, { useState, useContext } from 'react';
import { Card, CardBody, CardSubtitle, CardTitle, Button } from 'reactstrap';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { SocketContext } from 'main/context/socket';
import { CurrencyFormat } from 'utility';
import ItemDetailsModal from './ItemDetailsModal';
import ItemDetailsSmallModal from './ItemDetailsSmallModal';
import '../../styles.scss';

const SupplyItemCard = ({ data, isMonitor, is_view, getSupplyItem, tabValue }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [smallItemToggle, setSmallItemToggle] = useState(false);
  const [itemDetailsToggle, setItemDetailsToggle] = useState(false);

  const handleItemToggle = () => {
    setItemDetailsToggle(!itemDetailsToggle);
  };

  const handleSmallItemToggle = () => {
    setSmallItemToggle(!smallItemToggle);
  };
  return (
    <div className={`${tabValue === 'available' && data?.totalDuplicateLot > 0 ? 'hasdublicate' : ''}`}>
      <div className="border-box"> </div>
      {tabValue === 'available' && data?.totalDuplicateLot > 0 && (
        <div className="lot-count">
          <span className="d-flex justify-content-center align-items-center rounded-circle">
            {'x' + (data?.totalDuplicateLot + 1)}
          </span>
        </div>
      )}
      <div className="wonItemPrizeCard supply-item-cart h-100">
        <Card className="h-100">
          <div
            className={data?.status === 'draft' || data?.auctionItem?.isSold ? 'imgWrapper imageOverlay' : 'imgWrapper'}
            style={{
              background: `url(${identifiers.imageUrlConstant + '' + data?.images[0]?.name})`,
            }}>
            {data?.status === 'draft' || data?.auctionItem?.isSold ? (
              <div className="image-overlay-wrap">
                <img
                  alt="sold item"
                  src={
                    data?.auctionItem?.isSold
                      ? images.prizeIcon
                      : data?.status === 'draft'
                        ? images.itemcardOverlayImage
                        : images.itemcardOverlayImage
                  }
                  className="draft-icon"
                />
              </div>
            ) : (
              <img
                alt={data?.name}
                src={identifiers.imageUrlConstant + '' + data?.images[0]?.name}
                onError={e => {
                  e.target.src = images?.logo; // Set the default image
                  e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
                }}
                onLoad={e => {
                  if (e.target.src !== window.location.origin + images?.logo) {
                    // Check if the image is not the default
                    e.target.className = ''; // Reset the class name
                  }
                }}
              />
            )}
          </div>
          <CardBody>
            <CardTitle
              style={{
                marginBotton: data?.name !== null ? '26px' : '0px',
                marginTop: '16px',
                paddingBottom: data?.name !== null ? '0px' : '26px',
              }}>
              {data?.name !== null ? data?.name : ''}
            </CardTitle>

            <CardSubtitle className="mb-2 item-status">
              {OKTION_VARIABLE?.OKTION_STATUS} : {data?.itemStatus}
            </CardSubtitle>
            <p className="fs-16 mb-1">
              {OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT} :
              <CurrencyFormat
                value={data?.reserveAmount !== null ? data?.reserveAmount : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
              />
            </p>
            <div className="d-flex">
              {!isMonitor && (
                <Button
                  className="app-button btn-block main-button me-2 w-100 mt-2 fw-500 fs-20 h-60"
                  onClick={handleItemToggle}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_LOT_DETAILS}
                </Button>
              )}
              {isMonitor && (
                <Button
                  className="app-button btn-block main-button me-2 w-100 mt-2 h-60 fw-500 fs-20"
                  onClick={handleSmallItemToggle}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_MONITOR_LOT}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
        {itemDetailsToggle && (
          <ItemDetailsModal
            isToggle={itemDetailsToggle}
            toggle={handleItemToggle}
            isUpdate={true}
            propsData={data}
            getSupplyItem={getSupplyItem}
            tabValue={tabValue}
          />
        )}
        {smallItemToggle && (
          <ItemDetailsSmallModal
            isToggle={smallItemToggle}
            toggle={handleSmallItemToggle}
            lotData={data}
            getSupplyItem={getSupplyItem}
          />
        )}
      </div>
    </div>
  );
};

export default SupplyItemCard;
