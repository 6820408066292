import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: '18px',
    lineHeight: '18px',
    '&.MuiInputLabel-shrink': {
      fontSize: '13.84px',
      lineHeight: '18px',
      top: '1px',
      left: '1px',
    },
    '&.Mui-focused': {
      color: '#FFC629 !important',
    },
    '&.Mui-error': {
      color: '#e00101',
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    color: '#191919',
    borderRadius: 8,

    '& .MuiOutlinedInput-input': {
      padding: '13px',
      fontSize: '18px',
      lineHeight: '23px',
      height: 'auto',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid #FFC629',
    },
    '&:hover fieldset': {
      borderColor: '#FFC629',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E0E0E0',
    // For lable right spacing issue
    '& span': {
      fontSize: '11px',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
  },
  '& .MuiFormHelperText-root': {
    '&.Mui-error': {
      color: '#e00101',
    },
  },
  '& .Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e00101',
    },
  },
  '& .MuiInputAdornment-root': {
    '& .MuiSvgIcon-root': {
      fill: '#818181',
    },
  },
}));
