import React, { useState, useEffect, useContext } from 'react';
import { Button, Label, InputGroup, Modal, ModalBody, UncontrolledPopover, PopoverBody, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import moment from 'moment';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from 'library/common/constants/ImageConstants';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from 'main/axios';
import { Errors } from 'library/common/constants/ErrorMessage';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { isFromBiggerThanTo, setApiMessage } from 'library/common/constants/function';
import { dashBoardData } from 'library/common/actions/FeatureAction';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import ToggleSwitch from 'library/common/components/Toggle/ToggleSwitch';
import FileUpload from 'library/common/components/FileUpload';
import ImageDisplay from 'library/common/components/FileUpload/frames/ImageDisplay';
import LotLimiteModal from './lotLimitModal';
import DeleteLotModal from './DeleteLotModal';
import PageLoader from 'library/common/components/PageLoader';
import MarkAsReadModal from '../../Frame/MarkAsRead/MarkAsReadModal';
import './styles.scss';

const AddItemModal = ({
  isToggle,
  toggle,
  isUpdate,
  data,
  featureAuctionData,
  categoryList,
  getAuctionDetails,
  closeModal,
  dashBoardData,
  currency,
  isModal,
  currentPage,
  list,
  dropDownvalue,
  feturedData,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [dragId, setDragId] = useState();
  const [loading, setLoading] = useState(false);
  const [charsLotAbout, setCharsLotAbout] = useState(2000);
  const [charsLotName, setCharsLotName] = useState(75);
  const [featureLotSwitchVal, setFeatureLotSwitchVal] = useState(isUpdate ? data?.notifyUsers : false);
  const [notifyUserSwitchVal, setNotifyUserSwitchVal] = useState(data?.featuredItem ? data?.featuredItem : false);
  const [imageLength, setImageLength] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [markAsPaid, setMarkIsPaid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [markAspaid, setMarkaspaid] = useState(false);
  const [toggleMarkAsPaidVal, setToggleMarkAsPaidVal] = useState(false);
  const [islimitToggle, setLimitToggle] = useState(false);
  const [finalImageArray, setFinalImageArray] = useState([]);
  const [isClaimed, setClaimed] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isClaimedStatus, setIsClaimedStatus] = useState(false);
  const [isMarkPaidStatus, setIsMarkPaidStatus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  let latestImageArray = [
    { name: '', displayOrder: 1, preview: '', id: 'Box-1' },
    { name: '', displayOrder: 2, preview: '', id: 'Box-2' },
    { name: '', displayOrder: 3, preview: '', id: 'Box-3' },
    { name: '', displayOrder: 4, preview: '', id: 'Box-4' },
    { name: '', displayOrder: 5, preview: '', id: 'Box-5' },
    { name: '', displayOrder: 6, preview: '', id: 'Box-6' },
  ];

  const parseToFixed = (value, decimals = 2) =>
    value !== null && value !== undefined ? parseFloat(value).toFixed(decimals) : '';

  const defaultValue = {
    lotname: data?.itemName ?? '',
    automaticdraw: data?.itemAbout ?? '',
    providedBy: data?.providedBy ?? '',
    lotprovidername: data?.itemProvidedByName ?? '',
    startingbid: parseToFixed(data?.startBid),
    increment: parseToFixed(data?.bidIncrement),
    reverse_amount: parseToFixed(data?.reserveAmount),
    buyitnow: parseToFixed(data?.buyItNow),
    postage_handlingamt: parseToFixed(data?.postageAndHandeling),
    categoryId: data?.categoryId ?? null,
    postage_handlingnotes: data?.postageHandlingNote ?? '',
    lotnumber: data?.lotNumber ?? null,
    messageUsers: data?.message ?? '',
    duplicateLot: data?.totalDuplicateLot ?? '',
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
    reset,
  } = useForm({ defaultValues: defaultValue });

  // handle feature lot switch
  const handleFeatureLotSwitch = val => {
    setFeatureLotSwitchVal(val);
  };
  // handle notify user switch
  const handleNotifyUserSwitch = val => {
    if (feturedData && feturedData >= 6) {
      if (notifyUserSwitchVal) {
        setNotifyUserSwitchVal(val);
      } else {
        setLimitToggle(!islimitToggle);
      }
    } else {
      setNotifyUserSwitchVal(val);
    }
  };
  useEffect(() => {
    reset(defaultValue);

    setAmount(data?.amountPaid ?? 0);
    setMarkaspaid(data?.markAsPaid ?? false);
    setClaimed(data?.markAsClaimed ?? false);

    var today = new Date();
    var time = `${today.getHours()}:${today.getMinutes()}`;
    let featureEndDate = moment(featureAuctionData?.[0]?.endTime).format('YYYY-MM-DD');
    let featureEndTime = moment(featureAuctionData?.[0]?.endTime).format('HH:mm');

    // disable value when date is past
    var currentDate = `${moment(today).format('MM/DD/YYYY')} ${time}`;
    var auctionEndDateTime = `${moment(featureEndDate).format('MM/DD/YYYY')} ${featureEndTime}`;

    if (data?.status === 'published') {
      setIsDisabled(isFromBiggerThanTo(auctionEndDateTime, currentDate));
    }
    const updatedImageArray = [...latestImageArray];
    data?.images?.forEach((item, i) => {
      const boxId = `Box-${i + 1}`;
      const tempObj = {
        name: `${identifiers.imageUrlConstant}${item?.name}`,
        preview: `${identifiers.imageUrlConstant}${item?.name}`,
        displayOrder: item?.displayOrder,
        imagename: item?.name,
        id: boxId,
      };

      const index = latestImageArray?.findIndex(e => e?.displayOrder === tempObj?.displayOrder);
      if (index !== -1) {
        updatedImageArray[index] = tempObj;
      }
    });

    setFinalImageArray(updatedImageArray);
  }, [data, reset, featureAuctionData]);

  useEffect(() => {
    if (!isUpdate) {
      const handleRemoveImage = () => setFinalImageArray(latestImageArray);
      EventEmitter.subscribe('removeauctionlotimage', handleRemoveImage);
      return () => {
        EventEmitter.unsubscribe('removeauctionlotimage', handleRemoveImage);
      };
    }
  }, [isUpdate]);
  // handle lot name limit
  const handleLotNameLimit = event => {
    const valueLength = 75 - event.target.value.length;
    setCharsLotName(valueLength);
  };
  // handle lot about limit
  const handleLotAboutLimit = event => {
    const valueLength = 2000 - event.target.value.length;
    setCharsLotAbout(valueLength);
  };
  // handle mark as paid amount
  const handleAmountChange = e => {
    const value = e.target.value;
    setAmount(value);
  };
  // handle onsubmit reserve amount validate
  const validateReserveAmount = () => {
    if (parseInt(watch('reverse_amount')) < parseInt(watch('startingbid'))) {
      return OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  // handle onsubmit buy it now amount validation
  const validateBuyItNowAmount = () => {
    if (parseInt(watch('buyitnow')) !== 0 && parseInt(watch('buyitnow')) < parseInt(watch('reverse_amount'))) {
      return OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  // handle mark as paid confirm value
  const handleConfirm = val => {
    setMarkaspaid(val);
    handleMarkAsPaid();
    setToggleMarkAsPaidVal(false);
    setIsMarkPaidStatus(true);
  };
  // handle file validaion check
  const handleFileUpload = (file, index) => {
    if (file[0] && file[0].size <= 10485760) {
      uploadImage(file, index);
      setImageLength(false);
    } else {
      setImageLength(true);
    }
  };
  // handle media upload API
  const uploadImage = async (file, index) => {
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.multipleImageUpload;

    file?.forEach(item => body.append('file', item));

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 201) {
        const uploadedImages = data?.files || [];
        const updatedImages = [...finalImageArray];

        uploadedImages?.forEach((item, i) => {
          const tempObj = {
            name: identifiers.imageUrlConstant + item,
            preview: identifiers.imageUrlConstant + item,
            displayOrder: index + i + 1,
            imagename: item,
            id: `Box-${index + i + 1}`,
          };

          const existingIndex = updatedImages.findIndex(e => e.displayOrder === tempObj.displayOrder);

          if (existingIndex !== -1) {
            updatedImages[existingIndex] = tempObj;
          } else {
            updatedImages.push(tempObj);
          }
        });
        setFinalImageArray(updatedImages);

        const nonEmptyImages = updatedImages.filter(item => item?.name !== '');
        setIsImage(nonEmptyImages.length === 0);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // handle toggle delete modal
  const toggleLotDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };
  // handle remove image
  const handleRemoveImage = element => () => {
    // Update the array immutably
    const updatedArray = finalImageArray?.map((item, index) => {
      if (item?.displayOrder === element?.displayOrder) {
        // Clear properties for the matching image
        return {
          ...item,
          name: '',
          preview: '',
          imagename: '',
        };
      }
      return item;
    });

    // Update the state with the new array
    setFinalImageArray(updatedArray);

    // Check if there are any remaining images with a valid name
    const hasImages = updatedArray.some(item => item?.name !== '');
    setIsImage(!hasImages); // Set image state based on the presence of valid images
  };
  // handle mark as paid
  const handleMarkAsPaid = () => {
    setMarkIsPaid(!markAsPaid);
    setToggleMarkAsPaidVal(!toggleMarkAsPaidVal);
  };

  // handle save details
  const onSubmit = async (dataValue, addstatus) => {
    const prepareFinalImageArray = () =>
      finalImageArray
        ?.filter(item => item?.name !== '')
        ?.map(item => ({
          name: item?.imagename,
          displayOrder: item?.displayOrder,
        }));

    const finalImage_Array = prepareFinalImageArray();

    var {
      lotname,
      lotnumber,
      automaticdraw,
      providedBy,
      lotprovidername,
      startingbid,
      increment,
      reverse_amount,
      buyitnow,
      postage_handlingamt,
      postage_handlingnotes,
      duplicateLot,
      categoryId,
      othercategory,
      messageUsers,
    } = watch();

    var eventDetails = fetchFromStorage(identifiers.eventDetails);

    if (categoryId === 'other') {
      var requestData = {
        categoryName: othercategory,
        eventId: eventDetails?.id,
      };
      const { data } = await axiosInstance.post(URLS.getCategory, requestData);
      var otherCatId = data?.data?.createdCategory?.id;
    }
    var categoryValue = categoryId === 'other' ? otherCatId : categoryId;
    var lotnotype = featureAuctionData?.[0]?.eventFeatureOptions?.find(item => item.key === 'lotnotype');

    var sendData = {
      type: 'auction',
      eventId: eventDetails?.id,
      status: featureAuctionData?.[0]?.status,
      auctionOptions: [
        {
          ...(isUpdate && { id: data?.id }),
          itemName: lotname,
          ...(lotnotype?.value !== 'sequential' && lotnumber && { lotNumber: lotnumber }),
          itemAbout: automaticdraw,
          providedBy: providedBy,
          itemProvidedByName: lotprovidername,
          images: finalImage_Array,
          ...(startingbid && { startBid: Number(startingbid) }),
          ...(increment && { bidIncrement: Number(increment) }),
          ...(reverse_amount && { reserveAmount: Number(reverse_amount) }),
          ...(buyitnow && { buyItNow: Number(buyitnow) }),
          ...(postage_handlingamt && { postageAndHandeling: postage_handlingamt }),
          ...(postage_handlingnotes && { postageHandlingNote: postage_handlingnotes }),
          featuredItem: notifyUserSwitchVal,
          notifyUsers: featureLotSwitchVal,
          message: featureLotSwitchVal ? messageUsers : '',
          markAsClaimed: isClaimed,
          markAsPaid: markAspaid,
          amountPaid: Number(amount),
          status: addstatus,
          duplicateLots: Number(duplicateLot) || 0,
          ...(categoryValue && { categoryId: categoryValue }),
          winnerId: data?.winnerId || null,
          reserveMet: data?.reserveMet || '',
        },
      ],
    };

    if (!isImage) {
      setLoading(true);
      try {
        const { status, data } = await axiosInstance.put(URLS.deleteFeature(featureAuctionData?.[0]?.id), sendData);
        if (status === 200 || status === 304) {
          setDeleteModalOpen(false);
          setLoading(false);
          getAuctionDetails(dropDownvalue, currentPage, list, 1, '');
          dashBoardData(eventDetails?.id);

          setApiMessage(
            'success',
            addstatus === 'deleted'
              ? OKTION_VARIABLE?.OKTION_LOT_DELETE
              : (addstatus === 'published' || addstatus === 'saved') && isUpdate !== true
                ? OKTION_VARIABLE?.OKTION_LOT_ADDED
                : isUpdate === true
                  ? OKTION_VARIABLE?.OKTION_LOT_UPDATED
                  : data.msg,
          );

          setTimeout(() => {
            isModal && closeModal();
            if (isUpdate !== true) {
              setFinalImageArray(latestImageArray);
              setNotifyUserSwitchVal(false);
            }
          }, 4000);
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          if (err?.response?.data?.msg) {
            var errorMessage = err?.response?.data?.msg;
            setApiMessage('error', errorMessage);
          } else {
            var errorMessage =
              err?.response?.data?.validation && err?.response?.data?.validation['body']?.message !== undefined
                ? err?.response?.data?.validation['body']?.message
                : OKTION_VARIABLE?.OKTION_DONATION_CHANGES_NOT_SUCCESSFULLY;
            setApiMessage('error', errorMessage);
          }
        } else {
          if (err.response) {
            setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
          }
        }
      }
    } else {
      setApiMessage('error', OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION_MSG);
    }
    setIsClaimedStatus(false);
    setIsMarkPaidStatus(false);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  // handle close modal
  const handleClose = () => {
    if (isUpdate) {
      setFinalImageArray(latestImageArray);
    }
    setIsClaimedStatus(false);
    setIsMarkPaidStatus(false);
  };

  // handle event status
  const handleEventStatus = () => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    let current_time = moment(new Date()).format('HHmm');
    const eventDetails = fetchFromStorage(identifiers.eventDetails);

    if (eventDetails?.status === 'saved') {
      return 'Draft';
    } else {
      let eventEndDate = moment(eventDetails?.endDate).format('YYYY-MM-DD');
      let eventEndTime = moment(eventDetails?.endDate).format('HHmm');
      if (moment(today).isAfter(eventEndDate)) {
        return 'Closed';
      } else {
        if (today === eventEndDate && eventEndTime < current_time) {
          return 'Closed';
        } else {
          return 'Active';
        }
      }
    }
  };
  // handle claim lot payment
  const handleClaimed = async () => {
    setClaimed(true);
    setIsClaimedStatus(true);
  };
  const handleDrag = ev => {
    setDragId(ev.currentTarget.id);
  };
  const handleDrop = ev => {
    const dragBox = finalImageArray.find(box => box.id === dragId);
    const dropBox = finalImageArray.find(box => box.id === ev.currentTarget.id);
    const dragBoxOrder = dragBox.displayOrder;
    const dropBoxOrder = dropBox.displayOrder;

    const newBoxState = finalImageArray.map(box => {
      if (box.id === dragId) {
        box.displayOrder = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.displayOrder = dragBoxOrder;
      }
      return box;
    });
    setFinalImageArray(newBoxState);
  };

  var isEventStatus = handleEventStatus();

  const disableReservAmt = data?.currentBid >= 1;
  const isFieldDisable = (data?.supplyItemId !== null || data?.markAsPaid || disableReservAmt) && isUpdate;

  const isSupplyFieldDisable =
    data?.supplyItemId !== null && !data?.markAsPaid
      ? false
      : data?.supplyItemId !== null && data?.markAsPaid
        ? true
        : data?.supplyItemId === null && data?.markAsPaid === false
          ? false
          : true;

  var lotnotype =
    featureAuctionData && featureAuctionData[0]?.eventFeatureOptions?.find(item => item.key === 'lotnotype');

  var image_Length = finalImageArray.filter(item => item?.name !== '');

  // handle event feature status
  const eventFeatureStatus = () => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    let current_time = moment(new Date()).format('HHmm');

    if (featureAuctionData?.[0]?.status === 'saved') {
      return 'Draft';
    } else {
      let featureEndDate = moment(featureAuctionData?.[0]?.endTime).format('YYYY-MM-DD');
      let featureEndTime = moment(featureAuctionData?.[0]?.endTime).format('HHmm');
      if (moment(today).isAfter(featureEndDate)) {
        return 'Closed';
      } else {
        if (today === featureEndDate && featureEndTime < current_time) {
          return 'Closed';
        } else {
          return 'Active';
        }
      }
    }
  };

  return (
    <>
      <div className="AddPriceModal AddItemModal " id="modalIds">
        <Modal size="lg" className="customModal add-price-modal maxwidth-900 addLotModal" isOpen={isToggle}>
          {loading && <PageLoader />}
          <div className="text-end" onClick={() => handleClose()}>
            <img className="close-icon cursor-pointer" onClick={toggle} src={images.close} alt="" />
          </div>
          <div className="d-flex modalHeader">
            <div className="title-unclaimedIcon">
              <h2>
                {isUpdate ? OKTION_VARIABLE?.OKTION_EDIT : OKTION_VARIABLE?.OKTION_ADD}
                {OKTION_VARIABLE?.OKTION_LOT}
              </h2>
              {isUpdate ? (
                <div>
                  <img
                    src={
                      data?.markAsClaimed === true || isClaimed === true
                        ? images.claimed
                        : data?.markAsClaimed === false || isClaimed === false
                          ? images.unClaimed
                          : ''
                    }
                    alt="unClaimed"
                    className={`${
                      (eventFeatureStatus() === 'Closed' || data?.isSold) &&
                      (data?.markAsClaimed === false || isClaimed === false)
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }`}
                    onClick={() => {
                      if (
                        (eventFeatureStatus() === 'Closed' || data?.isSold) &&
                        (data?.markAsClaimed === false || isClaimed === false)
                      ) {
                        handleClaimed();
                      }
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <ModalBody>
            <div className="addItemModalForm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="lot-Images-block">
                  {finalImageArray
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map((item, index) => (
                      <Col
                        key={index}
                        sm="4"
                        xs="12"
                        className="mb-4"
                        draggable={true}
                        id={item?.id}
                        onDragOver={ev => ev.preventDefault()}
                        onDragStart={e => {
                          if (isDisabled || (!isFieldDisable && isEventStatus !== 'Closed')) {
                            handleDrag(e);
                          }
                        }}
                        onDrop={e => {
                          if (isDisabled || (!isFieldDisable && isEventStatus !== 'Closed')) {
                            handleDrop(e);
                          }
                        }}>
                        {item?.name !== '' ? (
                          <ImageDisplay
                            files={[item]}
                            onRemove={e => handleRemoveImage(item)}
                            disabled={isDisabled || isFieldDisable || isEventStatus === 'Closed'}
                          />
                        ) : (
                          <FileUpload
                            accept="image/jpeg, image/png"
                            text={OKTION_VARIABLE?.OKTION_UPLOAD_LOT_IMAGE}
                            name={`uploader${index}`}
                            onDrop={file => handleFileUpload(file, index)}
                            multiple
                            disabled={isDisabled || isFieldDisable || isEventStatus === 'Closed'}
                          />
                        )}
                      </Col>
                    ))}
                  <Col>
                    {imageLength === true && <p className="text-danger">{OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION}</p>}
                    {isImage === true && (
                      <span className="text-danger mt-2">
                        {OKTION_VARIABLE?.OKTION_PLEASE_SELECT_ATMEASE_ONE_IMAGE}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="img-specification">
                  <Col sm="12" className="mb-5">
                    <div className="message">
                      <p>{OKTION_VARIABLE?.OKTION_IMAGE_SPECIFICATION}</p>
                      <p>{OKTION_VARIABLE?.OKTION_UPLOAD_UPTO_SIX}</p>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_TYPE}</p>
                      <p>{OKTION_VARIABLE?.OKTION_RECOMMENDED_DIMENSION}</p>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_SIZE}</p>
                    </div>
                  </Col>
                </Row>
                <FormGroup
                  className={
                    watch('lotname') !== '' && typeof watch('lotname') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <input
                    type="text"
                    {...register('lotname', {
                      required: true,
                      maxLength: 75,
                      onChange: e => {
                        handleLotNameLimit(e);
                      },
                    })}
                    className={`mb-0 form-control ${errors.lotname && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_LOT_NAME_REQUIRED}
                    name="lotname"
                    disabled={isDisabled || isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_NAME_REQUIRED}</label>
                  {errors.lotname && charsLotName > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                  {charsLotName < 0 && <span className="text-danger">{OKTION_VARIABLE?.OKTION_LOTNAME_GREATER}</span>}
                  <p className="char_limit mt-0">{charsLotName}</p>
                </FormGroup>
                <FormGroup
                  className={
                    watch('automaticdraw') !== '' && typeof watch('automaticdraw') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <textarea
                    {...register('automaticdraw', {
                      required: true,
                      onChange: e => {
                        handleLotAboutLimit(e);
                      },
                    })}
                    name="automaticdraw"
                    type="textarea"
                    className={`mb-0 form-control ${errors.automaticdraw && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_ABOUT_THIS_LOT}
                    disabled={isDisabled || isFieldDisable}
                    data-gramm_editor="false"
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_ABOUT_THIS_LOT}</label>
                  {errors.automaticdraw && charsLotAbout > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                  {charsLotAbout < 0 && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ABOUTLOT_GREATER}</span>}
                  <p className="char_limit mt-0">{charsLotAbout}</p>
                </FormGroup>
                <FormGroup
                  className={
                    watch('lotnumber') !== '' && typeof watch('lotnumber') !== 'undefined'
                      ? ' mb-0 focus_field fixed-fieldset'
                      : ' mb-0 focus_field '
                  }>
                  <input
                    type="text"
                    {...register('lotnumber', { required: lotnotype?.value === 'sequential' ? false : true })}
                    className={`mb-0 form-control ${errors.lotnumber && 'errorColor'}`}
                    placeholder={
                      lotnotype?.value === 'sequential'
                        ? OKTION_VARIABLE?.OKTION_LOT_NUMBER
                        : OKTION_VARIABLE?.OKTION_LOT_NUMBER
                    }
                    name="lotnumber"
                    disabled={isDisabled || lotnotype?.value === 'sequential' ? true : false || isSupplyFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_NUMBER_REQUIRED}</label>
                  {errors.lotnumber && charsLotName > 0 && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <FormGroup className="custome-dropdown-arrow">
                  <select
                    {...register('providedBy', { required: false })}
                    className="form-control mb-3 mt-2 cursor-pointer"
                    name="providedBy"
                    value={watch('providedBy')}
                    disabled={isDisabled || isFieldDisable}>
                    <option value="providedBy">{OKTION_VARIABLE?.OKTION_PROVIDED_BY}</option>
                    <option value="donatedBy">{OKTION_VARIABLE?.OKTION_DONATION_BY}</option>
                    <option value="sponsoredBy">{OKTION_VARIABLE?.OKTION_SPONSOR_BY}</option>
                  </select>
                </FormGroup>
                <FormGroup
                  className={
                    watch('lotprovidername') !== '' && typeof watch('lotprovidername') !== 'undefined'
                      ? ' mt-2 focus_field fixed-fieldset'
                      : ' mt-2 focus_field '
                  }>
                  <input
                    {...register('lotprovidername', { required: true })}
                    className={`mb-0 form-control ${errors.lotprovidername && 'errorColor'}`}
                    type="text"
                    placeholder={OKTION_VARIABLE?.OKTION_LOT_PROVIDER_NAME}
                    name="lotprovidername"
                    disabled={isDisabled || isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_PROVIDER_NAME}</label>
                  {errors.lotprovidername && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <FormGroup className="custome-dropdown-arrow mt-2">
                  <select
                    {...register('categoryId', { required: true })}
                    className="form-control mb-3 mt-2"
                    name="categoryId"
                    disabled={isDisabled || isFieldDisable}>
                    <option value="" hidden>
                      {OKTION_VARIABLE?.OKTION_CATEGORY}
                    </option>
                    {typeof categoryList !== 'undefined' && (
                      <>
                        {categoryList?.map((item, index) => (
                          <option value={item?.id} key={index}>
                            {item?.categoryName}
                          </option>
                        ))}
                        <option value="other">{OKTION_VARIABLE?.OKTION_OTHER}</option>
                      </>
                    )}
                  </select>
                  {errors.categoryId && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                {watch('categoryId') === 'other' && (
                  <FormGroup
                    className={
                      watch('othercategory') !== '' && typeof watch('othercategory') !== 'undefined'
                        ? ' mt-2 focus_field fixed-fieldset '
                        : ' mt-2 focus_field '
                    }>
                    <input
                      type="text"
                      {...register('othercategory', { required: true })}
                      className={`mb-0 form-control ${errors.othercategory && 'errorColor'}`}
                      placeholder={OKTION_VARIABLE?.OKTION_TYPE_ITEM_CATEGORY}
                      name="othercategory"
                      disabled={isDisabled || isFieldDisable}
                    />
                    <label className="label-helper">{OKTION_VARIABLE?.OKTION_TYPE_ITEM_CATEGORY}</label>
                    {errors.othercategory && <span className="text-danger">{Errors.mandatory}</span>}
                  </FormGroup>
                )}

                <InputGroup
                  className={
                    watch('startingbid') !== '' &&
                    typeof watch('startingbid') !== 'undefined' &&
                    watch('startingbid') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register('startingbid', {
                      required: true,
                      min: 1,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    className={`mb-0 form-control input_field-style ${errors.startingbid && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_STARTING_BID_REQUIRED}
                    name="startingbid"
                    onKeyUp={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    type="number"
                    disabled={isDisabled || isSupplyFieldDisable || disableReservAmt}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_STARTING_BID_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {errors.startingbid && errors?.startingbid?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.startingbid && errors?.startingbid?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('increment') !== '' &&
                    typeof watch('increment') !== 'undefined' &&
                    watch('increment') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.increment && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_INCREMENT_REQUIRED}
                    {...register('increment', {
                      required: true,
                      min: 1,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="increment"
                    type="number"
                    onKeyUp={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    disabled={isDisabled || isSupplyFieldDisable || disableReservAmt}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_INCREMENT_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {errors.increment && errors?.increment?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.increment && errors?.increment?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('reverse_amount') !== '' &&
                    typeof watch('reverse_amount') !== 'undefined' &&
                    watch('reverse_amount') !== null
                      ? ' mb-3 form-group-style focus_field from-group-with-span fixed-fieldset'
                      : ' form-group-style mb-3 focus_field from-group-with-span '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.reverse_amount && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_REQUIRED}
                    {...register('reverse_amount', {
                      required: true,
                      validate: validateReserveAmount,
                      min: 1,
                      onChange: e => {
                        const currentValue = parseFloat(e.target.value.replace(/[^0-9.]/g, '')) || 0;
                        const previousValue = parseFloat(data?.reserveAmount) || 0;

                        // Check if isFieldDisable is true and enforce decrement-only behavior
                        if (isFieldDisable && currentValue >= previousValue) {
                          e.target.value = previousValue; // Revert to previous value if increment detected
                        } else {
                          e.target.value = currentValue.toString(); // Update with cleaned numeric value
                        }
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="reverse_amount"
                    type="number"
                    onKeyUp={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    disabled={isDisabled || isSupplyFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_REQUIRED}</label>
                  <span className="span-style">{currency}</span>
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'validate' && (
                    <span className="text-danger">{errors?.reverse_amount?.message}</span>
                  )}
                  {errors.reverse_amount && errors?.reverse_amount?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('buyitnow') !== '' && typeof watch('buyitnow') !== 'undefined' && watch('buyitnow') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.buyitnow && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}
                    {...register('buyitnow', {
                      required: false,
                      validate: validateBuyItNowAmount,
                      min: 0,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    type="number"
                    onKeyUp={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                    name="buyitnow"
                    disabled={isDisabled || isSupplyFieldDisable || disableReservAmt}
                  />

                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}</label>
                  <span className="span-style">{currency}</span>
                  {errors.buyitnow && errors?.buyitnow?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.buyitnow && errors?.buyitnow?.type === 'validate' && (
                    <span className="text-danger">{errors?.buyitnow?.message}</span>
                  )}
                  {errors.buyitnow && errors?.buyitnow?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <InputGroup
                  className={
                    watch('postage_handlingamt') !== '' &&
                    typeof watch('postage_handlingamt') !== 'undefined' &&
                    watch('postage_handlingamt') !== null
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.postage_handlingamt && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}
                    {...register('postage_handlingamt', {
                      min: 0,
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="postage_handlingamt"
                    type="number"
                    onKeyUp={e => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    disabled={isDisabled || isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}</label>
                  <span className="span-style">{currency}</span>
                  {errors.postage_handlingamt && <span className="text-danger">{Errors.mandatory}</span>}
                  {errors.postage_handlingamt && errors?.postage_handlingamt?.type === 'min' && (
                    <span className="text-danger">{OKTION_VARIABLE?.OKTION_VALUE_GRATER}</span>
                  )}
                </InputGroup>
                <FormGroup
                  className={
                    watch('postage_handlingnotes') !== '' && typeof watch('postage_handlingnotes') !== 'undefined'
                      ? ' mt-2 focus_field fixed-fieldset'
                      : ' mt-2 focus_field '
                  }>
                  <input
                    type="text"
                    className={`mb-0 form-control ${errors.postage_handlingnotes && 'errorColor'}`}
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}
                    {...register('postage_handlingnotes')}
                    name="postage_handlingnotes"
                    disabled={isDisabled || isFieldDisable}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_RAFFLE_POSTAGE_HANDLING_NOTES}</label>
                  {errors.postage_handlingnotes && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <div className="d-flex align-items-center mb-4">
                  <h5 className="fw-normal">{OKTION_VARIABLE?.OKTION_FEATURED_LOT}</h5>
                  <div id="payment" type="button" className="question-icon ms-3 p-0">
                    ?
                  </div>

                  <UncontrolledPopover placement="bottom" className="tax-detail" target="payment" trigger="legacy">
                    <PopoverBody>
                      <p>
                        <strong>{OKTION_VARIABLE?.OKTION_FEATURED_LOT}</strong>
                      </p>
                      <p>{OKTION_VARIABLE?.OKTION_LOT_APPEAR_AT_TOP}</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className="switchBtn">
                    <ToggleSwitch
                      onChange={handleNotifyUserSwitch}
                      defaultValue={notifyUserSwitchVal}
                      disabled={isDisabled || isFieldDisable || isEventStatus === 'Closed' ? true : false}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fw-normal">{OKTION_VARIABLE?.OKTION_RAFFLE_NOTIFY_USER}</h5>

                  <UncontrolledPopover placement="top" className="tax-detail" target="visibilitytr" trigger="legacy">
                    <PopoverBody>
                      <p>
                        <strong>{OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}</strong>
                      </p>
                      <p>{OKTION_VARIABLE?.OKTION_MORE_LOTS}</p>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className="switchBtn">
                    <ToggleSwitch
                      onChange={handleFeatureLotSwitch}
                      defaultValue={featureLotSwitchVal}
                      disabled={isDisabled || isFieldDisable || isEventStatus === 'Closed' ? true : false}
                    />
                  </div>
                </div>
                <div className="focus_field">
                  <input
                    {...register('duplicateLot', {
                      onChange: e => {
                        const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                        e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                      },
                    })}
                    name="duplicateLot"
                    placeholder={OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}
                    className={`mb-0 form-control ${errors.duplicateLot && 'errorColor'}`}
                    disabled={isDisabled || isFieldDisable}
                    id="visibilitytr"
                    type="number"
                    onKeyUp={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_DUPLICATE_LOT}</label>
                  <span className="leave-info-text mb-2">{OKTION_VARIABLE?.OKTION_LEAVE_BLANK}</span>
                  {errors.duplicateLot && <span className="text-danger">{Errors.mandatory}</span>}
                </div>
                {featureLotSwitchVal !== null && featureLotSwitchVal !== undefined && featureLotSwitchVal === true && (
                  <FormGroup
                    className={
                      watch('messageUsers') !== '' && typeof watch('messageUsers') !== 'undefined'
                        ? ' mb-0 mt-2 form-group-style focus_field fixed-fieldset'
                        : ' mb-0 mt-2 form-group-style focus_field '
                    }>
                    <textarea
                      {...register('messageUsers')}
                      type="text"
                      name="messageUsers"
                      className={`input_field-style form-control ps-3 m-0`}
                      placeholder={OKTION_VARIABLE?.OKTION_RAFFLE_MESSAGE_USERS}
                      style={{ height: '90px' }}
                      data-gramm_editor="false"
                    />
                    <Label className="label-helper">{OKTION_VARIABLE?.OKTION_RAFFLE_MESSAGE_USERS}</Label>
                    <div className="messageBoxLot">
                      <img src={images.SendIcon} alt="sendIcon" style={{ float: 'right' }} />
                    </div>
                  </FormGroup>
                )}
                <div className="mb-5 text-center modalFooter">
                  <Row className="ps-0 pe-0">
                    {isUpdate && (
                      <Col sm="4">
                        <Button
                          className="app-button black-button mt-2 me-2 mWidth-100"
                          disabled={isDisabled || isFieldDisable || isEventStatus === 'Closed' ? true : false}
                          onClick={toggleLotDeleteModal}>
                          {OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
                        </Button>
                      </Col>
                    )}
                    <Col sm={isUpdate ? '4' : '6'}>
                      {isUpdate && data?.status === 'published' ? (
                        <Button
                          className="app-button main-button mt-2 me-2 mWidth-100"
                          disabled={
                            isDisabled || eventFeatureStatus() === 'Closed'
                              ? false
                              : data?.isSold
                                ? false
                                : true
                                  ? true
                                  : false
                          }
                          onClick={() => handleMarkAsPaid()}>
                          {OKTION_VARIABLE?.OKTION_MARK_PAID}
                        </Button>
                      ) : (
                        <Button
                          className="app-button main-button mt-2 me-2 mWidth-100"
                          disabled={
                            data?.status !== 'published' && (isDisabled || isFieldDisable || isEventStatus === 'Closed')
                              ? true
                              : false
                          }
                          onClick={value => onSubmit('', 'saved')}>
                          {OKTION_VARIABLE?.OKTION_BUTTON_SAVE}
                        </Button>
                      )}
                    </Col>
                    <Col sm={isUpdate ? '4' : '6'}>
                      <Button
                        onClick={value => {
                          image_Length?.length === 0 ? setIsImage(true) : handleSubmit(onSubmit)('published');
                        }}
                        disabled={
                          isEventStatus === 'Closed'
                            ? isDisabled || isClaimedStatus || isMarkPaidStatus
                              ? false
                              : true
                            : false
                        }
                        className="app-button blue-button mt-2 me-2 mWidth-100">
                        {OKTION_VARIABLE?.OKTION_BUTTON_PUBLISH}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <DeleteLotModal
          isToggle={deleteModalOpen}
          toggle={toggleLotDeleteModal}
          handleSubmit={value => {
            onSubmit('', 'deleted');
            setTimeout(() => {
              setDeleteModalOpen(false);
            }, 2000);
          }}
        />
        <MarkAsReadModal
          isToggle={toggleMarkAsPaidVal}
          toggle={handleMarkAsPaid}
          handleAmountChange={handleAmountChange}
          handleConfirm={handleConfirm}
          currency={currency}
          markAsPaid={data?.markAsPaid}
          amountPaid={data?.amountPaid}
        />
        <LotLimiteModal isToggle={islimitToggle} closeModal={() => setLimitToggle(!islimitToggle)} />
      </div>
    </>
  );
};

const mapStateToProps = ({ featureReducer }) => {
  return {
    featureAuctionData: featureReducer.featureRaffleData?.data?.eventFeatures,
  };
};

export default connect(mapStateToProps, { dashBoardData })(AddItemModal);
