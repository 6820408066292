import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { CurrencyFormat } from 'utility';
import { ReactComponent as PluseIcon } from '../../../../resources/images/pluse_icon.svg';
import { ReactComponent as MinusIcon } from '../../../../resources/images/minus_icon.svg';
import { CustomTextField } from 'library/common/components/textfield';
import { SocketContext } from 'main/context/socket';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import moment from 'moment';

const TicketAddCard = ({
  count,
  ticketIndex,
  countIndex,
  ticketCardData,
  eventDetails,
  handleMinus,
  handleAddToCart,
  whitelabel,
  isVenueEndDateDisable,
  handleInputChange,
  handleAdd,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;

  // Select the input field text when focused
  const handleInputFocus = event => {
    event.target.select();
  };

  var ticketValue = ticketCardData?.eventFeatureOptions?.filter(item => item?.key === 'priceperticket');
  var availableTicket = ticketCardData?.eventFeatureOptions.filter(item => item.key === 'nooffavailableticket');

  const isFuture = moment(ticketCardData?.startTime).isAfter(moment());
  const isPast = moment(ticketCardData?.endTime).isBefore(moment());
  const isLimitExhausted = Number(availableTicket[0]?.value) === Number(ticketCardData?.ticketsSold);

  return (
    <div className="ticket-amount-card">
      <h4 className="ticket-amount-title">{ticketCardData?.featureName}</h4>
      <div className="ticket-amount-value">
        <CurrencyFormat
          value={count[countIndex] * Number(ticketValue[0]?.value)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${eventDetails?.currency != null ? currency : ''} ${eventDetails?.currency != null ? symbol : ''}`}
          thousandSpacing={symbol === '₹' ? '2s' : '3'}
          decimalScale="2"
          fixedDecimalScale={true}
        />
        {count[countIndex] > 1 && <span> for {count[countIndex]}</span>}
      </div>

      {ticketCardData?.featureAbout !== null ? (
        <div className="ticket-amount-discription">
          {parse(
            anchorme({
              input: (ticketCardData?.featureAbout).toString().replace(/\n/g, '<br/>'),
              options: {
                attributes: {
                  target: '_blank',
                  class: 'detected',
                },
              },
            }),
          )}
        </div>
      ) : (
        ''
      )}

      {isFuture ? (
        <p className="ticket-amount-future-date-time">
          {OKTION_VARIABLE?.OKTION_TICKETS_GO_ON_SALE_ON +
            ' ' +
            moment(ticketCardData?.startTime).format('DD MMMM YYYY')}
        </p>
      ) : isPast || ticketCardData?.isActive === false ? (
        <p className="ticket-amount-past-date-time">{OKTION_VARIABLE?.OKTION_TICKETS_SALE_PERIOD_HAS_ENDED}</p>
      ) : isLimitExhausted ? (
        <p className="ticket-amount-exhausted">{OKTION_VARIABLE?.OKTION_TICKET_ALLOCATION_EXHAUSTED}</p>
      ) : (
        <div className="ticket-card-amount-update-btn">
          <div className="d-flex justify-content-between align-items-center">
            <Button
              id={countIndex}
              className="btn-primary ticket-update-count-btn"
              onClick={() => handleMinus(countIndex, ticketIndex)}
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
                cursor: isVenueEndDateDisable || whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={isVenueEndDateDisable || whitelabel === true ? true : false}>
              <MinusIcon />
            </Button>
            <div className="ticket-count-input-field">
              <CustomTextField
                fullWidth
                className="custom-textfield ticket-count-field"
                value={count[countIndex]}
                onChange={e => handleInputChange(e)}
                type="number"
                pattern="[0-9]*"
                name={countIndex}
                onFocus={handleInputFocus}
              />
            </div>
            <Button
              id={countIndex}
              className="btn-primary ticket-update-count-btn"
              onClick={() => handleAdd(countIndex, ticketIndex)}
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
                cursor: isVenueEndDateDisable || whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={isVenueEndDateDisable || whitelabel === true ? true : false}>
              <PluseIcon />
            </Button>
          </div>
          <div className="">
            <Button
              fullWidth
              className="btn-primary ticket-add-to-cart-btn"
              onClick={() => handleAddToCart(ticketCardData?.id)}
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              }}
              disabled={isVenueEndDateDisable || whitelabel === true ? true : false}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketAddCard;
