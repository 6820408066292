import React from 'react';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import '../../styles.scss';

const AuctionWallPlatinumSponser = ({ itemValue, sponsorsImages }) => {
  return (
    <div className="auctionWallPlatinumSponsor mt-4 text-center">
      <h4 className="fw-500 mb-3">{itemValue}</h4>
      <div className="d-flex justify-content-center flex-wrap">
        {sponsorsImages &&
          sponsorsImages?.map((item, i) => (
            <div key={i} className="sponsor-box">
              <img
                src={identifiers.imageUrlConstant + '' + item?.imagePath}
                alt={item?.sponsorsName || 'sponsors image'}
                className="mb-4"
                onError={e => {
                  e.target.src = images?.logo; // Set the default image
                  e.target.className = 'error-bg-img mb-4'; // Add error class name
                }}
                onLoad={e => {
                  if (e.target.src !== window.location.origin + images?.logo) {
                    // Check if the image is not the default
                    e.target.className = 'mb-4'; // Reset the class name
                  }
                }}
              />
              {item?.sponsorsName && <span className="">{item?.sponsorsName}</span>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AuctionWallPlatinumSponser;
