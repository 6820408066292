import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import LogoutModal from '../../../Raffle/LogoutModal';
import { ReactComponent as PluseIcon } from '../../../../../../resources/images/pluse_icon.svg';
import { ReactComponent as MinusIcon } from '../../../../../../resources/images/minus_icon.svg';
import { CurrencyFormat } from 'utility';

const BiddingAmountCount = ({
  handleSetMaxBid,
  handleBidNow,
  handleBuyItNow,
  handleAdd,
  handleMinus,
  count,
  currency,
  currencySymbol,
  singleItem,
  setQuickBid,
  auctionFeatureData,
  eventDetailsData,
  isLoggedIn,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [bidCount, setBidCount] = useState(count);
  const [biddingData, setBiddingData] = useState(singleItem);
  const [isLoginToggle, setLoginToggle] = useState(false);

  // Fetch auction data when component mounts or auction is updated
  useEffect(() => {
    // Subscribe to socket updates for bidding data
    EventEmitter.subscribe('updateBiddingData', auctionData => {
      var auction_data = auctionData?.find(item => item?.id === singleItem?.id);
      setBidCount(
        auction_data?.currentBid === 0
          ? auction_data?.startBid
          : Number((auction_data?.currentBid + auction_data?.bidIncrement)?.toFixed(2)),
      );
      setBiddingData(auction_data);
    });
  }, []);

  // Update bid count when the count prop changes (handleAdd, handleMinus)
  useEffect(() => {
    setBidCount(count);
  }, [count]);

  // Function to toggle logout modal
  const toggleModal = () => {
    setLoginToggle(!isLoginToggle);
  };

  // Navigate to login if the user is not logged in
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: params?.id,
      },
    });
  };

  return (
    <>
      <div className={`bidding-amount-count-details ${setQuickBid === true ? '' : 'bidding-count mt-3'}`}>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className={`btn-primary update-count-btn ${auctionFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={() => handleMinus()}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            <MinusIcon />
          </Button>
          <h5 className="count-input-text">
            <CurrencyFormat
              value={bidCount !== null ? bidCount : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              decimalScale="2"
              fixedDecimalScale={true}
              style={{ fontSize: '20px' }}
            />
          </h5>
          <Button
            className={`btn-primary update-count-btn ${auctionFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={() => handleAdd()}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            <PluseIcon />
          </Button>
        </div>
        <Button
          fullWidth
          onClick={() => handleBuyItNow()}
          className="btn-blue buy-it-now-btn"
          disabled={
            singleItem?.isSold ||
            auctionFeatureData[0]?.isActive === false ||
            singleItem?.currentBid >= singleItem?.buyItNow ||
            singleItem?.buyItNow === null ||
            singleItem?.markAsPaid ||
            singleItem?.buyItNow === 0
          }>
          {singleItem > singleItem?.buyItNow || singleItem?.buyItNow === null || singleItem?.buyItNow === 0 ? (
            OKTION_VARIABLE?.OKTION_BUYIT_NOW
          ) : (
            <CurrencyFormat
              value={singleItem?.buyItNow !== null ? singleItem?.buyItNow : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${OKTION_VARIABLE?.OKTION_BUYIT_NOWFOR} ${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              decimalScale="2"
              fixedDecimalScale={true}
            />
          )}
        </Button>
        <div className="min-max-bid-btn">
          <Button
            fullWidth
            onClick={() => handleBidNow()}
            className={`btn-primary ${eventDetailsData?.primaryColour ? '' : ''}`}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}>
            {OKTION_VARIABLE?.OKTION_BID_NOW}
          </Button>
          <Button
            fullWidth
            onClick={() => handleSetMaxBid()}
            className={`btn-primary ${eventDetailsData?.primaryColour ? '' : ''}`}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            {OKTION_VARIABLE?.OKTION_SETMAX_BID}
          </Button>
        </div>

        <div className="min-max-bid-count">
          <div>
            <p
              className="bid-count-title"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_CURRENTBID}{' '}
            </p>
            <div className="bid-count-value">
              <CurrencyFormat
                value={biddingData?.myCurrentBid !== null ? biddingData?.myCurrentBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </div>
          </div>
          <div>
            <p
              className="bid-count-title"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_MAX_BID}
            </p>
            <div className="bid-count-value">
              <CurrencyFormat
                value={biddingData?.myMaxBid !== null ? biddingData?.myMaxBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Card details required modal */}
      <LogoutModal isToggle={isLoginToggle} toggle={toggleModal} handleClick={handleLoginRedirect} />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(BiddingAmountCount);
