import React, { useContext } from 'react';
import moment from 'moment';
import { images } from '../../../../library/common/constants/ImageConstants';
import { ReactComponent as Logo } from '../../../../resources/images/ticket_oktion_logo.svg';
import { SocketContext } from 'main/context/socket';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { CurrencyFormat } from 'utility';
import './styles.scss';

const SingleTicketPrintComponent = ({ ticketItemData, eventDetails, venueAddress }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const barcodeForRaffleTicket = ticketItemData?.ticketNumber;

  return (
    <>
      <div className="your-tickets-print-single-view-container">
        <div className="your-tickets-print-single-view-wrapper">
          <div className="align-items-center justify-content-between d-flex pb-5">
            <div className="ticket-footer pt-5">
              <p>oktion.com.au</p>
              <p>Suite 302, Level 3, 2 Elizabeth Plaza</p>
              <p>North Sydney, NSW 2060 </p>
              <p>PH: 1300 OKTION (1300 658 466)</p>
            </div>
            <div className="site-logo">
              <a className="d-block" rel="noopener noreferrer" href="http://www.oktion.com.au/" target="_blank">
                <img src={images.logo} alt={OKTION_VARIABLE?.OKTION_LOGO} />
              </a>
            </div>
          </div>
          <p className="your-tickets-print-single-view-title">{OKTION_VARIABLE?.OKTION_EVENT_TITLE_CAPITAL}</p>
          <div className="your-tickets-print-single-view-value mb18">
            {eventDetails?.eventName &&
              parse(
                anchorme({
                  input: (eventDetails?.eventName).toString().replace(/\n/g, '<br/>'),
                  options: {
                    attributes: {
                      target: '_blank',
                      class: 'detected',
                    },
                  },
                }),
              )}
          </div>
          <p className="your-tickets-print-single-view-title">VENUE DATES</p>
          <p className="your-tickets-print-single-view-value mb18">
            {moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format(
              'hh:mm A, DD MMMM YYYY',
            )}{' '}
            - {moment(eventDetails?.venueEndDate != null && eventDetails?.venueEndDate).format('DD MMMM YYYY, hh:mm A')}
          </p>{' '}
          <p className="your-tickets-print-single-view-title">{OKTION_VARIABLE?.OKTION_VENUE_CAPITAL}</p>
          <p className="your-tickets-print-single-view-value mb18">
            {Array.isArray(venueAddress)
              ? venueAddress?.map((line, index) => <span key={index}>{line} </span>)
              : venueAddress}
          </p>
          <div className="your-tickets-print-single-view-img-wrapper">
            <img
              src={
                eventDetails?.eventImages !== null && identifiers.imageUrlConstant + eventDetails?.eventImages[0].name
              }
              alt={OKTION_VARIABLE?.OKTION_EVENT_IMAGE}
              className=""
              onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
            />
          </div>
        </div>
        <div className="your-tickets-print-view-wrapper">
          <div className="your-tickets-print-view-left-side">
            <div className="your-ticket-qr-barcode-continer">
              <div className="">
                <QRCode size={130} value={barcodeForRaffleTicket} />
              </div>
              <div className="your-ticket-barcode">
                <Barcode value={barcodeForRaffleTicket} width={0.8} height={50} format="CODE128" displayValue={false} />
              </div>
            </div>
            <div className="your-tickets-print-view-img-wrapper">
              <img
                src={
                  eventDetails?.eventImages !== null && identifiers.imageUrlConstant + eventDetails?.eventImages[0].name
                }
                alt={OKTION_VARIABLE?.OKTION_EVENT_IMAGE}
                className=""
                onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
              />
            </div>
          </div>
          <div className="your-tickets-print-view-center">
            <div className="your-tickets-print-view-event-name">
              {eventDetails?.eventName &&
                parse(
                  anchorme({
                    input: (eventDetails?.eventName).toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </div>
            <p className="your-tickets-print-view-name"> {ticketItemData?.ticketName}</p>
            <div className="your-tickets-print-view-time-continer">
              <div className="your-tickets-print-view-from-to-time">
                <div>
                  <p className="your-tickets-print-view-time-title">{OKTION_VARIABLE?.OKTION_FROM_CAPITAL}</p>
                  <p className="your-tickets-print-view-date">
                    {moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format(
                      'DD MMMM YYYY',
                    )}
                  </p>{' '}
                  <p className="your-tickets-print-view-time">
                    {moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format('hh:mm A')}
                  </p>
                </div>
                <div>
                  <p className="your-tickets-print-view-time-title">{OKTION_VARIABLE?.OKTION_TO_CAPITAL}</p>
                  <p className="your-tickets-print-view-date">
                    {moment(eventDetails?.venueEndDate != null && eventDetails?.venueEndDate).format('DD MMMM YYYY')}
                  </p>{' '}
                  <p className="your-tickets-print-view-time">
                    {moment(eventDetails?.venueEndDate != null && eventDetails?.venueEndDate).format('hh:mm A')}
                  </p>
                </div>
              </div>
              <div>
                <p className="your-tickets-print-view-time-title">{OKTION_VARIABLE?.OKTION_VENUE_CAPITAL}</p>
                <p className="your-tickets-print-view-date mb-0">
                  {Array.isArray(venueAddress)
                    ? venueAddress?.map((line, index) => <span key={index}>{line} </span>)
                    : venueAddress}
                </p>
              </div>
            </div>
          </div>
          <div className="your-tickets-print-view-right-side">
            <div>
              <div className="">
                <a className="d-block" rel="noopener noreferrer" href="http://www.oktion.com.au/" target="_blank">
                  <Logo />
                </a>
              </div>
              <div className="your-tickets-print-view-details">
                <p className="your-tickets-print-view-title">{OKTION_VARIABLE?.OKTION_TICKET_NUMBER}</p>
                <p className="your-tickets-print-view-value">{ticketItemData?.ticketNumber}</p>
              </div>
              <div className="your-tickets-print-view-prize">
                <CurrencyFormat
                  value={ticketItemData?.ticketPrice || '00.0'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${eventDetails?.currency != null ? eventDetails?.currency?.abbreviation : ''} ${
                    eventDetails?.currency != null ? eventDetails?.currency?.symbol : ''
                  }`}
                  thousandSpacing={eventDetails?.currency?.symbol === '₹' ? '2s' : '3'}
                  decimalScale="2"
                  fixedDecimalScale={true}
                />
              </div>
            </div>
            <div className="your-tickets-print-view-right-event-name">
              {eventDetails?.eventName &&
                parse(
                  anchorme({
                    input: (eventDetails?.eventName).toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTicketPrintComponent;
