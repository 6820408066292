import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from 'main/axios';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { SocketContext } from 'main/context/socket';
import { getBankAccounts, createBankAccount } from 'library/common/actions/BankAccountsAction';
import BankingDetailsItem from './BankingDetailsItem';
import PageLoader from 'library/common/components/PageLoader';
import '../../../UserProfile/Frame/CardDetails/styles.scss';
import './styles.scss';

const BankingDetails = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [isAddNewBankToggle, setIsAddNewBankToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [isView, setIsView] = useState('');

  useEffect(() => {
    const isViews = fetchFromStorage(identifiers.is_view);
    setIsView(isViews);
    fetchBankAccount();
    fetchCurrencyList();
  }, []);

  // fetch currancy list
  const fetchCurrencyList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getCurrency());
      if (status === 200) {
        setCurrency(data?.data?.currencies);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // fetch bank details
  const fetchBankAccount = async () => {
    setLoading(true);
    try {
      props.getBankAccounts(_.get(props, 'orgId'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // handle create bank acount
  const createBankAccountAPI = async params => {
    await props.createBankAccount(_.get(props, 'orgId'), params);
    setIsAddNewBankToggle(false);
  };

  // handle toggle bank form
  const handleAddBankToggleForm = () => {
    setIsAddNewBankToggle(!isAddNewBankToggle);
  };

  return (
    <div className="transfer-ownership card-details bankDetails">
      {loading && <PageLoader />}
      <h1 className="text-center text-lg-start mb-2 mb-md-5 mt-4 page-title">
        {OKTION_VARIABLE?.OKTION_BANKING_DETAILS}
      </h1>
      <Row className="mb-3">
        {_.get(props, 'bankAccountsList', []).map((item, i) => (
          <Col sm="6" className="mb-3" key={'bankAcc' + i}>
            <BankingDetailsItem
              item={item}
              currency={currency}
              toggleModal={props.toggleModal}
              getBankAccountApi={fetchBankAccount}
              toggleEvent={props.toggleEvent}
            />
          </Col>
        ))}
        {isAddNewBankToggle ? (
          <Col sm="6 ">
            <BankingDetailsItem
              ToggleForm={handleAddBankToggleForm}
              createBankAccountAPI={createBankAccountAPI}
              CreateNew={true}
              currency={currency}
              is_view={isView}
              toggleModal={props.toggleModal}
              getBankAccountApi={fetchBankAccount}
              toggleEvent={props.toggleEvent}
            />
          </Col>
        ) : (
          <Col sm="6 mb-3">
            <div
              className={isView ? 'is-view dottedBox cursor-pointer h-100' : 'dottedBox cursor-pointer h-100'}
              onClick={handleAddBankToggleForm}>
              {OKTION_VARIABLE?.OKTION_ADD_BANK}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = ({ BankAccountsReducer }) => {
  return {
    bankAccountsList: BankAccountsReducer.bankAccounts,
    api_Status: BankAccountsReducer.apiStatus,
  };
};

export default connect(mapStateToProps, { getBankAccounts, createBankAccount })(BankingDetails);
