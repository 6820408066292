import React, { useContext } from 'react';
import { SocketContext } from 'main/context/socket';

const RaffleAllTicketPrint = ({ ticketData, innerRef }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <div className="raffle-tickets-print-wrapper" ref={innerRef}>
      <div className="raffle-tickets-print-details">
        <h5 className="raffle-tickets-print-title">{OKTION_VARIABLE?.OKTION_TICKET_NUMBER}</h5>
        <h6 className="raffle-tickets-print-value">{ticketData?.ticketNumber}</h6>
      </div>

      {/* {(ticketData?.user?.firstName || ticketData?.user?.lastName) && ( */}
      <div className="raffle-tickets-print-details">
        <h5 className="raffle-tickets-print-title">{OKTION_VARIABLE?.OKTION_NAME}</h5>
        <h6 className="raffle-tickets-print-value">
          {ticketData?.user?.firstName || ticketData?.user?.lastName ? (
            <>
              {ticketData?.user?.firstName ?? ticketData?.user?.firstName}{' '}
              {ticketData?.user?.lastName ?? ticketData?.user?.lastName}
            </>
          ) : (
            '-'
          )}
        </h6>
      </div>
      {/* )} */}
    </div>
  );
};

export default RaffleAllTicketPrint;
