import React from 'react';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { images } from 'library/common/constants/ImageConstants';
import _ from 'lodash';
import './styles.scss';

const Sponsors = props => {
  let sponser_sorted = props?.eventDetails && _.sortBy(props?.eventDetails?.sponsors, 'displayOrder');

  return (
    <div className="event-details-sponsors-details-container">
      {props?.eventDetails?.sponsors?.length > 0 ? (
        sponser_sorted.map(
          item =>
            item?.sponsors?.length > 0 && (
              <div className="event-details-sponsors-details">
                <h4 className="event-details-sponsors-title">{item?.name}</h4>

                <div className="event-details-sponsors-wrapper">
                  {item?.sponsors &&
                    item?.sponsors?.map((item, i) => (
                      <div>
                        <div key={i} className="event-details-sponsors-img">
                          <img
                            src={identifiers.imageUrlConstant + '' + item?.imagePath}
                            alt={item?.sponsorsName}
                            onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
                          />
                        </div>
                        {item?.sponsorsName && <p className="event-details-sponsors-name">{item?.sponsorsName}</p>}
                      </div>
                    ))}
                </div>
              </div>
            ),
        )
      ) : (
        <p className="no-data-found">No sponsors available</p>
      )}
    </div>
  );
};

export default Sponsors;
