import React from 'react';
import { useParams } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Container } from 'reactstrap';
import Slider from 'react-slick';
import moment from 'moment';
import _ from 'lodash';
import TicketSold from './TicketSold';
import AuctionWallCard from './AuctionWallCard';
import AuctionWallPlatinumSponser from './AuctionWallPlatinumSponser';
import AuctionWallTable from './AuctionWallTable';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import PageLoader from 'library/common/components/PageLoader';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import { CurrencyFormat } from 'utility';
import { images } from 'library/common/constants/ImageConstants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles.scss';

class AuctionWall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feturesData: '',
      currency: '',
      currencySymbol: '',
      loading: false,
      sponsorDetails: '',
      isShow: true,
      isCheckSponsors: false,
      eventDetails: '',
      feturesEndDate: '',
    };
  }

  async componentDidMount() {
    const { userSocket, params } = this.props;

    userSocket.emit('AuctionWall', {
      featureId: Number(params?.id),
    });
    userSocket.on('AuctionWall', data => {
      EventEmitter.dispatch('SocketAuctionWallDataUpdated', data);
    });
    EventEmitter.subscribe('SocketAuctionWallDataUpdated', data => {
      const { currency, featureDetail } = data?.data || {};

      this.setState({
        feturesData: data?.data,
        currencyAbbreviation: currency?.abbreviation,
        currencySymbol: currency?.symbol,
        feturesEndDate: featureDetail?.endTime,
      });
    });

    this.setState({ loading: true });
    try {
      // Fetch auction wall details
      const { status, data } = await axiosInstance.get(URLS.getActionWallDetails(params?.id));
      if (status === 200) {
        const featureDetail = data?.data?.featureDetail;

        // Fetch event and sponsor details
        const [eventResponse, sponsorResponse] = await Promise.all([
          axiosInstance.get(URLS.getByEventIdData(featureDetail?.eventId)),
          axiosInstance.get(URLS.getSponsors(featureDetail?.eventId)),
        ]);

        const sponsorDetails = sponsorResponse?.data?.data?.sponsorDetails || [];
        const hasSponsors = sponsorDetails.some(sponsor => sponsor?.sponsors?.length > 0);

        this.setState({
          sponsorDetails,
          loading: false,
          eventDetails: eventResponse?.data?.data?.events,
          isCheckSponsors: hasSponsors,
          feturesData: data?.data,
          currencyAbbreviation: data?.data?.currency?.abbreviation,
          currencySymbol: data?.data?.currency?.symbol,
          feturesEndDate: featureDetail?.endTime,
        });

        if (hasSponsors) {
          this.handleAutoChangeView();
        }
      }
    } catch {
      this.setState({ loading: false });
    }
  }

  handleAutoChangeView = () => {
    if (this.state.isCheckSponsors === true) {
      setInterval(() => {
        this.setState({ isShow: !this.state.isShow });
      }, 30000);
    }
  };

  handleswitchCase = slug => {
    const { feturesData, currencyAbbreviation, currencySymbol, loading } = this.state;
    switch (slug) {
      case 'table':
        return (
          <AuctionWallTable
            feturesData={feturesData}
            currencyAbbreviation={currencyAbbreviation}
            currencySymbol={currencySymbol}
            loading={loading}
          />
        );
      case 'item':
        return (
          <AuctionWallCard
            feturesData={feturesData}
            currencyAbbreviation={currencyAbbreviation}
            currencySymbol={currencySymbol}
            loading={loading}
          />
        );
      default:
        return '';
    }
  };
  renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <div>
        {days > 0 ? ('0' + days).slice(-2) : '00'}:{hours > 0 ? ('0' + hours).slice(-2) : '00'}:
        {minutes > 0 ? ('0' + minutes).slice(-2) : '00'}
      </div>
    );
  };
  render() {
    const {
      loading,
      feturesData,
      currencySymbol,
      currencyAbbreviation,
      sponsorDetails,
      isShow,
      eventDetails,
      feturesEndDate,
    } = this.state;
    const { params } = this.props;

    const routeName = params?.slug ?? null;

    const sortedSponsors = sponsorDetails ? _.sortBy(sponsorDetails, 'displayOrder') : [];
    const sponsorImageArray = _.flatMap(sortedSponsors, item => item?.sponsors || []);

    return (
      <div className="raffleWall auctionSmallWall auctionWallWrapper">
        {loading && <PageLoader />}
        <Container fluid={true} className="p-0">
          <div className="mainBlock ">
            <div className="left-side">
              <div className="">
                {eventDetails && eventDetails?.eventImages !== null && eventDetails?.eventImages?.[0]?.name !== '' ? (
                  <img
                    className="imageBox"
                    src={
                      eventDetails &&
                      eventDetails?.eventImages?.length > 0 &&
                      identifiers.imageUrlConstant + '' + eventDetails?.eventImages?.[0]?.name
                    }
                    onError={e => {
                      e.target.src = images?.logo; // Set the default image
                      e.target.className = 'error-bg-img imageBox'; // Add error class name
                    }}
                    onLoad={e => {
                      if (e.target.src !== window.location.origin + images?.logo) {
                        // Check if the image is not the default
                        e.target.className = 'imageBox'; // Reset the class name
                      }
                    }}
                    alt={eventDetails?.eventName || 'event image'}
                  />
                ) : (
                  <div className="box" />
                )}
                <div className="text-center mb-5">
                  <div className="auction-wall-title">
                    Live Oktion <br /> Bid Now
                  </div>
                </div>
                <div className="text-center mb-5">
                  <h2 className="fs-36">Time Remaining</h2>
                  <h1 className="fw-normal fs-63">
                    <Countdown
                      date={moment.utc(feturesEndDate).format('YYYY-MM-DD hh:mm:ss')}
                      renderer={this.renderTime}
                    />{' '}
                  </h1>
                </div>
                <div className="text-center mb-3">
                  <h2 className="fs-36">Amount Raised</h2>
                  <h1 className="fw-normal fs-63">
                    <CurrencyFormat
                      value={feturesData?.amountRaised?.auctionAmount || '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        eventDetails && eventDetails?.currencyId !== null
                          ? currencyAbbreviation + ' ' + currencySymbol
                          : ''
                      }
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                      className="fs-50"
                    />
                  </h1>
                </div>{' '}
                {sponsorImageArray?.length > 0 ? (
                  <Slider
                    dots={false}
                    infinite={true}
                    speed={0}
                    autoplay={true}
                    autoplaySpeed={5000}
                    swipe={false}
                    touchMove={false}
                    arrows={false}
                    pauseOnHover={false}>
                    {sponsorImageArray?.map((item, index) => (
                      <div key={index}>
                        <img
                          className="imageBox"
                          src={identifiers?.imageUrlConstant + '' + item?.imagePath}
                          onError={e => {
                            e.target.src = images?.logo; // Set the default image
                            e.target.className = 'error-bg-img imageBox'; // Add error class name
                          }}
                          onLoad={e => {
                            if (e.target.src !== window.location.origin + images?.logo) {
                              // Check if the image is not the default
                              e.target.className = 'imageBox'; // Reset the class name
                            }
                          }}
                          alt={eventDetails?.sponsorsName || 'sponsor'}
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="rightsideBlock">
              {isShow === true ? (
                <>
                  <div>{this.handleswitchCase(routeName)}</div>
                  {feturesData && feturesData?.item && feturesData?.item?.length > 0 ? (
                    <div className="TicketSoldBlock">
                      <TicketSold
                        feturesData={feturesData}
                        currencyAbbreviation={currencyAbbreviation}
                        currencySymbol={currencySymbol}
                        eventDetails={eventDetails}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <div className="eventDetailsTab auctionWallSponser-wrapper">
                  {sortedSponsors &&
                    sortedSponsors?.map(item => {
                      return (
                        item?.sponsors?.length > 0 && (
                          <AuctionWallPlatinumSponser sponsorsImages={item?.sponsors} itemValue={item?.name} />
                        )
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const AuctionWallWithSocket = props => {
  const params = useParams();
  return (
    <SocketContext.Consumer>
      {userSocket => <AuctionWall {...props} userSocket={userSocket.socket} params={params} />}
    </SocketContext.Consumer>
  );
};
export default AuctionWallWithSocket;
