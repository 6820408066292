import React from 'react';
import { images } from '../../../constants/ImageConstants';
import './imageDisplayStyles.scss';

const ImageDisplay = ({ files, onRemove, handleImageErrored, isImageLoaded, disabled }) => (
  <div className="image-display-list">
    {files?.length > 0 &&
      files?.map((file, key) => (
        <div className="image-item" key={key}>
          <span
            onClick={disabled ? null : onRemove(key)}
            className={`remove-button exportBtn ${disabled ? 'cursor-default' : ''}`}>
            <img src={images.blackDelete} alt="" />{' '}
          </span>
          {file.type === 'application/pdf' ? (
            <div className="pdf" title={file.name}>
              <div className="pdf-file">
                <a target="_blank" rel="noopener noreferrer" href={file.preview}>
                  {file.name}
                </a>
              </div>
              <div className="icon">
                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
              </div>
            </div>
          ) : (
            <>
              {isImageLoaded ? (
                <dix className="noImageWrap"></dix>
              ) : (
                <img
                  className="longImg"
                  src={file.preview}
                  alt={file.name}
                  onError={e => {
                    e.target.src = images?.logo; // Set the default image
                    e.target.className = 'error-bg-img longImg'; // Add error class name
                  }}
                  onLoad={e => {
                    if (e.target.src !== window.location.origin + images?.logo) {
                      // Check if the image is not the default
                      e.target.className = 'longImg'; // Reset the class name
                    }
                  }}
                />
              )}
            </>
          )}
        </div>
      ))}
  </div>
);

export default ImageDisplay;
