import React from 'react';
import Slider from 'react-slick';
import _ from 'lodash';
import { images } from 'library/common/constants/ImageConstants';
import { CurrencyFormat } from 'utility';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles.scss';
class TicketSold extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const soldTicketsItems = this.props?.feturesData?.item ?? [];

    const listOfItemGroupByWinnerId = _.chain(
      soldTicketsItems?.filter(item => item?.winnerId !== null && item?.reserveMet === true),
    )
      .groupBy('winnerId')
      .map((group, winnerId) => {
        const winner = group[0]?.winner ?? {};
        const winnerName = winnerId ? `${winner?.firstName ?? ''} ${winner?.lastName ?? ''}`.trim() : '';

        const sum = _.sumBy(group, item => {
          const { isSold, markAsPaid, buyItNow, currentBid } = item ?? {};
          return isSold && markAsPaid ? Number(buyItNow ?? currentBid ?? 0) : Number(currentBid ?? 0);
        });

        return {
          isAnonymousContribution: winner?.isAnonymousContribution ?? false,
          winnerName,
          sum,
        };
      })
      .value();

    return (
      <div>
        <Slider
          dots={false}
          infinite={listOfItemGroupByWinnerId?.length > 1}
          speed={0}
          autoplay={true}
          autoplaySpeed={4000}
          swipe={false}
          touchMove={false}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          pauseOnHover={false}>
          {listOfItemGroupByWinnerId &&
            listOfItemGroupByWinnerId?.map((item, i) => (
              <div className="ticketSold" key={i}>
                <div>
                  <img className="me-2" src={images.auctionWallPriceImg} alt="" style={{ display: 'inline-flex' }} />{' '}
                  <div style={{ display: 'inline-flex', textTransform: 'capitalize' }}>
                    {item?.isAnonymousContribution ? 'Anonymous Contributor' : item?.winnerName}
                  </div>{' '}
                  donated{' '}
                  <CurrencyFormat
                    value={item?.sum || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={
                      this.props.eventDetails && this.props.eventDetails.currencyId !== null
                        ? this.props.currencyAbbreviation + ' ' + this.props.currencySymbol
                        : ''
                    }
                    thousandSpacing={this.props.currencySymbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    className="currency-fromate"
                  />
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}
export default TicketSold;
