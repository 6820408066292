import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, CardImg } from 'reactstrap';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from '../../constants/IdentifierConstants';
import moment from 'moment';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import PageLoader from 'library/common/components/PageLoader';
import Countdown from 'react-countdown';
import Tooltip from '@mui/material/Tooltip';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from 'library/common/constants/function';
import { saveToStorage, CurrencyFormat } from 'utility';
import './styles.scss';

dayjs.extend(relativeTime);

const EventCardComponent = ({
  eventDetail,
  isHorizontal,
  isManaged,
  isContributions,
  isFollow,
  handleEventList,
  isFromSearch,
  // setActionMessage,
}) => {
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [loading, setLoader] = useState(false);
  const handleManage = event => {
    var redirectUrl =
      event?.featureType[0] === 'auction'
        ? 'auctionquick'
        : event?.featureType[0] === 'donation'
          ? 'donationquick'
          : event?.featureType[0] === 'raffle'
            ? 'rafflequick'
            : 'dashboard';
    if (event?.eventType === 'quick') {
      navigate(`/my-event/${event?.id}?event=${redirectUrl}`);
    } else {
      navigate(`/my-event/${event?.id}?event=details`);
    }
  };
  const handleLocation = () => {
    const location = eventDetail?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };
  const handleEventLocation = () => {
    const location = eventDetail?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };

  const handleStatus = () => {
    const status = eventDetail?.status;
    const today = moment().format('YYYY-MM-DD'); // Get today's date in 'YYYY-MM-DD' format
    const currentTime = moment().format('HHmm'); // Get current time in 'HHmm' format
    const eventEndDate = moment(eventDetail?.endDate).format('YYYY-MM-DD'); // Get event's end date
    const eventEndTime = moment(eventDetail?.endDate).format('HHmm'); // Get event's end time

    // Check if the event is suspended
    if (status === 'suspended') {
      return 'Suspended';
    }

    // If the event is saved, check its end date and time
    if (status === 'saved') {
      // If today's date is after the event end date, the event is closed
      // Otherwise, the event is still in draft
      return 'Draft';
    }

    // For other statuses, check the event's end date and time
    if (moment(today).isAfter(eventEndDate)) {
      return 'Closed'; // Event is closed if today's date is after the end date
    }

    // If the event ends today, check if the current time is past the event's end time
    if (today === eventEndDate && eventEndTime < currentTime) {
      return 'Closed'; // Event is closed if the current time is past the end time
    }

    return 'Active'; // If none of the above conditions are met, the event is active
  };

  const handleClick = () => {
    navigate(`/event-details/${eventDetail?.id}`);
  };
  const handleMyContribution = event => {
    navigate(`/my-event-page/contribution/${event?.id}`, { state: { event } });
  };
  const handleOnClick = () => {
    navigate(`/event-details/${eventDetail?.id}`);
  };
  const handleUnfollow = async event => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.eventUnFollow(event?.id));
      handleEventList();
      setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
    } catch {}
    setLoader(false);
  };
  const renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <span>
        {days > 0 ? days : 0} {days === 0 || days === 1 ? ' day' : ' days'} {hours > 0 ? hours : 0}{' '}
        {hours === 0 || hours === 1 ? ' hour' : ' hours'} {minutes > 0 ? minutes : 0}{' '}
        {minutes === 0 || minutes === 1 ? ' minute' : ' minutes'} left
      </span>
    );
  };
  var today = moment();
  var endDate = moment(eventDetail?.endDate && eventDetail?.endDate);
  let totalDays = 0;
  let totalHours = 0;
  let clearHours = 0;
  let totalMinutes = 0;
  let clearMinutes = 0;
  totalDays = endDate.diff(today, 'days');
  totalHours = endDate.diff(today, 'hours');
  clearHours = totalHours % 24;
  totalMinutes = endDate.diff(today, 'minutes');
  clearMinutes = totalMinutes % 60;

  var currentDate = new Date();
  var isPastDate = moment(currentDate).isAfter(new Date(moment(eventDetail?.endDate)));

  return (
    <>
      <div className="event-common-card" onClick={handleClick}>
        <div className="event-card-image-container">
          {eventDetail?.isPaidEventPlace ? <img src={images.badge} className="card-badge" alt="" /> : ''}
          <img
            src={identifiers.imageUrlConstant + '' + eventDetail?.eventImages?.[0]?.name}
            alt={eventDetail?.eventName}
            onError={e => {
              e.target.src = images?.logo; // Set the default image
              e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
            }}
            onLoad={e => {
              if (e.target.src !== window.location.origin + images?.logo) {
                // Check if the image is not the default
                e.target.className = 'event-card-image'; // Reset the class name
              }
            }}
          />
        </div>
        <div className="event-card-content">
          <div>
            <span className="event-type">{handleLocation()}</span>
            <p className="event-date">
              {eventDetail.endDate && dayjs(eventDetail.endDate).format('DD MMM YYYY')}{' '}
              <span className="event-time">{eventDetail.endDate && dayjs(eventDetail.endDate).format('hh:mm A')}</span>
            </p>
            <p className="event-name">
              {eventDetail?.eventName !== null &&
                parse(
                  anchorme({
                    input: eventDetail?.eventName && eventDetail?.eventName?.toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </p>
            {eventDetail.startDate && (
              <>
                {!isPastDate ? (
                  <p className="time-left">
                    <Countdown date={eventDetail?.endDate} renderer={renderTime} key={eventDetail?.endDate} />
                  </p>
                ) : (
                  <p className="text-danger mb-2">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE_MESSAGE}</p>
                )}
              </>
            )}
            {eventDetail?.raisedAmountStatus === 1 && (
              <p className="amount-raised">
                <span className="">
                  <CurrencyFormat
                    value={eventDetail?.amountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventDetail?.currency !== null ? eventDetail?.currency?.abbreviation : ''} ${
                      eventDetail?.currency !== null ? eventDetail?.currency?.symbol : ''
                    }`}
                    thousandSpacing={
                      eventDetail?.currency !== null ? (eventDetail?.currency?.symbol === '₹' ? '2s' : '3') : ''
                    }
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </span>{' '}
                {OKTION_VARIABLE?.OKTION_RAISED}
              </p>
            )}
            <div className="extra-info">
              {eventDetail?.auction && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_AUCTION} arrow>
                  <img src={images.auction} alt="auction" />
                </Tooltip>
              )}
              {eventDetail?.donation && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_DONATION} arrow>
                  <img src={images.donation} alt="donation" />
                </Tooltip>
              )}
              {eventDetail?.raffle && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_RAFFLE} arrow>
                  <img src={images.raffle} alt="raffle" />
                </Tooltip>
              )}
              {eventDetail?.ticket && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_TICKET} arrow>
                  <img src={images.tickets} alt="tickets" />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCardComponent;
