import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as DropDownIcon } from '../../../resources/images/dropdown_arrow.svg';

const FloatingLabelSelect = ({ label, options, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  // Dropdown Indicator component for custom styling
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropDownIcon />
      </components.DropdownIndicator>
    );
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
        {React.Children.map(children, child => (child && child.type !== components.Placeholder ? child : null))}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      options={options}
      classNamePrefix="custom-select"
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      isFocused={isFocused}
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator: DropdownIndicator,
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: 'visible',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused ? -20 : 'auto',
          backgroundColor:
            state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused ? 'white' : 'transparent',
          transition: 'top 2s, font-size 0.1s !important',
          fontSize:
            (state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused) && '11px !important',
          color: state.selectProps.isFocused ? '#ffc629 !important' : '#818181',
          padding:
            (state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused) && '0px 3px !important',
          lineHeight:
            (state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused) && '14px !important',
        }),
      }}
    />
  );
};

export default FloatingLabelSelect;
