import React, { useContext } from 'react';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { SocketContext } from 'main/context/socket';

const RaffleTickets = ({ ticketData, handleItemClick, imageUrl }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <div
      className="your-raffle-tickets-wrapper cursor-pointer"
      style={{ pageBreakAfter: 'always' }}
      onClick={() => handleItemClick(ticketData)}>
      <div className="your-raffle-tickets-img-wrapper">
        <img
          src={identifiers.imageUrlConstant + imageUrl}
          alt={OKTION_VARIABLE?.OKTION_CARD_IMAGE_CAP}
          className="printImage"
          onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
        />
      </div>

      <h6 className="your-raffle-tickets-number">{ticketData?.ticketNumber}</h6>
    </div>
  );
};

export default RaffleTickets;
