import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import EventTimeDetails from '../EventTimeDetails';
import LogoutModal from '../Raffle/LogoutModal';
import SendTicketModal from './SendTicketModal';
import TicketDataModal from './TicketDataModal';
import TicketPrintComponent from './TicketPrintComponent';
import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import axiosInstance from '../../../../main/axios';
import { fetchFromStorage, saveToStorage } from 'utility';
import { setApiMessage } from 'library/common/constants/function';

import TicketAddCard from './TicketAddCard';
import PurchaseTickets from './PurchaseTickets';
import PageLoader from 'library/common/components/PageLoader';
import './styles.scss';

const pageStyle = `
body {
  -webkit-print-color-adjust: exact;
}
@page { size: 350mm 400mm; }
`;

const Tickets = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const params = useParams();

  const [count, setCount] = useState({});
  const [isToggle, setToggle] = useState(false);
  const [isToggleSendTicket, setToggleSendTicket] = useState(false);
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureData, setFeatureData] = useState([]);
  const [ticketItemData, setticketItemData] = useState('');
  const [ticketCartlist, setTicketCartlist] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [cartItem, setClickItemData] = useState();
  const [size, setSize] = useState(window.innerWidth);

  var eventId = params?.id;

  useEffect(() => {
    // Handle window resize
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Calculate slides to show based on screen size
  const getSlideCount = size => {
    if (size > 1279) return 4;
    if (size > 959) return 3;
    if (size > 599) return 2;
    return 1;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getSlideCount(size),
    slidesToScroll: 1,
  };

  const userDetail = fetchFromStorage(identifiers?.userDetail);

  // Initialize ticket counts
  useEffect(() => {
    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var tempObj = {};
    ticektFeatureData?.map((item, index) => {
      Object.assign(tempObj, { [`count${index}`]: 1 });
    });
    setCount(tempObj);
    setFeatureData(ticektFeatureData);
  }, [props?.eventFeatures]);

  // Fetch ticket data when user is logged in
  useEffect(() => {
    props?.isLoggedIn && fetchTicketPurchase();
  }, [props?.isLoggedIn]);

  // fetch tickets list
  const fetchTicketPurchase = async () => {
    try {
      setLoading(true);
      var TICKET_PURCHASE_DATA = [];
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, false));
      if (status === 200 || status === 304) {
        TICKET_PURCHASE_DATA = data?.data?.tickets?.map(item => {
          var userName =
            item?.user?.firstName !== null && item?.user?.lastName !== null
              ? `${item?.user?.firstName} ${item?.user?.lastName}`
              : item?.user?.firstName !== null
                ? item?.user?.firstName
                : item?.user?.lastName !== null
                  ? item?.user?.lastName
                  : '';
          return {
            name: userName,
            amount: item?.user?.phoneNumber,
            toggleBundle: true,
            status: '',
            email: item?.user?.email,
            ticketNumber: item?.ticketNumber,
            id: item?.id,
            ticketName: item?.ticketName,
          };
        });
      }
      setLoading(false);
      setTicketCartlist(data?.data?.tickets);
      setTicketData(TICKET_PURCHASE_DATA);
    } catch (err) {
      setLoading(false);
    }
  };

  // Handle incrementing the ticket count
  const handleAddTicketCount = (name, index) => {
    var formData = { ...count, [name]: count[`count${index}`] + 1 };
    setCount({ ...count, [name]: count[`count${index}`] + 1 });

    var ticektFeatureData = props?.eventFeatures?.filter(item => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);
  };

  // Handle decrementing the ticket count
  const handleMinusTicketCount = (name, index) => {
    if (count[`count${index}`] > 1) {
      var formData = { ...count, [name]: count[`count${index}`] - 1 };
      setCount({ ...count, [name]: count[`count${index}`] - 1 });

      var ticektFeatureData = props?.eventFeatures?.filter(item => item.type === 'ticket');
      var TICKER_FEATURE_DATA = [];
      TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
        return {
          ...item,
          count: formData[`count${index}`],
        };
      });
      setFeatureData(TICKER_FEATURE_DATA);
    }
  };

  // single item data set
  const handleOnItemClick = item => {
    setticketItemData(item);
    setToggleModalValue(true);
  };

  // Handle input change for the ticket count input field
  const handleInputChange = event => {
    const value = parseInt(event.target.value);
    const name = event.target.name;
    var formData = { ...count, [name]: value };
    setCount({ ...count, [name]: value });

    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData?.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);
  };

  // Handle guest user or invalid feature scenarios
  const handleGuestCheckout = async ticketId => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    setLoading(true);
    var senData = [];
    var TICKET_DATA = featureData?.filter(item => item?.id === ticketId);
    senData =
      TICKET_DATA &&
      TICKET_DATA?.map(item => {
        return {
          type: 'ticket',
          quantity: item.count,
          eventId: item.eventId,
          referenceId: null,
          purchaseReferenceId: item.id,
          isRaffleTicket: false,
        };
      });

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: senData,
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };

    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setLoading(false);
        if (!props?.isLoggedIn) {
          setToggle(true);
        }
        handleSetCartData(ticketId);
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  // Handle ticket addition to cart for logged-in users
  const handleAddToCart = async ticketId => {
    if (props?.isLoggedIn && userDetail?.userType === 'normal') {
      setLoading(true);
      var senData = [];

      var TICKET_DATA = featureData?.filter(item => item?.id === ticketId);

      senData =
        TICKET_DATA &&
        TICKET_DATA?.map(item => {
          return {
            type: 'ticket',
            quantity: item?.count ? item?.count : 1,
            eventId: item?.eventId,
            referenceId: null,
            purchaseReferenceId: item?.id,
            isRaffleTicket: false,
          };
        });

      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else {
      handleGuestCheckout(ticketId);
    }
  };

  // Set checkout cart data
  const handleSetCartData = ticketId => {
    var TICKET_DATA = featureData.filter(item => item?.id === ticketId);
    let CartData =
      TICKET_DATA &&
      TICKET_DATA?.map((item, index) => {
        var ticketValue = item?.eventFeatureOptions.filter((item, index) => item.key === 'priceperticket');
        return {
          type: 'ticket',
          quantity: item.count,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: item?.featureName,
          amount: ticketValue[0]?.value,
          purchaseReferenceId: item?.id,
          isRaffleTicket: false,
          donationAmount: null,
          eventId: item?.eventId,
          currency: props?.eventDetails?.currency?.abbreviation,
          symbol: props?.eventDetails?.currency?.symbol,
          event: props?.eventDetails,
          eventName: props?.eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: item,
          cardCharedAmount: 0,
          total: ticketValue[0]?.value * item?.count,
        };
      });

    setClickItemData(CartData);
    if (props?.isLoggedIn && userDetail?.userType === 'guest') {
      updateGuestCardList(CartData);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // Handle login redirect
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'ticket',
        eventId: eventId,
      },
    });
  };

  var ticektFeatureData = props?.eventFeatures?.filter(item => item?.type === 'ticket');

  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    props?.eventDetails?.venue?.city + ' ',
    props?.eventDetails?.venue?.state + ' ',
    props?.eventDetails?.venue?.country + ' ',
    props?.eventDetails?.venue?.postCode,
  ];

  const currency = props?.eventDetails?.currency?.abbreviation;
  const currencysymbol = props?.eventDetails?.currency?.symbol;
  let flag = featureData.every(i => {
    return i?.count === 0;
  });

  var findLength = ticketCartlist?.length > 8 && (ticketCartlist?.length - 8) / 10;
  var lengthArray = [];
  var temp = 0;
  for (var i = 0; i < findLength + 1; i++) {
    var start = temp;
    var end = i === 0 ? start + 8 : start + 10;
    temp = end;
    var obj = {
      start: start,
      end: end,
    };
    lengthArray.push(obj);
  }

  let DATE_NOW = moment(new Date()).format('YYYY-MM-DD HH mm');
  let EVENT_END_DATE = moment(props?.eventDetails?.venueEndDate).format('YYYY-MM-DD HH mm');

  let isVenueEndDateDisable = DATE_NOW >= EVENT_END_DATE;

  const reactToPrintFn = useReactToPrint({ contentRef: componentRef, pageStyle: pageStyle });

  return (
    <div className="event-about-container">
      {loading && <PageLoader />}
      <div className="event-time-venu-details">
        {props?.timeZone !== undefined &&
          (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
            <EventTimeDetails
              img={images.watch}
              title={
                (props?.eventDetails?.location === 3 ? OKTION_VARIABLE?.OKTION_ONLINE : '') +
                ' ' +
                OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE
              }
              startDate={
                props?.eventDetails?.startDate
                  ? moment(props?.eventDetails?.startDate).format('hh:mm A DD MMMM, YYYY')
                  : ''
              }
              endDate={
                props?.eventDetails?.endDate ? moment(props?.eventDetails?.endDate).format('hh:mm A DD MMMM, YYYY') : ''
              }
              isLocation={false}
            />
          )}
        {props?.eventDetails?.location !== 1 && (
          <EventTimeDetails
            title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
            isLocation
            img={images.location}
            endDate={
              props?.eventDetails?.venueEndDate
                ? moment(props?.eventDetails?.venueEndDate).format('hh:mm A DD MMMM, YYYY')
                : ''
            }
            startDate={
              props?.eventDetails?.venueStartDate
                ? moment(props?.eventDetails?.venueStartDate).format('hh:mm A DD MMMM, YYYY')
                : ''
            }
            address={props?.eventDetails?.venue ? venueAddress : []}
            secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
          />
        )}
      </div>

      {/* It's print component */}
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="your-tickets-print-view-container">
          {lengthArray?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TicketPrintComponent
                  ticketCartlist={ticketCartlist.slice(item?.start, item?.end)}
                  eventDetails={props?.eventDetails}
                  venueAddress={venueAddress}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {props?.isLoggedIn && ticketCartlist.length > 0 && (
        <div className="event-details-tickets-print-container">
          <h4 className="event-details-tickets-print-title">{OKTION_VARIABLE?.OKTION_YOUR_TICKETS}</h4>
          <div className="event-details-tickets-print-sent">
            <p className="event-details-tickets-print-link cursor-pointer" onClick={() => setToggleSendTicket(true)}>
              {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
            </p>

            <p
              className="event-details-tickets-print-link print-link-hide cursor-pointer"
              onClick={() => reactToPrintFn()}>
              {OKTION_VARIABLE?.OKTION_PRINT_ALL_TICKETS}
            </p>
          </div>
        </div>
      )}

      {ticketCartlist?.length !== 0 && (
        <div className="your-raffle-tickets-right-shadow">
          <div className="your-raffle-tickets-container your-raffle-tickets-scroll">
            {ticketCartlist?.map((item, index) => (
              <React.Fragment key={index}>
                <PurchaseTickets
                  ticketData={item}
                  handleItemClick={handleOnItemClick}
                  imageUrl={props?.eventDetails?.eventImages[0].name}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      <div className="event-details-tickets-feature">
        <h4 className="feature-tickets-title">{OKTION_VARIABLE?.OKTION_TICKET_TITLE}</h4>
        <div className="ticket-add-amount-card-container">
          {ticektFeatureData?.map((item, index) => (
            <div
              key={index}
              // style={{
              //   pointerEvents: item?.isActive === false ? 'none' : '',
              //   opacity: item?.isActive === false ? '0.4' : '1',
              // }}
            >
              <TicketAddCard
                ticketCardData={item}
                countIndex={`count${index}`}
                ticketIndex={index}
                count={count}
                eventDetails={props?.eventDetails}
                handleAdd={handleAddTicketCount}
                handleMinus={handleMinusTicketCount}
                handleAddToCart={handleAddToCart}
                whitelabel={props?.whitelabel}
                isVenueEndDateDisable={isVenueEndDateDisable}
                handleInputChange={handleInputChange}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="res-org-tickets">
        {isToggle && (
          <LogoutModal
            isToggle={isToggle}
            toggle={() => setToggle(!isToggle)}
            handleClick={handleLoginRedirect}
            isGuestCheckout
            singleItem={cartItem}
          />
        )}
        {isToggleSendTicket && (
          <SendTicketModal
            isToggle={isToggleSendTicket}
            toggle={() => setToggleSendTicket(!isToggleSendTicket)}
            ticketData={ticketData}
          />
        )}
        {isToggleModal && (
          <TicketDataModal
            isToggle={isToggleModal}
            toggle={() => setToggleModalValue(!isToggleModal)}
            ticketItemData={ticketItemData}
            eventDetails={props?.eventDetails}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Tickets);
