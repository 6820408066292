import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
// import Select, { components } from 'react-select';
// import { ReactComponent as DropDownIcon } from '../../../../../../resources/images/dropdown_arrow.svg';
import { SocketContext } from '../../../../../../main/context/socket';
import { CustomTextField } from 'library/common/components/textfield';
import FloatingLabelSelect from 'library/common/components/selectField';

const SearchBox = ({ onSearch, searchValue, handleSelect, eventDetails, categoryList }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('all');
  const [inputValue, setInputValue] = useState('');
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      setInputValue(searchValue);
    }
  }, [searchValue]);

  const handleValueChange = event => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    onSearch(inputValue);
  };

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const handleChange = e => {
    setSelectedValue(e?.categoryName || e);
    handleSelect(e?.id);
  };

  // // Dropdown Indicator component for custom styling
  // const DropdownIndicator = props => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <DropDownIcon />
  //     </components.DropdownIndicator>
  //   );
  // };

  return (
    <div className="search-container">
      {/* <Select
        className="React search-select"
        value={categoryList?.find(item => {
          return item?.value === selectedValue;
        })}
        onChange={e => handleChange(e)}
        options={categoryList}
        classNamePrefix="select"
        placeholder="Select"
        label="Category"
        components={{ DropdownIndicator }}
      /> */}
      <FloatingLabelSelect
        className="search-select"
        value={categoryList?.find(item => {
          return item?.value === selectedValue;
        })}
        onChange={e => handleChange(e)}
        options={categoryList}
        classNamePrefix="select"
        placeholder="Category"
      />
      <CustomTextField
        fullWidth
        className="custom-textfield auction-search-field"
        onChange={handleValueChange}
        type="text"
        placeholder={OKTION_VARIABLE?.OKTION_SEARCH_AUCTION_LOT}
        label={OKTION_VARIABLE?.OKTION_SEARCH_AUCTION_LOT}
      />
      <Button
        className="btn-primary auction-search-button"
        onClick={handleSearch}
        style={{
          backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
          borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
          color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
        }}>
        {OKTION_VARIABLE?.OKTION_SEARCH}
      </Button>
    </div>
  );
};

export default SearchBox;
