import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import Countdown from 'react-countdown';
import moment from 'moment';
import { images } from 'library/common/constants/ImageConstants';
import { SocketContext } from 'main/context/socket';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { fetchFromStorage, CurrencyFormat } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import CongratulationOverlay from './CongratulationOverlay';
import RaffleWallCard from './RaffleWallCard';
import TicketSold from './TicketSold';
import PageLoader from 'library/common/components/PageLoader';
import '../../styles.scss';

const RaffleWall = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const location = useLocation();

  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [raffleFeatureDetails, setRaffleFeatureDetails] = useState('');

  var eventDetails = fetchFromStorage(identifiers?.eventDetails);

  const raffleFeatureData = location?.state?.raffleFeatureData?.[0];

  const renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <div>
        {days > 0 ? ('0' + days).slice(-2) : '00'}:{hours > 0 ? ('0' + hours).slice(-2) : '00'}:
        {minutes > 0 ? ('0' + minutes).slice(-2) : '00'}
      </div>
    );
  };
  //  fetch raffle wall details
  const fetchRaffleWallDetails = async () => {
    setLoading(true);
    var raffleItem = raffleFeatureData && raffleFeatureData;
    try {
      const { status, data } = await axiosInstance.get(URLS.getRaffleWallDetails(raffleItem?.id));
      if (status === 200) {
        setRaffleFeatureDetails(data?.data);
        setLoading(false);
        data?.data?.item?.isDraw && data?.data?.item?.isDraw === '1' && setFlag(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRaffleWallDetails();
    userSocket.socket.on('RaffleWall', data => {
      setRaffleFeatureDetails(data?.data);
      data?.data?.item?.isDraw && data?.data?.item?.isDraw === '1' && setFlag(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 9000);
  });

  return (
    <div className="raffleWall">
      {loading && <PageLoader />}
      <Container fluid={true}>
        <Row>
          <Col sm="4 ps-sm-0">
            <div className="left-side">
              {eventDetails && eventDetails?.eventImages !== null && eventDetails?.eventImages[0]?.name !== '' ? (
                <img
                  className="imageBox"
                  src={
                    eventDetails &&
                    eventDetails?.eventImages.length > 0 &&
                    identifiers.imageUrlConstant + '' + eventDetails?.eventImages[0]?.name
                  }
                  alt={eventDetails?.eventName || 'EventImage'}
                  onError={e => {
                    e.target.src = images?.logo; // Set the default image
                    e.target.className = 'error-bg-img imageBox'; // Add error class name
                  }}
                  onLoad={e => {
                    if (e.target.src !== window.location.origin + images?.logo) {
                      // Check if the image is not the default
                      e.target.className = 'imageBox'; // Reset the class name
                    }
                  }}
                />
              ) : (
                <div className="box" />
              )}
              <div className="text-center mb-5">
                <h1 className="fs-70">{OKTION_VARIABLE?.OKTION_LIVE_RAFFLE}</h1>
                <h3 className="fs-36">{OKTION_VARIABLE?.OKTION_GET_YOUR_TICKET}</h3>
              </div>
              <div className="text-center mb-5">
                <h2 className="mb-2 fs-36">{OKTION_VARIABLE?.OKTION_TIME_REMAINING}</h2>
                <h1 className="fw-normal fs-63">
                  <Countdown
                    date={moment.utc(raffleFeatureDetails?.featureDetail?.startTime).format('YYYY-MM-DD hh:mm:ss')}
                    renderer={renderTime}
                  />
                </h1>
              </div>
              <div className="text-center mb-3">
                <h2 className="mb-2 fs-36">{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED}</h2>
                <h1 className="fw-normal fs-63">
                  <CurrencyFormat
                    value={raffleFeatureDetails?.amountRaised?.raffleAmount || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={
                      raffleFeatureDetails && raffleFeatureDetails.currency !== null
                        ? raffleFeatureDetails?.currency?.abbreviation + ' ' + raffleFeatureDetails?.currency?.symbol
                        : ''
                    }
                    thousandSpacing={raffleFeatureDetails?.currency?.symbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    className="fs-50"
                  />
                </h1>
              </div>
              <div className="text-center">
                <img
                  src={images.raffleWallImg}
                  alt=""
                  onError={e => {
                    e.target.src = images?.logo; // Set the default image
                    e.target.className = 'error-bg-img '; // Add error class name
                  }}
                  onLoad={e => {
                    if (e.target.src !== window.location.origin + images?.logo) {
                      // Check if the image is not the default
                      e.target.className = ''; // Reset the class name
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm="8" className="mb-4">
            <RaffleWallCard raffleWallData={raffleFeatureDetails?.item?.rafflePrizeOptions} />
            <TicketSold totalTicket={raffleFeatureDetails?.item?.totalTicket} />
          </Col>
        </Row>
      </Container>
      {flag && <CongratulationOverlay />}
    </div>
  );
};

export default RaffleWall;
