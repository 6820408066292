import React, { lazy, useEffect, useState, useContext, useRef, useMemo } from 'react';
import { Route, Routes, useLocation, Navigate, useNavigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import RaffleWall from '../../modules/Events/MyEvents/frames/Features/frames/Raffle/Frame/RaffleWall/RaffleWall';
import AuctionWall from '../../modules/Events/MyEvents/frames/Features/frames/Auction/Frame/AuctionWall/AuctionWall';
import SupplyItem from '../../modules/SupplyItem/SupplyItem';
import Preview from '../../modules/Events/MyEvents/frames/WhiteLabel/Preview';
import OrganiserProfile from '../../modules/Organization/AllOrganization/frames/OrganiserProfile';
import TermsAndCondition from '../../modules/Authentication/frames/Terms&Condition/terms_and_Condition';
import RemoveOrganizationModal from '../../../src/modules/Authentication/frames/SignupOrganization/RemoveOrganizationModal';
// import NewEventSelectModal from '../../../src/modules/Events/CreateEvent/NewEvent';
import VersionConroll from 'modules/VersionControll';
import { images } from '../../library/common/constants/ImageConstants';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { fetchFromStorage, removeFromStorage } from '../../utility';
import headerResponseEventEmitter from 'library/utilities/headerResponse';
import { getNotificationList, setMailList } from '../../library/common/actions/NotificationAction';
import { getUserDetails, showNativePrompt } from '../../library/common/actions/AuthActions';
import { SocketContext } from '../../main/context/socket';
import './styles.scss';

const AllOrganization = lazy(() => import('../../modules/Organization/AllOrganization/AllOrganization'));
const Notifications = lazy(() => import('../../modules/Notifications/Notifications'));
const Following = lazy(() => import('../../modules/Follow/Follow'));
const EventDetails = lazy(() => import('../../modules/EventDetails/EventDetails'));
const AppNavbar = lazy(() => import('library/common/components/AppNavbar'));
const Footer = lazy(() => import('library/common/components/Footer'));
const Home = lazy(() => import('modules/Home'));
const UserProfile = lazy(() => import('modules/UserProfile'));
const SearchResult = lazy(() => import('modules/SearchResult'));
const MobileViewSearch = lazy(() => import('modules/MobileViewSearch'));
const MobileViewSearchRearch = lazy(() => import('modules/MobileViewSearchResult'));
const MobileViewApplyFilter = lazy(() => import('modules/MobileViewApplyFilter'));
const Authentication = lazy(() => import('modules/Authentication'));
const Organization = lazy(() => import('modules/Organization'));
const CreateEvent = lazy(() => import('modules/Events/CreateEvent'));
const Checkout = lazy(() => import('modules/Checkout'));
const GuestCheckout = lazy(() => import('modules/Checkout/GuestCheckout/'));
const GuestAsEmail = lazy(() => import('modules/Checkout/GuestCheckout/EmailVerify'));
const ManageEvents = lazy(() => import('modules/Events/ManageEvents'));
const MyEvents = lazy(() => import('modules/Events/MyEvents'));
const MyEventsPage = lazy(() => import('modules/MyEvents/MyEventsPage'));
const MyContributionPage = lazy(() => import('../../library/common/components/EventCard/MyContribution'));
const ContributorSupplyItems = lazy(() => import('modules/ContributorSupplyItems/ContributorSupplyItems'));
const MailPage = lazy(() => import('modules/Mail/mail'));
const ContributorSuplySearchResult = lazy(
  () => import('modules/ContributorSupplyItems/Frames/SearchResult/ContributorSuplySearchResult'),
);
const NotFound = lazy(() => import('modules/NotFound'));
const CrashPage = lazy(() => import('modules/CrashPage'));
const MaintainancePage = lazy(() => import('modules/MaintainancePage'));

const AllRoutes = ({ getNotificationList, getUserDetails, setMailList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [removeOrganizationModal, setRemoveOrganizationModal] = useState(false);
  const [isUpgradeRequiredToggle, setIsUpgradeRequiredToggle] = useState(false);
  const [orgSocketData, setOrgSocketData] = useState({});
  const [close, setClose] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState('');
  const [isMaintenanceType, setIsMaintenanceType] = useState('');
  const [isCrash, setIsCrash] = useState(false);

  // Ref for managing modal messages
  const removeMsgRef = useRef(null);

  // Clear storage if user navigates to specific routes
  if (location?.pathname === '/manage-event/' || location?.pathname === '/') {
    removeFromStorage(identifiers.color_data);
  }

  // Effect hook to listen for header updates from an event emitter
  useEffect(() => {
    // Callback function to handle 'Upgrade-Required' header response
    const handleCustomHeadersUpdated = version => {
      if (version === 'Upgrade-Required') {
        setIsUpgradeRequiredToggle(true);
      }
    };
    // Subscribe to custom header events via event emitter
    headerResponseEventEmitter.on('headerResponseUpdated', handleCustomHeadersUpdated);

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      headerResponseEventEmitter.off('headerResponseUpdated', handleCustomHeadersUpdated);
    };
  }, []);

  useEffect(() => {
    // Reset crash state and remove crash class from body on initial render or when location changes
    setIsCrash(false);
    document.body.classList.remove('crash');

    // Function to handle crash based on the HTTP status code in response
    const handleHeadersDetails = response => {
      const errorCode = response?.status;
      // If response status is 404, 500, or 503, trigger a crash state after a slight delay
      if (errorCode === 404 || errorCode === 500 || errorCode === 503) {
        setTimeout(() => {
          setIsCrash(true); // Set crash state to true
          document.body.classList.add('crash'); // Add crash class to body for visual effect
        }, 10); // Delay for smooth transition
      }
    };

    // Subscribe to the 'oktionCrash' event emitted by headerResponseEventEmitter
    headerResponseEventEmitter.on('oktionCrash', handleHeadersDetails);

    // Cleanup function: Unsubscribe from the event when component unmounts or location changes
    return () => {
      headerResponseEventEmitter.off('oktionCrash', handleHeadersDetails);
    };
  }, [location?.pathname]);

  // MAINTENANC REDIRECT
  useEffect(() => {
    // Function to handle maintenance mode updates
    const handleMaintenanceUpdated = maintenanceData => {
      // If maintenance mode is ON, proceed with handling redirection or localStorage updates
      if (maintenanceData?.maintenance === 'ON') {
        setIsMaintenance(maintenanceData?.maintenance);
        setIsMaintenanceType(maintenanceData?.maintenanceType);

        // Store the current page in localStorage unless already on the maintenance page
        localStorage.setItem(
          'previous_page',
          window.location.pathname === '/maintainance.html'
            ? '/'
            : window.location?.search !== ''
              ? window.location.pathname + window.location?.search
              : window.location.pathname,
        );
        // If maintenance type is 'redirect', redirect the user to the maintenance page
        if (maintenanceData?.maintenanceType === 'redirect') {
          setTimeout(() => {
            window.location.href = '/maintainance.html';
          }, 10);
        }
      } else {
        // If maintenance is OFF, remove the stored page
        localStorage.removeItem('previous_page');
      }
    };

    // Subscribe to the 'maintenanceMode' event to listen for maintenance updates
    headerResponseEventEmitter.on('maintenanceMode', handleMaintenanceUpdated);

    // Cleanup function: Unsubscribe from the event when the component unmounts
    return () => {
      headerResponseEventEmitter.off('maintenanceMode', handleMaintenanceUpdated);
    };
  }, []);

  const token = fetchFromStorage(identifiers.token);
  // Memoize authentication status
  const isAuthenticated = useMemo(() => {
    return token !== null;
  }, [token]);

  // Effect to fetch notifications and user details if logged in
  useEffect(() => {
    if (isAuthenticated) {
      getNotificationList(1, 10);
      // getUserDetails();
    }
  }, [isAuthenticated]);

  // Effect to handle socket events and native prompts
  useEffect(() => {
    (location.pathname === '/login' || location.pathname === '/signup') && showNativePrompt();
    // Listen for organization removal event
    userSocket.socket.on('RemovedFromOrganisation', data => {
      handleRemoveOrg(); // Handle organization removal
      setOrgSocketData(data); // Set organization socket data
      EventEmitter.dispatch('RemovedFromOrganisation', data); // Dispatch event
    });

    setMailList(); // Set the mail list
  }, []);

  // Function to show the remove organization modal
  const handleRemoveOrg = () => {
    setRemoveOrganizationModal(true);
  };

  // Function to handle publishing after organization removal
  const handlePublish = () => {
    var currentOrg = JSON.parse(localStorage.getItem('currentOrganization'));
    // Check if the current organization matches the one being remove
    if (currentOrg === orgSocketData?.data?.organisationId) {
      navigate('/all-organizations'); // Navigate to all organizations
    } else {
      setRemoveOrganizationModal(false); // Close modal if not matching
    }
    setRemoveOrganizationModal(false); // Close modal
  };

  // Function to toggle visibility of a message
  const handleToggle = () => {
    if (removeMsgRef?.current) {
      removeMsgRef.current.classList.add('remove-msg'); // Add class to remove message
    }
    setClose(false);
  };

  // Private route component for authentication
  const PrivateRoute = ({ element, ...rest }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
  };

  // Determine if the user is logging in or on signup pages
  let isLogin = location.pathname === '/welcome-message' || location.pathname === '/signup-organization';

  // Check for specific auction or raffle wall routes
  let checkLocation =
    location.pathname.includes('/auction-wall/table/') ||
    location.pathname.includes('/auction-wall/item/') ||
    location.pathname.includes('/raffle-wall');

  // Handle URL and session storage for event IDs
  var url = window.location.href; // Get current URL
  const spliturl = url?.split('/'); // Split URL by '/'
  const SplitRaffleUrl = url?.split('?'); // Split URL by '?'
  const eventId = spliturl && spliturl[spliturl?.length - 1]; // Get last part of URL as eventId
  var raffleSplitUrl = spliturl && spliturl[spliturl?.length - 1]; // Same as above for raffle
  var event_id = parseInt(eventId); // Parse eventId to integer

  if (!isNaN(event_id)) {
    sessionStorage.setItem('urlValue', parseInt(eventId)); // Store eventId in sessionStorage
  }

  // Check for raffle event in URL and manage session storage
  if (SplitRaffleUrl[SplitRaffleUrl?.length - 1] === 'event=Raffle') {
    sessionStorage.setItem('rafflrUrlValue', raffleSplitUrl); // Store raffle URL value
    sessionStorage.removeItem('urlValue'); // Remove urlValue if it’s a raffle event
  }

  return (
    <>
      {/* Render the AppNavbar only if the user is not on a specific location and maintenance is not ON */}
      {checkLocation === false && isMaintenance !== 'ON' && <AppNavbar isLogin={isLogin} />}
      <div
        className={
          location.pathname === '/my-event-page' || location.pathname.includes('/event-details')
            ? ' bg-gray main-content'
            : location.pathname === '/following'
              ? 'bg-gray main-content container-main'
              : location.pathname === '/notifications'
                ? 'container-main main-content'
                : location.pathname === '/mail'
                  ? 'container-main main-content'
                  : 'main-content'
        }>
        {/* Display a welcome message if create new Org. */}
        {close === true ? (
          <div
            ref={removeMsgRef}
            className={location.pathname === '/organization/organiser-details' ? 'welcome-message' : ''}>
            {location.pathname === '/organization/organiser-details' ? (
              <>
                <p>{OKTION_VARIABLE?.OKTION_WELCOME_TEXT}</p>
                <div>
                  <img className="close-icon cursor-pointer" onClick={handleToggle} src={images.close} alt="" />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {/* Render MaintenancePage if maintenance is ON and type is inline */}
        {isMaintenance === 'ON' && isMaintenanceType === 'inline' && <MaintainancePage />}
        {/* Render CrashPage if there’s a crash */}
        {isCrash && <CrashPage />}
        {/* Render main routes if not in maintenance mode or a crash */}
        {isMaintenance !== 'ON' && isMaintenanceType !== 'inline' && !isCrash && (
          <>
            <Routes>
              {/* Public Routes */}
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Authentication />} />
              <Route exact path="/signup" element={<Authentication />} />
              <Route exact path="/guest-signup" element={<Authentication />} />
              <Route exact path="/verify" element={<Authentication />} />
              <Route exact path="/resend" element={<Authentication />} />
              <Route exact path="/forgot-password" element={<Authentication />} />
              <Route exact path="/terms-and-condition" element={<TermsAndCondition />} />
              <Route exact path="/forgot-reset-password" element={<Authentication />} />
              <Route exact path="/welcome-message" element={<Authentication />} />
              <Route exact path="/search-result" element={<SearchResult />} />
              <Route exact path="/mobile-search" element={<MobileViewSearch />} />
              <Route exact path="/mobile-search-result" element={<MobileViewSearchRearch />} />
              <Route exact path="/mobile-apply-filter" element={<MobileViewApplyFilter />} />
              <Route exact path="/raffle-wall" element={<RaffleWall />} />
              <Route exact path="/auction-wall/:slug/:id" element={<AuctionWall />} />
              <Route exact path="/event-details" element={<EventDetails />} />
              <Route path={'/event-details/:id'} element={<EventDetails />} />
              <Route exact path="/event-details/:id" element={<EventDetails />} />
              <Route exact path="/following" element={<Following />} />
              <Route exact path="/preview/:id" element={<Preview />} />
              <Route exact path="/guest-checkout" element={<GuestCheckout />} />
              <Route exact path="/guest-as-email" element={<GuestAsEmail />} />
              {/* Private Routes - requires authentication */}
              <Route element={<PrivateRoute />}>
                <Route exact path="/signup-organization" element={<Authentication />} />
                <Route exact path="/organization/:id" element={<Organization />} />
                <Route exact path="/create-event" element={<CreateEvent />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/manage-event" element={<ManageEvents />} />
                <Route exact path="/manage-event/:id" element={<ManageEvents />} />
                <Route exact path="/my-event/:id" element={<MyEvents />} />
                <Route exact path="/my-event-page" element={<MyEventsPage />} />
                <Route exact path="/my-event-page/contribution/:id" element={<MyContributionPage />} />
                <Route exact path="/user-profile" element={<UserProfile />} />
                <Route exact path="/contributor-supply-items" element={<ContributorSupplyItems />} />
                <Route exact path="/contributor-supply-search-result" element={<ContributorSuplySearchResult />} />
                <Route exact path="/supply-item" element={<SupplyItem />} />
                <Route exact path="/social-links" element={<SupplyItem />} />
                <Route exact path={'/private-event-details/:id'} element={<EventDetails />} />
                <Route exact path="/notifications" element={<Notifications />} />
                <Route exact path={'/all-organizations'} element={<AllOrganization />} />
                <Route exact path="/organiser-profile/:id" element={<OrganiserProfile />} />
                <Route exact path="/mail" element={<MailPage />} />
                <Route exact path="/mail/:id" element={<MailPage />} />
              </Route>
              {/* Not Found Route */}
              <Route exact path="/not-found" element={<NotFound />} />
              {/* Redirect to Not Found page for unmatched routes */}
              <Route render={() => <Navigate to="/not-found" />} />
            </Routes>
          </>
        )}
      </div>
      {/* Render the Footer only if the user is not on a specific location and maintenance is not ON */}
      {!checkLocation && location.pathname !== '/crash' && !isCrash && isMaintenance !== 'ON' && <Footer />}

      {/* Render RemoveOrganizationModal if triggered */}
      {removeOrganizationModal && (
        <RemoveOrganizationModal
          isToggle={removeOrganizationModal}
          toggle={setRemoveOrganizationModal}
          handleClick={handlePublish}
          orgSocketData={orgSocketData}
        />
      )}

      {/* Render VersionConroll modal if upgrade is required */}
      {isUpgradeRequiredToggle && <VersionConroll isToggle={isUpgradeRequiredToggle} />}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { getNotificationList, getUserDetails, setMailList })(AllRoutes);
