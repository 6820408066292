import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Modal, ModalBody } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FaFacebookF, FaLinkedinIn, FaPinterest, FaWhatsapp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { PiCopy } from 'react-icons/pi';
import { IoBookmarkSharp } from 'react-icons/io5';
import { Button, IconButton } from '@mui/material';
import anchorme from 'anchorme';
import parse from 'html-react-parser';
// import { MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import axiosInstance from '../../main/axios';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import Config from '../../utility/Config';
import { EventEmitter } from 'library/common/constants/event';
import { setApiMessage, getAppLinkByMobileOS } from '../../library/common/constants/function';
import EventCard from '../../library/common/components/EventCard';
import EventTabWrapper from './TabWrapper';
import PageLoader from '../../library/common/components/PageLoader';
import NoOrgModal from '../../modules/Events/CreateEvent/NoOrganisationModal';
import LogoutModal from './Frames/Raffle/LogoutModal';
import { SocketContext } from '../../main/context/socket';
import { images } from '../../library/common/constants/ImageConstants';
import { ReactComponent as SocialFacebook } from '../../resources/images/social_facebook.svg';
import { ReactComponent as SocialInstagram } from '../../resources/images/social_instagram.svg';
import { ReactComponent as SocialYoutube } from '../../resources/images/social_youtube.svg';
import { ReactComponent as SocialTwitter } from '../../resources/images/social_twitter.svg';
import { ReactComponent as SocialLinkedin } from '../../resources/images/social_linkedin.svg';
import { ReactComponent as SocialPintrest } from '../../resources/images/social_pintrest.svg';
import { ReactComponent as SocialCustom } from '../../resources/images/social_custom.svg';
import QRCode from 'react-qr-code';
import { ReactComponent as Closed } from '../../resources/images/close.svg';
import './styles.scss';
import EventCardComponent from 'library/common/components/EventCardComponent';

const EventDetails = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [eventOutfit, setEventOutfit] = useState();
  const [loading, setloader] = useState(false);
  const [isNoOrgToggle, setIsNoOrgToggle] = useState(false);
  const [isloginToggle, setLoginToggle] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [modalErrorText, setModalErrorText] = useState('');
  const [qrModal, setQrModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const params = useParams();

  const appInfo = {
    imageUrl: images.app_logo,
    name: 'Oktion',
    publisher: 'DigiGround Pty Ltd',
    infoText: 'Free',
    linkButtonText: 'VIEW',
    linkUrl: '',
  };

  appInfo.linkUrl = getAppLinkByMobileOS();

  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  var eventId = params?.id !== undefined ? params.id : eventDetails?.id;

  // Effect for window resize handling
  useEffect(() => {
    const updateDimensions = () => setWidth(window.innerWidth);
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    var checkForUserLogin = fetchFromStorage(identifiers.token);
    if (checkForUserLogin !== null) {
      getEventDetailsByToken();
    } else {
      getEventDetailsById();
    }
    userSocket.socket.on('EventDetail', data => {
      EventEmitter.dispatch('socketUpdateEventDetailsData', data);
    });
    EventEmitter.subscribe('socketUpdateEventDetailsData', event => {
      Number(params?.id) === event?.events?.id && setEventOutfit(event?.events && event?.events);
    });

    Object.keys(params).length === 0 && navigate('/');
  }, []);

  // Fetches the social links list based on organisation ID
  const getSocialLinksList = async orgID => {
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getsocialLInks(orgID));
      if (status === 200 || status === 201) {
        // Filter non-empty social links from both default and custom lists
        let socialLinks = data?.data?.OrganisationSocialLinksList?.filter(item => item?.linkUrl !== '');
        let otherLinks = data?.data?.OrganisationCustomSocialLinksList?.filter(item => item?.linkUrl !== '');

        setSocialData([...socialLinks, ...otherLinks]); // Merges and updates the state with social links data
      }
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  // Returns the appropriate social media icon component based on the type
  const getSocialIcon = type => {
    if (type === 'facebook') {
      return <SocialFacebook />;
    } else if (type === 'instagram') {
      return <SocialInstagram />;
    } else if (type === 'youtube') {
      return <SocialYoutube />;
    } else if (type === 'twitter') {
      return <SocialTwitter />;
    } else if (type === 'linkedin') {
      return <SocialLinkedin />;
    } else if (type === 'pinterest') {
      return <SocialPintrest />;
    } else if (type === 'custom') {
      return <SocialCustom />;
    }
  };

  // Fetches event details based on event ID
  const getEventDetailsById = async () => {
    setloader(true);
    const previewStatus = location?.state?.previewStatus; // Fetches preview status from location state
    var prevStatus = previewStatus !== undefined ? previewStatus : 0; // Sets preview status
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        // Checks authorization
        if (data?.msg === 'You are not authorized') {
          setModalErrorText(''); // Clears modal error text
          setIsNoOrgToggle(true); // Opens modal for unauthorized users
        } else {
          var tempData = data?.data?.events; // Event data

          // Fetches social links based on organisation ID
          getSocialLinksList(tempData?.organisation?.id);

          // Prepares event display data based on preview props
          tempData = {
            ...tempData,
            primaryColour: props?.previewPropsValue?.hexCode[0],
            primaryTextColour: props?.previewPropsValue?.hexCode[1],
            secondaryColour: props?.previewPropsValue?.hexCode[2],
            secondaryTextColour: props?.previewPropsValue?.hexCode[3],
            background: props?.previewPropsValue?.background,
            pictureFit: props?.previewPropsValue?.backgroundSize,
            backgroundProperty: props?.previewPropsValue?.backgroundProperty,
          };

          setEventOutfit(tempData);
        }
      }
      setloader(false);
    } catch (err) {
      setloader(false);
      // Handles specific error codes
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setIsNoOrgToggle(true); // Opens modal for cancelled or suspended event
        setModalErrorText(err?.response?.data?.msg); // Sets error message
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/'); // Redirects to homepage if event doesn't exist
      }
    }
  };

  // Scrolls to top and shows success message when link is copied
  const handleCopyLink = async => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setApiMessage('success', `${OKTION_VARIABLE?.OKTION_COPIED}!`);
  };

  // Fetches event details using a token for authorization
  const getEventDetailsByToken = async () => {
    var token = fetchFromStorage(identifiers.token); // Retrieves token from storage
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByToken(eventId, token?.token, prevStatus));
      setloader(false);
      if (status === 200 || status === 304) {
        // Handles unauthorized access
        if (data?.msg === 'You are not authorized') {
          setModalErrorText(''); // Clears modal error text
          setIsNoOrgToggle(true); // Opens unauthorized access modal
        } else {
          var tempData = data?.data?.events;
          getSocialLinksList(tempData?.organisation?.id); // Fetches organisation's social links

          // Handles whitelabel (custom branding) data
          if (props.whitelabel) {
            tempData = {
              ...tempData,
              primaryColour: props?.previewPropsValue?.hexCode[0],
              primaryTextColour: props?.previewPropsValue?.hexCode[1],
              secondaryColour: props?.previewPropsValue?.hexCode[2],
              secondaryTextColour: props?.previewPropsValue?.hexCode[3],
              background: props?.previewPropsValue?.background,
              pictureFit: props?.previewPropsValue?.backgroundSize,
              backgroundProperty: props?.previewPropsValue?.backgroundProperty,
            };
            setEventOutfit(tempData);
          } else {
            setEventOutfit(data?.data?.events);
          }
        }
      }
    } catch (err) {
      setloader(false);
      // Handles specific error codes
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setModalErrorText(err?.response?.data?.msg); // Sets error message for suspended/cancelled event
        setIsNoOrgToggle(true); // Opens modal
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/'); // Redirects to homepage if event doesn't exist
      }
    }
  };

  // Handles navigation to organiser profile page when click on contact
  const handleContactBtn = () => {
    navigate(`/organiser-profile/${eventOutfit?.organisation?.id}`, {
      state: {
        eventId: params?.id,
        orgId: eventOutfit?.organisation?.id,
      },
    });
  };

  // Handles item click and reloads the page
  const handleItemClick = event => {
    window.location.reload(false);
    navigate(`/event-details/${event?.id}`);
  };

  // Handles follow/unfollow functionality based on the isFollow state
  const handleFollowUnfollow = isFollow => {
    if (!props?.isLoggedIn) {
      setLoginToggle(true); // Toggles login modal if user is not logged in
    }
    if (props?.isLoggedIn) {
      handleAddToFollow(isFollow); // Calls function to follow/unfollow event
    }
  };

  // Adds or removes event from follow list based on the isFollow parameter
  const handleAddToFollow = async isFollow => {
    try {
      if (isFollow === false) {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventFollow(eventId)); // API call to follow event
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED); // Shows success message for follow
          getEventDetailsByToken(); // Refreshes event details
        }
        setloader(false);
      } else {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventUnFollow(eventId)); // API call to unfollow event
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST); // Shows success message for unfollow
          getEventDetailsByToken(); // Refreshes event details
        }
        setloader(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setApiMessage('error', err?.response?.data?.msg);
        setloader(false);
      }
    }
    setloader(false);
  };

  // Handle logout modal if you are not login
  const handleLoginModal = () => {
    setLoginToggle(!isloginToggle);
  };

  // Handle redirect to login page
  const handleModalLoginBtn = () => {
    navigate('/login', {
      redirectTo: 'event-details',
      eventId: params?.id,
    });
  };

  // Handles the add to calendar if loggeding
  const handleAddToCalendarBtn = () => {
    if (!props?.isLoggedIn) {
      setLoginToggle(true);
    }
    if (props?.isLoggedIn) {
      handleAddToCalendar();
    }
  };

  // Makes API call to add event to user's calendar
  const handleAddToCalendar = async () => {
    setloader(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.addToCalender(eventId));
      if (status === 200 || status === 201) {
        setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED_IN_CALENDER);
        setTimeout(() => {
          navigate(`/my-event-page`);
        }, 4000);
        setloader(false);
      }
    } catch (err) {
      if (err?.response?.status === 403 && err?.response?.data?.msg === OKTION_VARIABLE?.OKTION_ALREADY_ADDED) {
        setApiMessage('error', err?.response?.data?.msg);
        setloader(false);
      }
    }
    setloader(false);
  };

  // Determine the background color based on the location state
  var backColor =
    location?.state?.hexCode?.background_picture === false ? location?.state?.hexCode?.background_color : '';

  // Define the default background color based on the eventOutfit object
  var defBackColoc = eventOutfit?.background === 'color' ? eventOutfit?.backgroundProperty : '';

  // Determine the background picture based on the location state
  var backPicture = location?.state?.background === 'picture' ? `url(${location?.state?.backgroundProperty})` : '';

  // Define the default background picture based on the eventOutfit object
  var defPicture = eventOutfit?.background === 'picture' ? `url(${eventOutfit?.backgroundProperty})` : '';

  const isSaveBookmark = eventOutfit?.isFollow && eventOutfit?.isFollow === true;

  return (
    <div
      className="eventDetails event-details-wrapper home-container default-color"
      style={{
        background: props?.whitelabel === true ? backPicture : defPicture,
        backgroundColor: props?.whitelabel === true ? backColor : defBackColoc,
        backgroundSize: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : 'contain',
        backgroundPosition: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
        backgroundRepeat: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
      }}>
      <div className="container">
        {/* <MobileView>
          <AppBanner appInfo={appInfo} position="bottom" onClose={() => {}} hidden={false} style={{ zIndex: 15 }} />
        </MobileView> */}
        <div>{loading && <PageLoader />}</div>{' '}
        {isNoOrgToggle === false && modalErrorText === '' && eventOutfit !== undefined ? (
          <>
            <div className="event-details-container">
              <div className="event-left-side-container">
                {/* event image */}
                <div className="event-image-wrapper">
                  <img
                    src={
                      eventOutfit?.eventImages !== null &&
                      identifiers?.imageUrlConstant + eventOutfit?.eventImages[0]?.name
                    }
                    onError={e => {
                      e.target.src = images?.logo; // Set the default image
                      e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
                    }}
                    onLoad={e => {
                      if (e.target.src !== window.location.origin + images?.logo) {
                        // Check if the image is not the default
                        e.target.className = ''; // Reset the class name
                      }
                    }}
                    alt={eventOutfit?.eventName}
                  />
                </div>
                {/* event name */}
                <h1 className="event-name-text">
                  {eventOutfit &&
                    eventOutfit?.eventName != null &&
                    parse(
                      anchorme({
                        input: (eventOutfit?.eventName).toString().replace(/\n/g, '<br/>'),
                        options: {
                          attributes: {
                            target: '_blank',
                            class: 'detected',
                          },
                        },
                      }),
                    )}
                </h1>

                {/* event tabs */}
                <EventTabWrapper
                  eventDetails={eventOutfit}
                  preview_color={props.preview_color}
                  featureName={location?.state?.featureName}
                  whitelabel={props?.whitelabel}
                />
              </div>
              <div className="event-right-side-container">
                {/* QR code container */}
                <div className="event-qr-code-container">
                  <div className="event-qr-wrap mb18">
                    <QRCode id="QRCode" size={99} value={`${Config.socketUrl}event-details/${params?.id}`} />
                    <div className="qr-content ml18">
                      <h4 className="qr-head">{OKTION_VARIABLE?.OKTION_QR}</h4>
                      <span
                        className="qr-larg-view-link"
                        onClick={() => {
                          setQrModal(!qrModal);
                        }}>
                        {OKTION_VARIABLE?.OKTION_LARGE_VIEW}
                      </span>
                    </div>
                  </div>
                  {/* social icons */}
                  <div className="social-media-main">
                    <div>
                      <IconButton style={{ background: '#3B5998' }}>
                        <FacebookShareButton
                          url={`${Config.socketUrl}event-details/${eventId}`}
                          className="socialMediaIcons">
                          <FaFacebookF fontSize="inherit" size="20px" />
                        </FacebookShareButton>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton style={{ background: '#000000' }}>
                        <TwitterShareButton
                          url={`${Config.socketUrl}event-details/${eventId}`}
                          className="socialMediaIcons">
                          <FaXTwitter fontSize="inherit" size="20px" />
                        </TwitterShareButton>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton style={{ background: '#007BB5' }}>
                        <LinkedinShareButton
                          url={`${Config.socketUrl}event-details/${eventId}`}
                          className="socialMediaIcons">
                          <FaLinkedinIn fontSize="inherit" size="20px" />
                        </LinkedinShareButton>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton style={{ background: '#CB2027' }}>
                        <PinterestShareButton
                          url={`https://pinterest.com/pin/create/button/?url=${Config.socketUrl}event-details/${eventId}&media=&description=`}
                          color="#BD081C"
                          size="40px"
                          description="Check out this free React Component by @stephanwozniak, made for easily sharing pages on social media sites!"
                          media={`${Config.socketUrl}event-details/${eventId}`}
                          className="socialMediaIcons">
                          <FaPinterest fontSize="inherit" size="20px" />
                        </PinterestShareButton>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton style={{ background: '#25D366' }}>
                        <WhatsappShareButton
                          url={`${Config.socketUrl}event-details/${eventId}`}
                          className="socialMediaIcons">
                          <FaWhatsapp fontSize="inherit" size="20px" />
                        </WhatsappShareButton>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        style={{ background: '#0080FF' }}
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          handleCopyLink();
                        }}>
                        <div className="copy_link_button">
                          <PiCopy fontSize="inherit" size="20px" />
                        </div>
                      </IconButton>
                    </div>
                  </div>
                  {/* event save and add button */}
                  <div className="event-save-add-btn">
                    <Button
                      fullWidth
                      className="btn-primary event-save-btn"
                      onClick={() => handleFollowUnfollow(eventOutfit?.isFollow)}
                      disabled={isSaveBookmark ? (props?.whitelabel === true ? true : false) : false}>
                      <IoBookmarkSharp
                        style={{
                          fill: isSaveBookmark
                            ? eventOutfit?.secondaryColour
                              ? eventOutfit?.secondaryColour
                              : '#0080FF'
                            : '#1d1d1d',

                          borderColor: isSaveBookmark
                            ? eventOutfit?.primaryColour
                              ? eventOutfit?.primaryColour
                              : ''
                            : '',
                        }}
                      />
                      <span>{OKTION_VARIABLE?.OKTION_SAVE_EVENT}</span>
                    </Button>

                    <Button
                      fullWidth
                      className="b-btn-primary"
                      onClick={() => {
                        handleAddToCalendarBtn();
                      }}>
                      {OKTION_VARIABLE?.OKTION_ADD_TO_CALENDER}
                    </Button>
                  </div>
                </div>

                {/* Organisation details */}
                <div className="event-org-details-container">
                  <div className="event-org-logo-name">
                    {/* event org. image */}
                    <div className="event-org-image">
                      {eventOutfit?.organisation?.imagePath && eventOutfit?.organisation?.imagePath !== null ? (
                        <img
                          className="imgWidth"
                          src={
                            props?.previewPropsValue?.hexCode?.hasOwnProperty('Logo_name') === true &&
                            props?.previewPropsValue?.hexCode['Logo_name'] !== ''
                              ? props?.previewPropsValue?.hexCode['Logo_name']
                              : eventOutfit?.companyLogo !== null
                                ? eventOutfit?.companyLogo
                                : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                          }
                          alt={eventOutfit?.organisation?.name}
                          onError={e => ((e.target.src = images?.logo), (e.target.className = 'imgWidth error-bg-img'))}
                        />
                      ) : (
                        <div className="square"></div>
                      )}
                    </div>
                    {/* event org. name */}
                    <h5 className="event-org-name ml18">
                      {eventOutfit ? `${OKTION_VARIABLE?.OKTION_BY} ${eventOutfit?.organisation?.name}` : ''}
                    </h5>
                  </div>
                  {/* event org. social icon */}
                  <div className="event-org-social-icon-container">
                    {socialData?.length > 0 && (
                      <ul className="social-icon-wrap">
                        {socialData?.map((item, i) => {
                          return (
                            <li key={i}>
                              <a href={item?.linkUrl} target="_blank">
                                {getSocialIcon(item?.linkType)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {/* event org. description */}
                  <div className="event-org-description mb18">
                    {eventOutfit?.organisation?.aboutTheOrganisation &&
                      parse(
                        anchorme({
                          input: (eventOutfit?.organisation?.aboutTheOrganisation).toString().replace(/\n/g, '<br/>'),
                          options: {
                            attributes: {
                              target: '_blank',
                              class: 'detected',
                            },
                          },
                        }),
                      )}
                  </div>

                  <Button
                    fullWidth
                    className="btn-primary"
                    onClick={handleContactBtn}
                    disabled={props.whitelabel === true ? true : false}
                    style={{
                      backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                      borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                      color: eventOutfit?.primaryTextColour ? eventOutfit?.primaryTextColour : '',
                    }}>
                    {OKTION_VARIABLE?.OKTION_CONTACT}
                  </Button>
                </div>
              </div>
            </div>
            <hr className="event-details-bottom-divider" />
          </>
        ) : (
          <Row>
            <Col sm="12">
              {isNoOrgToggle && modalErrorText === '' && (
                <div className="bg-white no-private-event-access">
                  <p className="no-private-event-title mb18">
                    {props?.isLoggedIn ? 'Access denied' : 'Login required'}
                  </p>
                  <p className="no-private-event-sub-title">
                    {props?.isLoggedIn
                      ? 'You don’t have permission to view this page. Please confirm with the event organiser that the email associated with your account matches the one they used to send the invitation.'
                      : 'You need to be logged in to view this page. Please log in to check if you have access.'}
                  </p>
                  {!props?.isLoggedIn && (
                    <Button onClick={() => navigate('/login')} className="btn-primary no-private-event-btn">
                      Login
                    </Button>
                  )}
                </div>
              )}
            </Col>
          </Row>
        )}
        {/* similer event */}
        {!loading && eventOutfit !== undefined && props.whitelabel !== true ? (
          <div className="similar-event-container">
            <h4 className="similar-event-title">{OKTION_VARIABLE?.OKTION_OTHER_EVENT_LIKE}</h4>
            <Row className="justify-content-center">
              {eventOutfit?.similarEventFeatureDetails &&
                eventOutfit?.similarEventFeatureDetails?.map((item, index) => (
                  <Col
                    key={index}
                    lg="3"
                    md="4"
                    sm="12"
                    className="event-wrapper"
                    onClick={() => handleItemClick(item)}>
                    <EventCardComponent
                      key={index}
                      image={item?.eventImages}
                      types={item?.types}
                      eventDetail={item}
                      currency={eventOutfit?.currency?.abbreviation}
                      isManaged
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>
      <NoOrgModal
        isFromPublic={true}
        isToggle={isNoOrgToggle && modalErrorText !== ''}
        handleClick={() => navigate('/')}
        modalErrorText={modalErrorText}
      />{' '}
      <LogoutModal isToggle={isloginToggle} toggle={handleLoginModal} handleClick={handleModalLoginBtn} />
      <Modal
        size="xl"
        className="modal-dialog qr-modal"
        isOpen={qrModal}
        style={{ maxWidth: '406px', margin: '0 auto' }}
        centered>
        <Closed
          className="model-close"
          onClick={() => {
            setQrModal(!qrModal);
          }}
        />
        <div className="text-center pb-3 mb-1 pt-3 mt-1">
          <h4> {OKTION_VARIABLE?.OKTION_SCANE_QR}</h4>
        </div>
        <ModalBody className="text-center">
          <QRCode
            id="QRCode"
            size={width > 599 ? 366 : width > 375 ? 314 : 220}
            value={`${Config.socketUrl}event-details/${params?.id}`}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(EventDetails);
