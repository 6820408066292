import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import anchorme from 'anchorme';
import axiosInstance from '../../../../../main/axios';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import TabWrapper from './TabWrapper';
import PageLoader from '../../../../../library/common/components/PageLoader';
import LogoutModal from './../../../../EventDetails/Frames/Raffle/LogoutModal';
import { setApiMessage } from '../../../../../library/common/constants/function';
import { SocketContext } from 'main/context/socket';
import { images } from 'library/common/constants/ImageConstants';
import '../../styles.scss';
import '../../../../EventDetails/styles.scss';

const OrganiserProfile = props => {
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  var orgId = params?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location?.state?.eventId;

  const [organizationStatus, setOrganizationStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const [organizationData, setOrganizationData] = useState([]);

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  const handleLogoutToggle = () => {
    setToggle(!isToggle);
  };

  // handle redirect to login screen
  const handleLoginRedirect = () => {
    navigate('/login', {
      redirectTo: `organiser-profile`,
      eventId: eventId,
      orgId: orgId,
    });
  };
  // handle organisation follow status
  const fetchOrganizationDetails = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.organizationDetail(orgId));
      if (status === 200 || status === 304 || status === 201) {
        setOrganizationStatus(data?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFollowUnFollow = async () => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    }
    if (props?.isLoggedIn) {
      setLoading(true);
      if (organizationStatus?.isFollow) {
        try {
          const { status } = await axiosInstance.post(URLS.unfollowOrganization(orgId, { id: orgId }));
          if (status === 200 || status === 304 || status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWINFLIST);
            fetchOrganizationDetails();
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      } else {
        try {
          const { status } = await axiosInstance.post(URLS.followOrganization(orgId, { id: orgId }));
          if (status === 200 || status === 304 || status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_FOLLOWING_LIST);
            fetchOrganizationDetails();
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };
  const handleContact = data => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    }
    if (props?.isLoggedIn) {
      let obj = {
        fromUser: true,
        paramsData: {
          recipientId: data?.ownerDetail?.id,
          firstName: data?.ownerDetail?.firstName,
          lastName: data?.ownerDetail?.lastName,
          userProfilePicture: data?.ownerDetail?.userProfilePicture,
          referenceId: eventId,
          chatType: 'event',
        },
      };
      navigate('/mail/' + data?.ownerDetail?.id, obj);
    }
  };

  const orgFilter = props.AllOrganizationList.filter((item, index) => item.organisation?.id === Number(orgId));
  const ISDISABLE = orgFilter.length > 0 ? true : false;

  return (
    <div className="eventDetails">
      {loading && <PageLoader />}
      <div className="container">
        <div className="organization-profile-main">
          <Row>
            <Col sm="12">
              <div className="text-center mt-3 bottomSection">
                <div className="text-center">
                  <img
                    className="imgWidth"
                    src={organizationData?.imagePath}
                    alt={OKTION_VARIABLE?.OKTION_ORG_PROFILE}
                    onError={e => {
                      e.target.src = images?.logo; // Set the default image
                      e.target.className = 'error-bg-img imgWidth'; // Add error class name
                    }}
                    onLoad={e => {
                      if (e.target.src !== window.location.origin + images?.logo) {
                        // Check if the image is not the default
                        e.target.className = 'imgWidth'; // Reset the class name
                      }
                    }}
                  />
                </div>
                <h5 className="mt-2 fw-normal text-center mb-2">
                  {organizationData?.name &&
                    parse(
                      anchorme({
                        input: (organizationData?.name).toString().replace(/\n/g, '<br/>'),
                        options: {
                          attributes: {
                            target: '_blank',
                            class: 'detected',
                          },
                        },
                      }),
                    )}
                </h5>
                <div>
                  {organizationData?.aboutTheOrganisation &&
                    parse(
                      anchorme({
                        input: (organizationData?.aboutTheOrganisation).toString().replace(/\n/g, '<br/>'),
                        options: {
                          attributes: {
                            target: '_blank',
                            class: 'detected',
                          },
                        },
                      }),
                    )}
                </div>

                <Button
                  disabled={ISDISABLE}
                  className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn btn-blue"
                  onClick={() => handleFollowUnFollow()}>
                  {organizationStatus?.isFollow ? OKTION_VARIABLE?.OKTION_UNFOLLOW : OKTION_VARIABLE?.OKTION_FOLLOW}{' '}
                </Button>
                <Button
                  disabled={ISDISABLE}
                  className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn ms-2"
                  onClick={() => handleContact(organizationData)}>
                  {OKTION_VARIABLE?.OKTION_CONTACT}
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        <TabWrapper setOrganizationData={setOrganizationData} />
        <LogoutModal isToggle={isToggle} toggle={handleLogoutToggle} handleClick={handleLoginRedirect} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, OrganizationReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    AllOrganizationList: OrganizationReducer.AllOrganizationList,
  };
};
export default connect(mapStateToProps, {})(OrganiserProfile);
