import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, FormText, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from 'library/common/constants/ImageConstants';
import { deleteBankAccount, editBankAccount } from 'library/common/actions/BankAccountsAction';
import SelectBoxWithFlag from 'library/common/components/SelectBoxWithFlag';
import PageLoader from 'library/common/components/PageLoader';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { setApiMessage } from 'library/common/constants/function';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from 'main/axios';
import Config from 'utility/Config';
import { fetchFromStorage } from 'utility';
import { SocketContext } from 'main/context/socket';
import { createDraftOrg } from 'modules/Organization/menu-items';
import '../../../UserProfile/Frame/CardDetails/styles.scss';
import './styles.scss';

const BankingDetailsItem = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [inputValues, setInputValues] = useState({
    routingNumber: props?.item?.routingNumber,
    accountNumber: props?.item?.accountNumber,
    beneficiaryname: props?.item?.beneficiaryName,
    beneficiaryaddress: props?.item?.beneficiaryAddress,
    bankname: props?.item?.bankName,
  });
  const [Errors, setErrors] = useState({});
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  var defaultValue = {
    routingnumber: inputValues?.routingNumber,
    accountNumber: inputValues?.accountNumber,
    beneficiaryname: props?.item?.beneficiaryName,
    beneficiaryaddress: props?.item?.beneficiaryAddress,
    bankname: props?.item?.bankName,
  };
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: defaultValue });

  useEffect(() => {
    let allCurrencies = getCurrencyOptions();
    // let tempSelectedCurrency = allCurrencies?.filter(currency => currency?.id === props?.item?.currencyId);

    const tempSelectedCurrency = allCurrencies?.find(currency => currency?.id === props?.item?.currencyId);

    if (tempSelectedCurrency) {
      setSelectedCurrency(prev => {
        // Check if the selected currency has changed
        if (prev?.id !== tempSelectedCurrency?.id) {
          return {
            ...tempSelectedCurrency,
            countryCode: tempSelectedCurrency?.country?.countryCode,
          };
        }
        return prev; // No update needed
      });
    }

    // setSelectedCurrency({ ...tempSelectedCurrency[0], countryCode: tempSelectedCurrency[0]?.country?.countryCode });
  }, [props?.item, props.currency]);

  // handle on change country
  const onSelectCountry = selectedCountry => {
    // Clear specific error fields
    setErrors(prevErrors => ({
      ...prevErrors,
      message: '',
      currency: '',
    }));

    // Safely update selected currency if it has changed
    setSelectedCurrency(prev => {
      if (prev?.id !== selectedCountry?.id) {
        return {
          ...selectedCountry,
          countryCode: selectedCountry?.country?.countryCode || '', // Fallback to empty string
        };
      }
      return prev; // No update needed
    });

    setIsEdit(true);
  };

  // handle delete bank account
  const handleDelete = async id => {
    try {
      setLoading(true);
      await props.deleteBankAccount(id);
      setApiMessage('success', OKTION_VARIABLE?.OKTION_BANK_DETAILS_DELETE);
      props.getBankAccountApi();

      if (props.toggleEvent) {
        setTimeout(() => {
          props.toggleModal();
        }, 4000);
      }
    } catch {
      setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
      setLoading(false);
    } finally {
      // Ensure loading is reset
      setLoading(false);
    }
  };

  // handle input on change
  const handleInputChange = (inputField, inputValue) => {
    if (!props?.CreateNew) {
      setIsEdit(true);
    }
    setInputValues({
      ...inputValues,
      [inputField]: inputValue,
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      message: '',
      [inputField]: '',
    }));
  };

  // handle edit bank account
  const handleEditBankAccount = async () => {
    const bankAccountId = props?.item?.id;
    // Initiate the Stripe token creation process for the given bank account ID
    createStripeToken(bankAccountId);
  };

  const getCurrencyOptions = () => {
    if (props?.currency?.length > 0) {
      return props.currency.map(currency => ({
        value: currency?.abbreviation,
        label: currency?.abbreviation,
        flag: currency?.country?.flag,
        ...currency,
      }));
    }

    // Return an empty array if no currencies are available
    return [];
  };
  const createStripeToken = async bankItemId => {
    setLoader(true);
    let addNewParams = { ...inputValues, country: selectedCurrency?.countryCode, currency: selectedCurrency?.value };

    var stripe = window.Stripe(Config?.stripeAPIKEY);

    var bankAccountParams = {
      country: addNewParams?.country,
      currency: addNewParams?.currency,
      account_number: addNewParams?.accountNumber, //'000123456789
      routing_number: addNewParams?.routingNumber, //'110000000'
      account_holder_type: 'company',
    };

    stripe.createToken('bank_account', bankAccountParams).then(async response => {
      if (response?.error) {
        setLoader(false);
        if (response?.error?.code === 'routing_number_invalid') {
          setErrors(Errors => {
            return { ...Errors, routingNumber: response?.error?.message };
          });
        } else if (response?.error?.code === 'account_number_invalid') {
          setErrors(Errors => {
            return { ...Errors, accountNumber: response?.error?.message };
          });
        } else {
          setErrors(Errors => {
            return { ...Errors, message: response?.error?.message };
          });
        }
      } else {
        setLoader(false);
        let resData = response?.token;
        const org_id = fetchFromStorage(identifiers.currentOrganization);

        let addNewParams = {
          stripeId: resData?.bank_account?.id,
          accountHolderType: resData?.bank_account?.account_holder_type,
          routingNumber: inputValues?.routingNumber,
          accountNumber: inputValues?.accountNumber,
          bankName: inputValues?.bankname,
          currencyId: selectedCurrency?.id,
          bankToken: resData?.id,
          last4: resData?.bank_account?.last4,
          id: bankItemId,
          beneficiaryName: watch('beneficiaryname'),
          beneficiaryAddress: watch('beneficiaryaddress'),
        };
        if (props?.CreateNew === true) {
          setLoader(true);
          try {
            const { status } = await axiosInstance.post(URLS.orgBankAccounts(org_id), addNewParams);
            if (status === 201) {
              setLoader(false);
              setApiMessage('success', OKTION_VARIABLE?.OKTION_BANKDETAILS_ADDED);
              setTimeout(() => {
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              props.ToggleForm();
              props.getBankAccountApi();
              if (isNewOrg) {
                setTimeout(() => {
                  navigate(`/organization/${org_id}?banking-details`);
                }, 1000);
              }
            }
          } catch (err) {
            setLoader(false);
            if (err?.response?.data?.msg === 'ERR_BANK_ACCOUNT_ALREADY_EXIST') {
              setApiMessage('error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              setTimeout(() => {
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
            }
          }
        } else {
          setLoader(false);
          try {
            const { status } = await axiosInstance.put(URLS.orgBankAccounts(bankItemId), addNewParams);
            if (status === 201) {
              setLoader(false);
              setApiMessage('success', OKTION_VARIABLE?.OKTION_BANK_DETAILS_UPDATE);
              setTimeout(() => {
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              setIsEdit(false);
              props.getBankAccountApi();
              props.ToggleForm();
            }
          } catch (err) {
            setLoader(false);
            if (err?.response?.data?.msg === 'ERR_BANK_ACCOUNT_ALREADY_EXIST') {
              setApiMessage('error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              setTimeout(() => {
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              setInputValues({ inputValues });
            }
          }
        }
      }
    });
  };

  // handle create new bank token and org
  const handleAddNew = async () => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        createStripeToken('new');
      }
    } else {
      createStripeToken('new');
    }
  };

  // handle validate and submit bank details
  const handleSubmitBankDetails = () => {
    setErrors({});
    let values = { ...inputValues, currency: selectedCurrency?.value };

    let flag = true;
    if (!values?.accountNumber || values?.accountNumber === '') {
      flag = false;
      Errors.accountNumber = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.accountNumber = '';
    }

    if (!values?.routingNumber || values?.routingNumber === '') {
      flag = false;
      Errors.routingNumber = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.routingNumber = '';
    }

    if (typeof values?.currency === 'undefined' || values?.currency === '') {
      flag = false;
      Errors.currency = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.currency = '';
    }

    if (typeof values?.beneficiaryname === 'undefined' || values?.beneficiaryname === '') {
      flag = false;
      Errors.beneficiaryname = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.beneficiaryname = '';
    }

    if (typeof values?.beneficiaryaddress === 'undefined' || values?.beneficiaryaddress === '') {
      flag = false;
      Errors.beneficiaryaddress = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.beneficiaryaddress = '';
    }

    if (typeof values?.bankname === 'undefined' || values?.bankname === '') {
      flag = false;
      Errors.bankname = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.bankname = '';
    }

    setErrors({ ...Errors, Errors });
    if (flag && props?.CreateNew === true) {
      handleAddNew();
    } else if (flag) {
      handleEditBankAccount();
    }
    return flag;
  };

  return (
    <div className="cardInfo">
      {(loading || loader) && <PageLoader />}

      <FormGroup>
        <SelectBoxWithFlag
          {...props}
          selectOptions={[...getCurrencyOptions()]}
          selectedValue={selectedCurrency}
          onChange={onSelectCountry}
          disabled={props.is_view === true ? true : true}
          className="selectBorder currencySelect"
          placeholder={OKTION_VARIABLE?.OKTION_SELECT_CURRENCY}
        />
        {Errors?.currency !== '' && (
          <FormText className="text-danger" color="error">
            {Errors?.currency}
          </FormText>
        )}
      </FormGroup>
      <div className="row mt-2">
        <div className="col-sm-12">
          <FormGroup
            className={
              watch('beneficiaryname') !== '' && watch('beneficiaryname') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="beneficiaryname"
              {...register('beneficiaryname', {
                onChange: e => {
                  handleInputChange('beneficiaryname', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BENEFICIARY_FULLNAME}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BENEFICIARY_FULLNAME}</Label>
            {Errors?.beneficiaryname !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.beneficiaryname}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('beneficiaryaddress') !== '' && watch('beneficiaryaddress') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="beneficiaryaddress"
              {...register('beneficiaryaddress', {
                onChange: e => {
                  handleInputChange('beneficiaryaddress', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BENEFICIARY_ADDRESS}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BENEFICIARY_ADDRESS}</Label>
            {Errors?.beneficiaryaddress !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.beneficiaryaddress}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('accountNumber') !== '' && watch('accountNumber') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="accountNumber"
              {...register('accountNumber', {
                onChange: e => {
                  handleInputChange('accountNumber', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_ACCOUNT_NUMBER}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ACCOUNT_NUMBER}</Label>
            {Errors?.accountNumber !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.accountNumber}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('routingnumber') !== '' && watch('routingnumber') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="routingnumber"
              {...register('routingnumber', {
                onChange: e => {
                  handleInputChange('routingNumber', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_ROUTING_NUMBER}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ROUTING_NUMBER}</Label>
            {Errors?.routingNumber !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.routingNumber}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('bankname') !== '' && watch('bankname') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="bankname"
              {...register('bankname', {
                onChange: e => {
                  handleInputChange('bankname', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BANK_NAME}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BANK_NAME}</Label>
            {Errors?.bankname !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.bankname}
              </FormText>
            )}
          </FormGroup>
        </div>

        {Errors?.message !== '' && (
          <div className="col-sm-12">
            <FormGroup className="focus_field mb-1">
              <small className="text-danger">{Errors?.message}</small>
            </FormGroup>
          </div>
        )}
        <Col sm="12" className="ps-1 pe-1 pt-1">
          <div className="d-flex justify-content-end border-top pt-2 pe-2">
            {props?.CreateNew === true ? (
              <img onClick={props.ToggleForm} className="me-2 cursor-pointer" src={images.deleteWithCircle} alt="" />
            ) : (
              <img
                onClick={() => handleDelete(props?.item.id)}
                className={(isEdit ? 'me-2 ' : '') + ' cursor-pointer'}
                src={images.deleteWithCircle}
                alt=""
              />
            )}
            {(isEdit || props?.CreateNew === true) && (
              <img
                onClick={handleSubmitBankDetails}
                className="cursor-pointer"
                src={images.circleWithYellowTick}
                alt=""
              />
            )}
          </div>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = ({ BankAccountsReducer }) => {
  return {
    bankAccountsList: BankAccountsReducer.bankAccounts,
  };
};

export default connect(mapStateToProps, { deleteBankAccount, editBankAccount })(BankingDetailsItem);
