import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, CardImg } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import Countdown from 'react-countdown';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from '../../constants/IdentifierConstants';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import PageLoader from 'library/common/components/PageLoader';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from 'library/common/constants/function';
import { CurrencyFormat, saveToStorage } from 'utility';
import './styles.scss';

dayjs.extend(relativeTime);

const EventCard = ({
  // key,
  // types,
  // image,
  event,
  isHorizontal,
  isManaged,
  isContributions,
  isFollow,
  handleEventList,
  isFromSearch,
  // setActionMessage,
}) => {
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [loading, setLoader] = useState(false);
  const handleManage = event => {
    var redirectUrl =
      event?.featureType[0] === 'auction'
        ? 'auctionquick'
        : event?.featureType[0] === 'donation'
          ? 'donationquick'
          : event?.featureType[0] === 'raffle'
            ? 'rafflequick'
            : 'dashboard';
    if (event?.eventType === 'quick') {
      navigate(`/my-event/${event?.id}?event=${redirectUrl}`);
    } else {
      navigate(`/my-event/${event?.id}?event=details`);
    }
  };
  const handleLocation = () => {
    const location = event?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };
  const handleEventLocation = () => {
    const location = event?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };

  const handleStatus = () => {
    const status = event?.status;
    const today = moment().format('YYYY-MM-DD'); // Get today's date in 'YYYY-MM-DD' format
    const currentTime = moment().format('HHmm'); // Get current time in 'HHmm' format
    const eventEndDate = moment(event?.endDate).format('YYYY-MM-DD'); // Get event's end date
    const eventEndTime = moment(event?.endDate).format('HHmm'); // Get event's end time

    // Check if the event is suspended
    if (status === 'suspended') {
      return 'Suspended';
    }

    // If the event is saved, check its end date and time
    if (status === 'saved') {
      // If today's date is after the event end date, the event is closed
      // Otherwise, the event is still in draft
      return 'Draft';
    }

    // For other statuses, check the event's end date and time
    if (moment(today).isAfter(eventEndDate)) {
      return 'Closed'; // Event is closed if today's date is after the end date
    }

    // If the event ends today, check if the current time is past the event's end time
    if (today === eventEndDate && eventEndTime < currentTime) {
      return 'Closed'; // Event is closed if the current time is past the end time
    }

    return 'Active'; // If none of the above conditions are met, the event is active
  };

  const handleEventClick = () => {
    navigate(`/event-details/${event?.id}`);
  };
  const handleMyContribution = event => {
    navigate(`/my-event-page/contribution/${event?.id}`, { state: { event } });
  };
  const handleUnfollow = async event => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.eventUnFollow(event?.id));
      handleEventList();
      setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
    } catch {}
    setLoader(false);
  };
  const renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <span>
        {days > 0 ? days : 0} {days === 0 || days === 1 ? ' day' : ' days'} {hours > 0 ? hours : 0}{' '}
        {hours === 0 || hours === 1 ? ' hour' : ' hours'} {minutes > 0 ? minutes : 0}{' '}
        {minutes === 0 || minutes === 1 ? ' minute' : ' minutes'} left
      </span>
    );
  };
  var today = moment();
  var endDate = moment(event?.endDate && event?.endDate);
  let totalDays = 0;
  let totalHours = 0;
  let clearHours = 0;
  let totalMinutes = 0;
  let clearMinutes = 0;
  totalDays = endDate.diff(today, 'days');
  totalHours = endDate.diff(today, 'hours');
  clearHours = totalHours % 24;
  totalMinutes = endDate.diff(today, 'minutes');
  clearMinutes = totalMinutes % 60;

  if (isHorizontal) {
    return (
      <>
        {loading === true && <PageLoader />}
        <Card className={`shadow-sm event-card ${isHorizontal && 'isHorizontal'}`}>
          <CardBody>
            <div className="row">
              <div
                className={
                  isFollow === true
                    ? 'col-xl-3 col-md-3 cursor-pointer position-relative'
                    : 'col-xl-3 col-md-3 position-relative'
                }
                onClick={() => {
                  if (isFollow) {
                    handleEventClick();
                  }
                }}>
                {event?.isPaidEventPlace ? <img src={images.badge} className="card-badge" alt="" /> : ''}

                <CardImg
                  top
                  width="100%"
                  src={identifiers.imageUrlConstant + '' + event?.eventImages?.[0]?.name}
                  alt={event?.eventName}
                  onError={e => {
                    e.target.src = images?.logo; // Set the default image
                    e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
                  }}
                  onLoad={e => {
                    if (e.target.src !== window.location.origin + images?.logo) {
                      // Check if the image is not the default
                      e.target.className = 'card-img-top'; // Reset the class name
                    }
                  }}
                />
              </div>
              <div
                className={isFollow === true ? 'col-xl-7 col-md-7 cursor-pointer' : 'col-xl-6 col-md-6'}
                onClick={() => {
                  if (isFollow) {
                    handleEventClick();
                  }
                }}>
                <div className="card_content">
                  <span className="event-type">{handleEventLocation()}</span>
                  <p className="event-date mt-0">
                    {event?.endDate && dayjs(event?.endDate).format('DD MMM YYYY')}{' '}
                    <span className="event-time">{event?.endDate && dayjs(event?.endDate).format('hh:mm A')}</span>
                  </p>{' '}
                  {isFollow === true ? (
                    <button className="eventname-button" onClick={handleEventClick}>
                      {event?.eventName &&
                        parse(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </button>
                  ) : isContributions !== true ? (
                    <p className="event-name">
                      {event?.eventName &&
                        parse(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </p>
                  ) : (
                    <button className="eventname-button" onClick={handleEventClick}>
                      {event?.eventName &&
                        parse(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </button>
                  )}
                  {event?.endDate ? (
                    handleStatus() === 'Closed' ? (
                      <p className="time-left mb-1 red-color">{OKTION_VARIABLE?.OKTION_EVENT_HAS_FINISHED}</p>
                    ) : (
                      <p className="time-left mb-1">
                        <Countdown date={event?.endDate} renderer={renderTime} key={event?.endDate} />
                      </p>
                    )
                  ) : null}
                  {event?.raisedAmountStatus === 1 && (
                    <p className="amount-raised">
                      <span className="fw-500">
                        <CurrencyFormat
                          value={event?.amountRaised || '0.00'}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${event?.currency !== null ? event?.currency?.abbreviation : ''} ${
                            event?.currency !== null ? event?.currency?.symbol : ''
                          }`}
                          thousandSpacing={
                            event?.currency !== null ? (event?.currency?.symbol === '₹' ? '2s' : '3') : ''
                          }
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />
                      </span>{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </p>
                  )}
                </div>
              </div>
              <div className={isFollow === true ? 'col-xl-2 col-md-3' : 'col-xl-3 col-md-3 img-section'}>
                <div className="card_content">
                  <p
                    className={
                      handleStatus() === 'Active'
                        ? `active_link_color ${handleStatus()}`
                        : handleStatus() === 'Draft'
                          ? `draft_link_color ${handleStatus()}`
                          : handleStatus() === 'Closed'
                            ? `close_link_color ${handleStatus()}`
                            : handleStatus() === 'Suspended'
                              ? `suspended_link_color ${handleStatus()}`
                              : ''
                    }>
                    {handleStatus()}
                  </p>
                  {isFollow !== true && (
                    <p className="public_text">
                      {event.isPublic === 1
                        ? isFromSearch
                          ? ''
                          : 'Public'
                        : event.isPublic === 2
                          ? isFromSearch
                            ? ''
                            : 'Unlisted'
                          : isFromSearch
                            ? ''
                            : 'Private'}
                    </p>
                  )}
                  <div className="extra-info">
                    {event?.auction && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_AUCTION} arrow>
                        <img src={images.auction} alt="auction" />
                      </Tooltip>
                    )}
                    {event?.donation && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_DONATION} arrow>
                        <img src={images.donation} alt="donation" />
                      </Tooltip>
                    )}
                    {event?.raffle && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_RAFFLE} arrow>
                        <img src={images.raffle} alt="auction" />
                      </Tooltip>
                    )}
                    {event?.ticket && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_TICKET} arrow>
                        <img src={images.tickets} alt="tickets" />
                      </Tooltip>
                    )}
                  </div>
                  {isManaged && (
                    <Button
                      tyle={{ maxWidth: '100%' }}
                      onClick={() => {
                        saveToStorage(identifiers.eventDetails, event);
                        setTimeout(() => {
                          handleManage(event);
                        }, 300);
                      }}
                      className="main-button btn-block mt-3 h-45 w-100 fs-20">
                      {OKTION_VARIABLE?.OKTION_FEATURE_MANAGE}
                    </Button>
                  )}
                  {isContributions && (
                    <Button
                      onClick={() => handleMyContribution(event)}
                      className="main-button btn-block mt- 2 my-contribution-button">
                      {OKTION_VARIABLE?.OKTION_MY_CONTRIBUTION}
                    </Button>
                  )}
                  {isFollow === true && (
                    <Button onClick={() => handleUnfollow(event)} className="main-button btn-block mt-2 fw-500">
                      {OKTION_VARIABLE?.OKTION_UNFOLLOW}
                    </Button>
                  )}
                </div>
              </div>
              {isContributions && (
                <Button
                  onClick={() => handleMyContribution(event)}
                  className="main-button btn-block mt- 2 my-contribution-button-smallscreen">
                  {OKTION_VARIABLE?.OKTION_MY_CONTRIBUTION}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
  var currentDate = new Date();
  var isPastDate = moment(currentDate).isAfter(new Date(moment(event?.endDate)));

  return (
    <>
      <Card className="shadow-sm event-card responsive_card" onClick={handleEventClick}>
        <div className="card_image">
          {event?.isPaidEventPlace ? <img src={images.badge} className="card-badge" alt="" /> : ''}
          {/* {event?.eventImages ? ( */}
          <CardImg
            top
            width="100%"
            src={identifiers.imageUrlConstant + '' + event?.eventImages?.[0]?.name}
            alt={event?.eventName}
            onError={e => {
              e.target.src = images?.logo; // Set the default image
              e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
            }}
            onLoad={e => {
              if (e.target.src !== window.location.origin + images?.logo) {
                // Check if the image is not the default
                e.target.className = 'card-img-top'; // Reset the class name
              }
            }}
          />
          {/* ) : (
            <div className="noImage"></div>
          )} */}
        </div>
        <div className="card_content">
          <CardBody>
            <span className="event-type">{handleLocation()}</span>
            <p className="event-date">
              {event.endDate && dayjs(event.endDate).format('DD MMM YYYY')}{' '}
              <span className="event-time">{event.endDate && dayjs(event.endDate).format('hh:mm A')}</span>
            </p>
            <p className="event-name">
              {event?.eventName !== null &&
                parse(
                  anchorme({
                    input: event?.eventName && event?.eventName?.toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </p>
            {event.startDate && (
              <>
                {!isPastDate ? (
                  <p className="time-left">
                    <Countdown date={event?.endDate} renderer={renderTime} key={event?.endDate} />
                  </p>
                ) : (
                  <p className="text-danger mb-2">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE_MESSAGE}</p>
                )}
              </>
            )}
            {event?.raisedAmountStatus === 1 && (
              <p className="amount-raised">
                <span className="fw-500">
                  <CurrencyFormat
                    value={event?.amountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${event?.currency !== null ? event?.currency?.abbreviation : ''} ${
                      event?.currency !== null ? event?.currency?.symbol : ''
                    }`}
                    thousandSpacing={event?.currency !== null ? (event?.currency?.symbol === '₹' ? '2s' : '3') : ''}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </span>{' '}
                {OKTION_VARIABLE?.OKTION_RAISED}
              </p>
            )}
            <div className="extra-info">
              {event?.auction && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_AUCTION} arrow>
                  <img src={images.auction} alt="auction" />
                </Tooltip>
              )}
              {event?.donation && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_DONATION} arrow>
                  <img src={images.donation} alt="donation" />
                </Tooltip>
              )}
              {event?.raffle && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_RAFFLE} arrow>
                  <img src={images.raffle} alt="raffle" />
                </Tooltip>
              )}
              {event?.ticket && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_TICKET} arrow>
                  <img src={images.tickets} alt="tickets" />
                </Tooltip>
              )}
            </div>
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default EventCard;
