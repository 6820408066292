import React, { useContext, useState } from 'react';
import { Button } from '@mui/material';
import { CurrencyFormat, fetchFromStorage } from 'utility';
import { ReactComponent as PluseIcon } from '../../../../resources/images/pluse_icon.svg';
import { ReactComponent as MinusIcon } from '../../../../resources/images/minus_icon.svg';
import { CustomTextField } from 'library/common/components/textfield';
import { SocketContext } from 'main/context/socket';
import { useNavigate, useParams } from 'react-router-dom';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { setApiMessage } from 'library/common/constants/function';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from 'main/axios';
import PageLoader from 'library/common/components/PageLoader';

const RaffleAddCard = ({
  raffleCardData,
  raffleFeatureData,
  eventDetails,
  whitelabel,
  setClickItemData,
  handleBtnClick,
  islogin,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);

  var eventId = params?.id;
  const userDetail = fetchFromStorage(identifiers?.userDetail);

  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;

  // Create the object representing the current ticket in the cart
  var ticketCartData = {
    type: 'ticket',
    quantity: count,
    referenceId: null,
    subscriptionName: null,
    subscriptionPrice: null,
    name: raffleCardData?.ticketBundleName,
    amount: raffleCardData?.bundlePrize,
    purchaseReferenceId: raffleCardData?.id,
    isRaffleTicket: true,
    donationAmount: null,
    eventId: eventDetails?.id,
    currency: eventDetails?.currency?.abbreviation,
    symbol: eventDetails?.currency?.symbol,
    event: eventDetails,
    eventName: eventDetails?.eventName,
    digiCommission: 0,
    ticketPrice: raffleCardData?.bundlePrize,
    cardCharedAmount: 0,
    total: raffleCardData?.bundlePrize * count,
  };

  // Handle ticket addition to cart for logged-in users
  const handleAddToCart = async () => {
    if (islogin && userDetail?.userType === 'normal') {
      setLoading(true);
      const senData = [
        {
          type: 'ticket',
          quantity: count,
          eventId: eventDetails?.id,
          referenceId: null,
          purchaseReferenceId: raffleCardData?.id,
          isRaffleTicket: true,
        },
      ];
      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else {
      handleGuestCheckout();
    }
  };

  // Handle guest user or invalid feature scenarios
  const handleGuestCheckout = async () => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    const senData = {
      type: 'ticket',
      quantity: count,
      eventId: eventDetails?.id,
      referenceId: null,
      purchaseReferenceId: raffleCardData?.id,
      isRaffleTicket: true,
    };

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: [senData],
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };
    setLoading(true);
    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setClickItemData(ticketCartData);
        handleBtnClick(ticketCartData);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  // Handle incrementing the ticket count
  const handleAddTicketCount = () => {
    setCount(count + 1);
  };

  // Handle decrementing the ticket count
  const handleMinusTicketCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  // Handle input change for the ticket count input field
  const handleInputChange = event => {
    let inputValue = event.target.value;
    inputValue = inputValue === '' ? 1 : inputValue.replace(/^0/, '1');
    setCount(Number(inputValue));
  };

  // Select the input field text when focused
  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <div className="raffle-tickets-amount-card">
      {loading && <PageLoader />}
      <h4 className="raffle-tickets-amount-title">{raffleCardData?.ticketBundleName}</h4>
      <div className="raffle-tickets-amount-value">
        <CurrencyFormat
          value={count * raffleCardData?.bundlePrize}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${eventDetails?.currency != null ? currency : ''} ${eventDetails?.currency != null ? symbol : ''}`}
          thousandSpacing={symbol === '₹' ? '2s' : '3'}
          decimalScale="2"
          fixedDecimalScale={true}
        />
      </div>
      <h5 className="raffle-tickets-quantity">
        {raffleCardData?.ticketQuantity}{' '}
        {raffleCardData?.ticketQuantity === 1 ? OKTION_VARIABLE?.OKTION_TICKET : OKTION_VARIABLE?.OKTION_TICKET_TITLE}
      </h5>
      <div className="raffle-tickets-card-amount-update-btn">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className={`btn-primary raffle-tickets-update-count-btn ${raffleFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={handleMinusTicketCount}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              pointerEvents: whitelabel === true && 'none',
              opacity: whitelabel === true && '0.5',
            }}>
            <MinusIcon />
          </Button>
          <div className="raffle-tickets-count-input-field">
            <CustomTextField
              fullWidth
              className="custom-textfield raffle-tickets-count-field"
              value={count}
              type="number"
              pattern="[0-9]*"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <Button
            className={`btn-primary raffle-tickets-update-count-btn ${raffleFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={handleAddTicketCount}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              pointerEvents: whitelabel === true && 'none',
              opacity: whitelabel === true && '0.5',
            }}>
            <PluseIcon />
          </Button>
        </div>
        <div className="">
          <Button
            fullWidth
            className={`btn-primary raffle-tickets-add-to-cart-btn ${raffleFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            disabled={
              whitelabel === true || raffleFeatureData[0]?.isDraw === '1' || raffleCardData?.totalCartItems > 0
                ? true
                : false || count === 0
            }
            onClick={() => handleAddToCart()}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
            }}>
            {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RaffleAddCard;
