import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from 'library/common/constants/ImageConstants';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { SocketContext } from 'main/context/socket';

const RelistingModal = ({ isToggle, toggle, itemData }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const handleContinue = async () => {
    try {
      const { status } = await axiosInstance.put(URLS.relistSupplyItem(itemData?.id));
      if (status === 201) {
        toggle();
      }
    } catch (err) {}
  };

  return (
    <div className="AddPriceModal ">
      <Modal size="md" className="customModal add-price-modal" isOpen={isToggle}>
        <div className="text-end">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div className="addPriceModalForm itemDetailsSmallModal">
            <h5 className="mb-3"> {OKTION_VARIABLE?.OKTION_RELIST_INSTRUCTION}</h5>
            <div className="d-flex">
              <Button className="app-button mt-2 me-2 black-button w-100 h-60" onClick={toggle}>
                {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
              </Button>
              <Button className="app-button mt-2 me-2 main-button w-100 h-60" onClick={handleContinue}>
                {OKTION_VARIABLE?.OKTION_CONTINUE}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RelistingModal;
