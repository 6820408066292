import React from 'react';
import { Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { CurrencyFormat } from 'utility';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles.scss';
import { images } from 'library/common/constants/ImageConstants';

class AuctionWallCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 4,
    };
  }

  render() {
    const { feturesData, currencyAbbreviation, currencySymbol, loading } = this.props;
    let withOutfeaturedItem =
      feturesData &&
      feturesData?.item &&
      feturesData?.item.length > 0 &&
      feturesData?.item.filter(item => item?.featuredItem === false);
    let withfeaturedItem =
      feturesData &&
      feturesData?.item &&
      feturesData?.item.length > 0 &&
      feturesData?.item.filter(item => item?.featuredItem === true);
    var settings = {
      responsive: [
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            rows: withOutfeaturedItem && withOutfeaturedItem?.length === 2 ? 1 : 4,
            slidesToScroll: 1,
          },
        },
      ],
    };

    console.log('withfeaturedItem', withOutfeaturedItem, withfeaturedItem);

    return (
      <>
        <div className="auctionWallCard">
          {feturesData && feturesData?.item.length > 0 ? (
            <div>
              <Slider
                dots={false}
                infinite={true}
                speed={0}
                autoplay={true}
                autoplaySpeed={15000}
                swipe={false}
                touchMove={false}
                arrows={false}
                pauseOnHover={false}
                className={`${
                  withfeaturedItem && withfeaturedItem?.length > 0 && withfeaturedItem?.length !== 0 ? 'card-row' : ''
                }`}>
                {withfeaturedItem &&
                  withfeaturedItem?.length > 0 &&
                  withfeaturedItem?.map((item, i) => (
                    <div className="d-flex inner-card-block" key={i}>
                      <Col sm="4" className="p-0">
                        <div className="big-card-img">
                          <img
                            src={identifiers.imageUrlConstant + item?.images[0]?.name}
                            alt={item?.itemName || 'item image'}
                            onError={e => {
                              e.target.src = images?.logo; // Set the default image
                              e.target.className = 'error-bg-img '; // Add error class name
                            }}
                            onLoad={e => {
                              if (e.target.src !== window.location.origin + images?.logo) {
                                // Check if the image is not the default
                                e.target.className = ''; // Reset the class name
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm="8" className="p-0">
                        <div className="card-content-big">
                          <h1 className="fw-500">{item?.itemName}</h1>
                          <h4 className="fw-normal mb-4">
                            {item?.providedBy && item?.providedBy === 'sponsoredBy'
                              ? 'Sponsored by'
                              : item?.providedBy === 'donatedBy'
                                ? 'Donated by'
                                : 'Provided by'}{' '}
                            : {item?.itemProvidedByName}
                          </h4>
                          <h4 className="fw-normal">Current Bid : </h4>
                          <h1 className="mb-2">
                            <CurrencyFormat
                              value={
                                item?.isSold === true && item?.markAsPaid === true
                                  ? item?.buyItNow !== null
                                    ? item?.buyItNow
                                    : item?.currentBid !== null
                                      ? item?.currentBid
                                      : 0
                                  : item?.currentBid !== null
                                    ? item?.currentBid
                                    : 0
                              }
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={`${currencyAbbreviation}  ${currencySymbol}`}
                              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                              decimalScale="2"
                              fixedDecimalScale={true}
                              className="fs-60"
                            />{' '}
                          </h1>{' '}
                        </div>
                      </Col>
                    </div>
                  ))}{' '}
              </Slider>
            </div>
          ) : (
            !loading && <h1>No items available</h1>
          )}
        </div>
        <div className="auctionSmallWallCard">
          <Slider
            dots={false}
            infinite={true}
            speed={0}
            autoplay={true}
            autoplaySpeed={10000}
            swipe={false}
            slidesToShow={2}
            touchMove={false}
            arrows={false}
            slidesToScroll={2}
            rows={`${withOutfeaturedItem && withOutfeaturedItem?.length === 2 ? 1 : 2}`}
            pauseOnHover={false}
            {...settings}>
            {withOutfeaturedItem &&
              withOutfeaturedItem?.length > 0 &&
              withOutfeaturedItem?.map((item, i) => (
                <Col sm="12" className="wall-card" key={i}>
                  <Row className="mb-3 box me-0 ms-0">
                    <Col sm="4" className="p-0">
                      <div className="small-card-img">
                        <img
                          src={identifiers.imageUrlConstant + item.images[0].name}
                          onError={e => {
                            e.target.src = images?.logo; // Set the default image
                            e.target.className = 'error-bg-img '; // Add error class name
                          }}
                          onLoad={e => {
                            if (e.target.src !== window.location.origin + images?.logo) {
                              // Check if the image is not the default
                              e.target.className = ''; // Reset the class name
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="card-content">
                        <h4 className="fw-500">{item?.itemName}</h4>
                        <h6 className="fw-normal mb-4">
                          {item?.providedBy && item?.providedBy === 'sponsoredBy'
                            ? 'Sponsored by'
                            : item?.providedBy === 'donatedBy'
                              ? 'Donated by'
                              : 'Provided by'}{' '}
                          : {item?.itemProvidedByName}
                        </h6>
                        <h6 className="fw-normal">Current Bid : </h6>
                        <h4 className="mb-2 currency">
                          <CurrencyFormat
                            value={
                              item?.isSold === true && item?.markAsPaid === true
                                ? item?.buyItNow !== null
                                  ? item?.buyItNow
                                  : item?.currentBid !== null
                                    ? item?.currentBid
                                    : 0
                                : item?.currentBid !== null
                                  ? item?.currentBid
                                  : 0
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={`${currencyAbbreviation}  ${currencySymbol}`}
                            thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                            decimalScale="2"
                            fixedDecimalScale={true}
                            className="fs-25"
                          />
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}{' '}
          </Slider>
        </div>
      </>
    );
  }
}

export default AuctionWallCard;
