import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import anchorme from 'anchorme';

import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import LogoutModal from '../Raffle/LogoutModal';
import { SocketContext } from 'main/context/socket';

const About = props => {
  const params = useParams();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [isLoginToggle, setIsLoginToggle] = useState(false);

  // Handles navigation to login page with redirect information
  const handleButtonClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        eventId: params?.id,
      },
    });
  };

  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    `${props?.eventDetails?.venue?.city} ${props?.eventDetails?.venue?.state} ${props?.eventDetails?.venue?.country} ${props?.eventDetails?.venue?.postCode}`,
  ];
  return (
    <>
      <div className="event-about-container">
        {/* event time and venu details */}
        <div className="event-time-venu-details">
          {props?.timeZone !== undefined &&
            (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
              <EventTimeDetails
                img={images.watch}
                title={
                  (props?.eventDetails?.location === 3 ? OKTION_VARIABLE?.OKTION_ONLINE : '') +
                  ' ' +
                  OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE
                }
                startDate={
                  props?.eventDetails?.startDate
                    ? moment(props?.eventDetails?.startDate).format('hh:mm A DD MMMM, YYYY')
                    : ''
                }
                endDate={
                  props?.eventDetails?.endDate
                    ? moment(props?.eventDetails?.endDate).format('hh:mm A DD MMMM, YYYY')
                    : ''
                }
                isLocation={false}
              />
            )}
          {props?.eventDetails?.location !== 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
              isLocation
              img={images.location}
              endDate={
                props?.eventDetails?.venueEndDate
                  ? moment(props?.eventDetails?.venueEndDate).format('hh:mm A DD MMMM, YYYY')
                  : ''
              }
              startDate={
                props?.eventDetails?.venueStartDate
                  ? moment(props?.eventDetails?.venueStartDate).format('hh:mm A DD MMMM, YYYY')
                  : ''
              }
              address={props?.eventDetails?.venue ? venueAddress : []}
              secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
            />
          )}
        </div>
        <div className="event-about-details">
          <h4 className="event-about-title mb18">{OKTION_VARIABLE?.OKTION_ABOUT_EVENT}</h4>

          {props?.eventDetails?.eventAbout != null ? (
            <div className="event-about-description">
              {parse(
                anchorme({
                  input: (props?.eventDetails?.eventAbout).toString().replace(/\n/g, '<br/>'),
                  options: {
                    attributes: {
                      target: '_blank',
                      class: 'detected',
                    },
                  },
                }),
              )}
            </div>
          ) : (
            ''
          )}
        </div>

        {/* Login reqired modal */}
        <LogoutModal
          isToggle={isLoginToggle}
          toggle={() => setIsLoginToggle(prev => !prev)}
          handleClick={handleButtonClick}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(About);
