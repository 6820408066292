import React, { useState, useEffect, useContext } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { getCurrencyList, setPublicCategoryList, getCurrentCountryDetails } from 'library/common/actions/AuthActions';
import { SocketContext } from 'main/context/socket';
import AvailableTab from './Frame/AvailableTab';
import PageLoader from 'library/common/components/PageLoader';

const TabWrapper = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.getCurrentCountryDetails();
    fetchCurrency();
    fetchCategories();
  }, []);

  // get currency list
  const fetchCurrency = async () => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.getCurrency());
      if (status === 200) {
        props.getCurrencyList(data?.data?.currencies);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  // get category list
  const fetchCategories = async () => {
    try {
      setLoading(true);

      const { status, data } = await axiosInstance.get(URLS.getCategory);
      if (status === 200 || status === 201) {
        props.setPublicCategoryList(data?.data?.categories);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleToggleTabs = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="raffleTab">
      {loading && <PageLoader />}
      <Nav tabs>
        <NavItem className={`${activeTab === '1' && 'active'}`}>
          <NavLink
            onClick={() => {
              handleToggleTabs('1');
            }}>
            {OKTION_VARIABLE?.OKTION_AVAILABLE}
          </NavLink>
        </NavItem>
        <NavItem className={`${activeTab === '2' && 'active'}`}>
          <NavLink
            onClick={() => {
              handleToggleTabs('2');
            }}>
            {OKTION_VARIABLE?.OKTION_ACTIVE}
          </NavLink>
        </NavItem>
        <NavItem className={`${activeTab === '3' && 'active'}`}>
          <NavLink
            onClick={() => {
              handleToggleTabs('3');
            }}>
            {OKTION_VARIABLE?.OKTION_CLOSED}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">{activeTab === '1' && <AvailableTab tabValue="available" />}</TabPane>
        <TabPane tabId="2">{activeTab === '2' && <AvailableTab isMonitor={true} tabValue="active" />}</TabPane>
        <TabPane tabId="3">{activeTab === '3' && <AvailableTab isMonitor={true} tabValue="sold" />}</TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    currencyList: authReducer.currencyList,
    countryDetails: authReducer.countryDetails,
  };
};

export default connect(mapStateToProps, { getCurrencyList, setPublicCategoryList, getCurrentCountryDetails })(
  TabWrapper,
);
