import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { CurrencyFormat } from 'utility';
import { ReactComponent as PluseIcon } from '../../../../resources/images/pluse_icon.svg';
import { ReactComponent as MinusIcon } from '../../../../resources/images/minus_icon.svg';
import { CustomTextField } from 'library/common/components/textfield';
import { SocketContext } from 'main/context/socket';

const DonationAddCard = ({
  donationCardData,
  donationFeatureData,
  eventDetails,
  handleCheckedChange,
  handleAddToCart,
  buttonActive,
  whitelabel,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;

  // Select the input field text when focused
  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <div className="donation-amount-card">
      <h4 className="donation-amount-title">{donationCardData?.name}</h4>
      <div className="donation-amount-value">
        <CurrencyFormat
          value={donationCardData?.count * donationCardData?.amount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${eventDetails?.currency != null ? currency : ''} ${eventDetails?.currency != null ? symbol : ''}`}
          thousandSpacing={symbol === '₹' ? '2s' : '3'}
          decimalScale="2"
          fixedDecimalScale={true}
        />
      </div>

      <div className="donation-card-amount-update-btn">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className={`btn-primary donation-update-count-btn ${donationFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={() => {
              if (donationCardData?.count > 1)
                handleCheckedChange(3, true, donationCardData?.count, donationCardData?.id);
            }}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
            }}>
            <MinusIcon />
          </Button>
          <div className="donation-count-input-field">
            <CustomTextField
              fullWidth
              className="custom-textfield donation-count-field"
              value={donationCardData?.count}
              onChange={e => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                handleCheckedChange(2, false, e.target.value, donationCardData?.id);
              }}
              type="number"
              pattern="[0-9]*"
              onFocus={handleInputFocus}
            />
          </div>
          <Button
            className={`btn-primary donation-update-count-btn ${donationFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            onClick={() => handleCheckedChange(3, false, donationCardData?.count, donationCardData?.id)}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
            }}>
            <PluseIcon />
          </Button>
        </div>
        <div className="">
          <Button
            fullWidth
            className={`btn-primary donation-add-to-cart-btn ${donationFeatureData[0]?.isActive === false ? 'blur-section' : ''}`}
            disabled={buttonActive || whitelabel === true ? true : false}
            onClick={() => handleAddToCart(donationFeatureData?.[0].eventId, donationCardData?.id)}
            style={{
              backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
              color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
            }}>
            {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DonationAddCard;
