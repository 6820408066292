import { storeConstants } from '../constants/StoreConstants';
import _ from 'lodash';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';
import Config from '../../../utility/Config';
import { fetchFromStorage } from 'utility';
import { identifiers } from '../constants/IdentifierConstants';

const stripeAuthHeader = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Bearer ${Config.stripeAPIKEYDK}`,
};
export const getUserCards = () => dispatch => {
  const token = fetchFromStorage(identifiers?.token);
  if (token) {
    return axiosInstance
      .get(URLS.userCards(false))
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch({
            type: storeConstants.GET_USER_CARDS,
            payload: data.data.cards,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: storeConstants.GET_USER_CARDS,
          payload: [],
        });
      });
  }
};
/* 
//never been used
export const generateTransactionId = payload => async dispatch => {
  const params = new URLSearchParams();
  params.append('amount', payload?.amount * 100);
  params.append('currency', payload?.currency);
  params.append('description', payload?.description);
  params.append('customer', payload.stripeId);
  params.append('card', payload.card);

  const resp = await axiosInstance.post('https://api.stripe.com/v1/charges', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${Config.stripeAPIKEYDK}`,
    },
  });
  // localStorage.setItem('balance_transaction', resp?.data?.balance_transaction);
  return resp?.data?.balance_transaction;
};
 */
/* 
// never been used
export const createUserCard = values => async dispatch => {
  const tokenRes = await createCardTokenFiserv(values);
  const token = tokenRes.paymentToken.value;

  var userDetail = JSON.parse(localStorage.getItem('userDetail'));
  const stripeCardData = await axiosInstance.post(
    URLS.stripeCreateCard(userDetail?.userStripeId),
    `source=${_.get(token, 'data.id')}`,
    { headers: stripeAuthHeader },
  );

  console.log("::> userDetail:", JSON.stringify(userDetail, null, 2))
  // var userDetail = JSON.parse(localStorage.getItem('userDetail'));

  const cardValues = {
    stripeId: userDetail?.userStripeId,
    brand: _.get(stripeCardData, 'data.brand'),
    cardTokenId: _.get(stripeCardData, 'data.id'),
    customerToken: _.get(stripeCardData, 'data.customer'),
    last4: _.chain(stripeCardData).get('data.last4').toNumber().value().toString(),
    expMonth: _.get(stripeCardData, 'data.exp_month').toString(),
    expYear: _.get(stripeCardData, 'data.exp_year').toString(),
    country: _.get(stripeCardData, 'data.country'),
    isDefault: values?.isDefault,
    fingerPrint: token?.data?.card?.fingerprint,
    isSavedForFuture: values?.isSavedForFuture,
  };
  return axiosInstance
    .post(URLS.userCards(true), cardValues)
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.CREATE_USER_CARD,
          payload: { card: data.data.createdCards, cardId: data.data.createdCards?.id },
        });
      }
    })
    .catch(error => {
      dispatch({
        type: storeConstants.CREATE_USER_CARD,
        payload: error?.response,
      });
    });
};
 */

// stripe
export const UpdateDefaultCards = (id, values) => async dispatch => {
  const cardValues = {
    brand: values.brand,
    cardTokenId: values.cardTokenId,
    country: values.country,
    expMonth: values.expMonth,
    expYear: values.expYear,
    last4: values.last4,
    isDefault: values?.isDefault,
    fingerPrint: values?.fingerPrint,
    // isSavedForFuture: values?.isSavedForFuture,
  };

  return axiosInstance
    .put(URLS.deleteUserCards(id), cardValues)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.UPDATE_USER_CARD,
          payload: { id: id, card: cardValues },
        });
      }
    })
    .catch(() => {});
};

export const deleteUserCards = id => dispatch => {
  return axiosInstance
    .delete(URLS.deleteUserCards(id))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.DELETE_USER_CARDS,
          payload: id,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.DELETE_USER_CARDS,
        payload: [],
      });
    });
};

export const verifyCoupon = data => dispatch => {
  dispatch({
    type: storeConstants.VERIFY_COUPON_CODE,
    payload: data,
  });
};

export const CartDetails = () => dispatch => {
  return axiosInstance
    .get(URLS.addToCart)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.CART_DETAIL,
          payload: data.data?.cart,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.CART_DETAIL,
        payload: [],
      });
    });
};

export const cardAddPaymentTime = data => dispatch => {
  dispatch({
    type: storeConstants.SET_CHECKOUT_CARD_DATA,
    payload: data,
  });
};
export const paymentAfterAddCard = data => dispatch => {
  dispatch({
    type: storeConstants.SET_SINGLE_CARD_DATA,
    payload: data,
  });
};
export const CartDetailsById = data => dispatch => {
  dispatch({
    type: storeConstants.SET_CARD_DETAILS_BY_ID,
    payload: data ? data : [],
  });
};
export const setFlagforCardAdd = flag => dispatch => {
  dispatch({
    type: storeConstants.SET_FLAGFOR_CARD_ADD,
    payload: flag,
  });
};
