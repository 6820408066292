import React, { useState, useEffect, useRef, useContext } from 'react';
import { Collapse, Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import parse from 'html-react-parser';
import anchorme from 'anchorme';

import RaffleSlider from './RaffleSlider';
import RaffleTickets from './RaffleTickets';
import EventTimeDetails from '../EventTimeDetails';
import RaffleAddCard from './RaffleAddCard';
import LogoutModal from './LogoutModal';
import PageLoader from 'library/common/components/PageLoader';

import { images } from '../../../../library/common/constants/ImageConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { fetchFromStorage, saveToStorage } from 'utility';
import axiosInstance from '../../../../main/axios';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from 'main/context/socket';
import RaffleAllTicketPrint from './RaffleAllTicketPrint';

import './styles.scss';

const pageStyle = `
  @media all {
    .pagebreak {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  body {
    -webkit-print-color-adjust: exact;
  }
`;
const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Raffle = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const myRef = useRef(null);

  const [isToggle, setToggle] = useState(false);
  const [raffleTicket, setRaffleTicket] = useState([]);
  const [ticketItemData, setTicketItemData] = useState('');
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [singleItem, setClickItemData] = useState('');
  const [isabout, setIsAbout] = useState(true);
  const [isLoader, setIsLoader] = useState(false);

  var eventId = params?.id;

  useEffect(() => {
    // Handle window resize
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    // Fetch ticket data when user is logged in
    props?.isLoggedIn && fetchTicketPurchaseList();
  }, [props?.isLoggedIn]);

  // Calculate slides to show based on screen size
  const getSlideCount = size => {
    if (size > 1279) return 4;
    if (size > 959) return 3;
    if (size > 599) return 2;
    return 1;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getSlideCount(size),
    slidesToScroll: 1,
  };

  // Handle login redirect
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'raffle',
        eventId: eventId,
      },
    });
  };

  // Handle mange login modal and redirect guest checkout screen
  const handleLoginModal = data => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    } else {
      updateGuestCardList(data);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // fetch tickets list
  const fetchTicketPurchaseList = async () => {
    try {
      setIsLoader(true);
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, true));
      if (status === 200 || status === 304) {
        setRaffleTicket(data?.data?.tickets);
        // setTotalCount(data?.data?.count);
        // setOffset(page);
        setIsLoader(false);
      }
    } catch (err) {
      setIsLoader(false);
    }
  };

  const handleTicketItemOnClick = item => {
    setTicketItemData(item);
    setToggleModalValue(true);
  };

  const executeScroll = () => scrollToRef(myRef);

  // Filter raffle feature data
  var raffleFeatureData = props?.eventFeatures?.filter(item => item?.type === 'raffle');

  var currency = props?.eventDetails?.currency?.abbreviation;
  var symbol = props?.eventDetails?.currency?.symbol;

  const reactToPrintFn = useReactToPrint({ contentRef: componentRef, pageStyle: pageStyle });

  return (
    <>
      {isLoader && <PageLoader />}
      <div className="event-about-container">
        <div className="event-time-venu-details">
          {props?.timeZone !== undefined && (
            <EventTimeDetails
              isLocation
              title={OKTION_VARIABLE?.OKTION_DRAW_DATE}
              img={images.watch}
              address={moment(raffleFeatureData[0]?.startTime).format('h:mm A DD MMMM, YYYY')}
            />
          )}
          {props?.eventDetails?.chanceToWinStatus === 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}
              isLocation
              img={images.winIcon}
              address={`${raffleFeatureData[0]?.chanceToWin ?? raffleFeatureData[0]?.chanceToWin} ${OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}`}
            />
          )}
        </div>

        <div className="event-about-details">
          {raffleFeatureData[0]?.featureName && (
            <div
              className="d-flex align-items-center justify-content-between cursor-pointer mb18"
              onClick={() => {
                setIsAbout(!isabout);
              }}>
              <h4 className="event-about-title">{raffleFeatureData[0]?.featureName}</h4>
              <img
                className={`ml18 mr18 ${!isabout ? 'collapse-closed' : ''}`}
                src={images?.uppArraow}
                alt="collapse arrow"
                width="15px"
              />
            </div>
          )}
          {raffleFeatureData[0]?.featureAbout != null && (
            <Collapse isOpen={isabout}>
              <div>
                {raffleFeatureData[0]?.featureAbout != null ? (
                  <div className="event-about-description">
                    {parse(
                      anchorme({
                        input: (raffleFeatureData[0]?.featureAbout).toString().replace(/\n/g, '<br/>'),
                        options: {
                          attributes: {
                            target: '_blank',
                            class: 'detected',
                          },
                        },
                      }),
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Collapse>
          )}
        </div>

        {raffleTicket && raffleTicket?.length > 0 && (
          <div className="event-details-raffle-tickets-print-container">
            <h4 className="event-details-raffle-tickets-print-title">{OKTION_VARIABLE?.OKTION_YOUR_RAFFLE}</h4>

            <p className="event-details-raffle-tickets-print-link cursor-pointer" onClick={() => reactToPrintFn()}>
              {OKTION_VARIABLE?.OKTION_PRINT_ALL_TICKETS}
            </p>
          </div>
        )}

        {/* Do not remove this code bcz it's used for print */}
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="raffle-tickets-print-view-container">
            {raffleTicket?.map((item, index) => (
              <div key={index} className="pagebreak">
                <RaffleAllTicketPrint ticketData={item} imageUrl={props?.eventDetails?.eventImages[0].name} />
              </div>
            ))}
          </div>
        </div>

        {raffleTicket?.length > 0 ? (
          <div className="your-raffle-tickets-right-shadow">
            <div className="your-raffle-tickets-container your-raffle-tickets-scroll">
              {raffleTicket?.map((item, index) => (
                <React.Fragment key={index}>
                  <RaffleTickets
                    ticketData={item}
                    handleItemClick={handleTicketItemOnClick}
                    imageUrl={props?.eventDetails?.eventImages[0].name}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          !isLoader && props?.isLoggedIn && <p className="no-data-found">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</p>
        )}

        <div className="event-details-raffle-feature">
          <h4 className="feature-raffle-prize-title">{OKTION_VARIABLE?.OKTION_RAFFLE_PRIZE}</h4>
          {raffleFeatureData?.[0]?.rafflePrizes?.map((item, i) => (
            <React.Fragment key={i}>
              <RaffleSlider sliderData={item} index={i} />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div ref={myRef}></div>
      {raffleFeatureData[0]?.ticketBundles.length > 0 && (
        <div className="event-details-raffle-tickets-container">
          <h4 className="feature-raffle-tickets-title">{OKTION_VARIABLE?.OKTION_RAFFLE_TICKET_BUNDLES}</h4>{' '}
          <div className="raffle-tickets-add-amount-card-container">
            {raffleFeatureData[0]?.ticketBundles.map((item, i) => (
              <div
                key={i}
                style={{
                  pointerEvents: raffleFeatureData?.[0]?.isActive === false ? 'none' : '',
                  opacity: raffleFeatureData?.[0]?.isActive === false ? '0.4' : '1',
                }}>
                <RaffleAddCard
                  raffleCardData={item}
                  raffleFeatureData={raffleFeatureData}
                  eventDetails={props?.eventDetails}
                  currency={currency}
                  symbol={symbol}
                  whitelabel={props?.whitelabel}
                  setClickItemData={data => {
                    setClickItemData(data);
                  }}
                  handleBtnClick={data => {
                    handleLoginModal(data);
                  }}
                  islogin={props?.isLoggedIn}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="sticky-btn-bg">
        <Button
          onClick={executeScroll}
          className="btn-primary"
          disabled={raffleFeatureData[0]?.isDraw === '1' ? true : false}
          style={{
            backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
          }}>
          {OKTION_VARIABLE?.OKTION_BUY_RAFFLE_TICKET}
        </Button>
      </div>

      <LogoutModal
        isToggle={isToggle}
        toggle={() => setToggle(!isToggle)}
        handleClick={handleLoginRedirect}
        singleItem={singleItem}
        isGuestCheckout={true}
      />

      <Modal className="event-details-raffle-ticket-modal" isOpen={isToggleModal}>
        <div className="text-end">
          <img
            className="cursor-pointer modal-close-icon"
            onClick={() => setToggleModalValue(!isToggleModal)}
            src={images.modalCloseIcon}
            alt=""
          />
        </div>
        <ModalBody>
          <div className="modal-your-raffle-tickets-wrapper">
            <div className="modal-your-raffle-tickets-img-wrapper">
              <img
                src={identifiers.imageUrlConstant + props?.eventDetails?.eventImages[0].name}
                alt={OKTION_VARIABLE?.OKTION_CARD_IMAGE_CAP}
                className="printImage"
                onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
              />
            </div>
            <div className="modal-your-raffle-tickets-details">
              <div>
                <p className="modal-your-raffle-tickets-title">{OKTION_VARIABLE?.OKTION_TICKET_NUMBER}</p>
                <p className="modal-your-raffle-tickets-value">{ticketItemData?.ticketNumber}</p>
              </div>
              {/* {(ticketItemData?.user?.firstName || ticketItemData?.user?.lastName) && ( */}
              <div>
                <p className="modal-your-raffle-tickets-title">{OKTION_VARIABLE?.OKTION_NAME}</p>
                <p className="modal-your-raffle-tickets-value">
                  {ticketItemData?.user?.firstName || ticketItemData?.user?.lastName ? (
                    <>
                      {ticketItemData?.user?.firstName ?? ticketItemData?.user?.firstName}{' '}
                      {ticketItemData?.user?.lastName ?? ticketItemData?.user?.lastName}
                    </>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              {/* )} */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Raffle);
