import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import EventDetailsAuctionModal from '../../../../modules/EventDetails/Frames/Auction/Frame/EventDetailsAuctionModal/EventDetailsAuctionModal';
import BiddingAmountCount from '../../../../modules/EventDetails/Frames/Auction/Frame/EventDetailsAuctionModal/BiddingAmountCount';
import AddItemModal from 'modules/Events/MyEvents/frames/Features/frames/Auction/Frame/ItemTab/AddItemModal';
import DeleteFeatureModal from 'modules/Events/MyEvents/frames/Tickets/frame/DeleteFeatureModal';
import LogoutModal from 'modules/EventDetails/Frames/Raffle/LogoutModal';
import { SocketContext } from 'main/context/socket';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { setApiMessage } from 'library/common/constants/function';
import { EventEmitter } from 'library/common/constants/event';
import { saveToStorage } from 'library/utilities/Storage';
import { CurrencyFormat, fetchFromStorage } from 'utility';
import PageLoader from 'library/common/components/PageLoader';
import { Button } from '@mui/material';
import { ReactComponent as DropDownIcon } from '../../../../resources/images/dropdown_arrow.svg';
import './styles.scss';

const ItemLotCards = ({
  cardItemData,
  isBid,
  categoryList,
  index,
  eventFeatures,
  isLoggedIn,
  eventDetailsData,
  userCards,
  whitelabel,
  isOrganiser,
  eventOutfit,
  feturedItem,
  isDragable,
}) => {
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();

  const [addEditDetailsToggle, setAddEditDetailsToggle] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [countToggle, setCountToggle] = useState(false);
  const [count, setCount] = useState(cardItemData?.startBid);
  const [loading, setLoading] = useState(false);
  const [operationPerformed, setOperationPerformed] = useState(false);
  const [buyitNowObj, setBuyitNowObj] = useState('');
  const [bidButtonStatus, setBidButtonStatus] = useState(false);
  const [isloginToggle, setIsLoginToggle] = useState(false);
  const [bidType, setBidType] = useState('');

  // Filter event features to extract auction-related data
  var auctionFeatureData = eventFeatures && eventFeatures?.filter(item => item?.type === 'auction');

  // Retrieve event and user details from storage
  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  var userDetails = fetchFromStorage(identifiers?.userDetail);

  // Extract event ID from either event details or URL
  const url = window.location.pathname;
  const spliturl = url.split('/');
  const id = spliturl[spliturl.length - 1];
  var eventID = eventDetails !== null ? eventDetails?.id : id;

  useEffect(() => {
    const toggleCloseBidSection = () => {
      setCountToggle(false);
    };

    EventEmitter.subscribe('searchFilterData', toggleCloseBidSection);

    return () => {
      EventEmitter.unsubscribe('searchFilterData', toggleCloseBidSection);
    };
  }, []);

  // useEffect to set the initial bid count based on current or starting bid
  useEffect(() => {
    setCount(
      cardItemData?.currentBid === 0
        ? cardItemData?.startBid
        : Number((cardItemData?.currentBid + cardItemData?.bidIncrement)?.toFixed(2)),
    );
  }, [cardItemData]);

  // Handle toggle for showing add/edit details based on login status
  const handleToggle = val => {
    if (!isLoggedIn || userCards?.length === 0) {
      setAddEditDetailsToggle(!addEditDetailsToggle);
    }
  };

  // Toggle payment modal
  const handlePaymentModal = () => {
    setPaymentToggle(!paymentToggle);
  };

  // Handle bidding logic with checks for login status
  const handleBid = () => {
    handleSetBuyItNow(); // Set 'Buy It Now' option first
    if (!isLoggedIn) {
      setAddEditDetailsToggle(true);
    } else {
      handleToggle(); // Show login/modal if not logged in
      setAddEditDetailsToggle(true);
    }
  };

  // Payment toggle handler based on login status and payment method availability
  const paymenttoggle = () => {
    if (!isLoggedIn) {
      handleSetBuyItNow(true);
      setIsLoginToggle(!isloginToggle);
      setAddEditDetailsToggle(!addEditDetailsToggle);
    } else if (userCards?.length <= 0 && userDetails?.userType !== 'guest') {
      setPaymentToggle(!paymentToggle);
    } else {
      handleAddtoCart();
    }
  };

  // Add item to cart and proceed to checkout
  const handleAddtoCart = async () => {
    setLoading(true);
    const senData = [
      {
        type: 'item',
        eventId: eventID,
        referenceId: null,
        quantity: 1,
        purchaseReferenceId: cardItemData?.id,
        isRaffleTicket: false,
      },
    ];
    try {
      const { status } = await axiosInstance.post(URLS.addToCart, senData);
      if (status === 201 || status === 200) {
        setLoading(false);
        navigate('/checkout');
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err.response.data.msg);
    }
  };

  // Toggle bid count
  const toggleBidCount = () => {
    setCountToggle(!countToggle);
  };

  // Handle quick bid action for organizers and regular users
  const handleQuickBid = () => {
    if (isOrganiser === true) {
      handleToggle();
    } else {
      toggleBidCount();
    }
    setOperationPerformed(false);
  };

  // Increase bid by bid increment
  const handleAddBidCount = () => {
    setCount(Number((count + cardItemData?.bidIncrement)?.toFixed(2)));
  };

  // Decrease bid with conditions based on current or starting bid
  const handleMinusBidCount = () => {
    if (cardItemData?.currentBid === 0) {
      if (count > cardItemData?.startBid) {
        setCount(Number((count - cardItemData?.bidIncrement)?.toFixed(2)));
      }
    } else {
      if (count > cardItemData?.currentBid + cardItemData?.bidIncrement) {
        setCount(Number((count - cardItemData?.bidIncrement)?.toFixed(2)));
      } else {
        setCount(cardItemData?.currentBid + cardItemData?.bidIncrement);
      }
    }
  };

  // Handle login redirection
  const handleRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: eventID,
      },
    });
  };

  // Handle bid submission logic based on user type
  const handleSubmitBidNow = async () => {
    setBidType('bidNow');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setIsLoginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);

        const requestData = {
          bidAmount: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(cardItemData?.id), requestData);
          if (status === 201) {
            setApiMessage('success', 'Success');
            setOperationPerformed(true);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          // Function to handle various bid-related errors
          if (err?.response?.data?.msg === 'ERR_BIDDING_ALREADY_EXISTS') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_OUTBID);
          } else if (err?.response?.data?.msg === 'ERR_BIDDING_INTERVAL_MISMATCH') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_INTERVAL_MISMATCH);
          } else if (err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED') {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        setPaymentToggle(!paymentToggle);
      } else {
        handleSetBuyItNow();
        setIsLoginToggle(!isloginToggle);
        setBidButtonStatus(true);
      }
    }
  };

  // Set Buy It Now data for guest and regular users
  const handleSetBuyItNow = value => {
    var obj = {
      type: 'item',
      quantity: 1,
      referenceId: null,
      subscriptionName: null,
      subscriptionPrice: null,
      name: cardItemData?.itemName,
      amount: value ? cardItemData?.currentBid : cardItemData?.buyItNow,
      purchaseReferenceId: cardItemData?.id,
      isRaffleTicket: false,
      donationAmount: null,
      eventId: eventOutfit?.id,
      currency: eventOutfit?.currency?.abbreviation,
      symbol: eventOutfit?.currency?.symbol,
      event: eventOutfit,
      eventName: eventOutfit?.eventName,
      digiCommission: 0,
      itemDetails: cardItemData,
      cardCharedAmount: 0,
    };
    setBuyitNowObj(obj);
  };

  // Add guest Buy It Now item to checkout and navigate to guest checkout page
  const handleGuestBuyItNow = () => {
    var obj = {
      type: 'item',
      quantity: 1,
      referenceId: null,
      subscriptionName: null,
      subscriptionPrice: null,
      name: cardItemData?.itemName,
      amount: cardItemData?.buyItNow,
      purchaseReferenceId: cardItemData?.id,
      isRaffleTicket: false,
      donationAmount: null,
      eventId: eventOutfit?.id,
      currency: eventOutfit?.currency?.abbreviation,
      symbol: eventOutfit?.currency?.symbol,
      event: eventOutfit,
      eventName: eventOutfit?.eventName,
      digiCommission: 0,
      itemDetails: cardItemData,
      cardCharedAmount: 0,
    };
    updateGuestCardList(obj);
    navigate('/guest-checkout', {
      state: {
        eventId: params?.id,
      },
    });
  };

  // Update guest card list and dispatch event for updating guest checkout
  const updateGuestCardList = data => {
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];
    if (data?.constructor === Array) {
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      guestCheckoutData.push(data);
    }

    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // Handle direct Buy It Now action for logged-in users
  const handleLoginUserBuyItNow = async () => {
    const requestData = {
      eventId: eventID,
    };
    if (isLoggedIn && userDetails?.userType === 'normal') {
      setLoading(true);
      try {
        const { status } = await axiosInstance.put(URLS.buyItNow(cardItemData?.id), requestData);
        if (status === 200) {
          setOperationPerformed(true);
          // setApiMessage('success', OKTION_VARIABLE?.OKTION_BID_SUCCESSFULLY);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventID,
            },
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.errCode === 'ERR_CART_ITEM_ALREADY_EXISTS') {
          setApiMessage('error', OKTION_VARIABLE?.OKTION_BIDDING_CARTITEM_EXIST);
        } else {
          setApiMessage('error', err?.response?.data?.msg);
        }
      }
    } else if (isLoggedIn && userDetails?.userType === 'guest') {
      handleGuestBuyItNow();
    } else {
      handleSetBuyItNow();
      setIsLoginToggle(!isloginToggle);
    }
  };

  // Handle setting the maximum bid for logged-in users
  const handleSetMaxBid = async () => {
    setBidType('bidMax');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setIsLoginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);
        const requestData = {
          myMaxBid: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(cardItemData?.id), requestData);
          if (status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SET_MAX_BID);
            setOperationPerformed(true);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          if (
            err?.response?.data?.errCode === 'ERR_BIDDING_ALREADY_EXISTS' ||
            err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED'
          ) {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        setPaymentToggle(!paymentToggle);
      } else {
        handleSetBuyItNow();
        setIsLoginToggle(!isloginToggle);
        setBidButtonStatus(true);
      }
    }
  };
  const currency = eventDetailsData?.currency != null ? eventDetailsData?.currency?.abbreviation : '';
  const currencySymbol = eventDetailsData?.currency != null ? eventDetailsData?.currency?.symbol : '';

  return (
    <div className="auction-lot-card">
      {loading && <PageLoader />}

      <div className="auction-lot-card-details">
        {!isBid && isDragable && (
          <div className="mb-2 text-end" style={{ marginTop: '-5px', cursor: 'grab' }}>
            <img src={images.hamberger} alt="drg & grop" style={{ height: '12px' }} />
          </div>
        )}
        <div
          className={`auction-lot-img ${
            cardItemData?.status === 'saved' || cardItemData?.isSold || cardItemData?.markAsPaid ? 'img-overlay ' : ''
          }`}
          onClick={() => handleBid(index)}>
          {(cardItemData?.status === 'saved' || cardItemData?.isSold || cardItemData?.markAsPaid) && (
            <div className="img-overlay-wrap">
              <img
                alt="sold item"
                src={
                  cardItemData?.isSold || cardItemData?.markAsPaid
                    ? images.soldOutWithTextIcon
                    : images.itemcardOverlayImage
                }
                className="h-auto"
              />
            </div>
          )}
          <img
            src={identifiers.imageUrlConstant + '' + cardItemData?.images[0]?.name}
            alt={cardItemData?.images[0]?.name}
            onError={e => {
              e.target.src = images?.logo; // Set the default image
              e.target.className = 'error-bg-img w-100 h-100'; // Add error class name
            }}
            onLoad={e => {
              if (e.target.src !== window.location.origin + images?.logo) {
                // Check if the image is not the default
                e.target.className = ''; // Reset the class name
              }
            }}
          />
        </div>
        <div>
          {countToggle === false ? (
            <div onClick={() => handleBid(index)} className="cursor-pointer">
              <p className="aucton-lot-title">{cardItemData?.itemName}</p>
              <p className="aucton-lot-provided--by-title">
                {cardItemData?.providedBy && cardItemData?.providedBy == 'sponsoredBy'
                  ? OKTION_VARIABLE?.OKTION_SPONSOR_BY
                  : cardItemData?.providedBy == 'donatedBy'
                    ? OKTION_VARIABLE?.OKTION_DONATION_BY
                    : OKTION_VARIABLE?.OKTION_PROVIDED_BY}{' '}
                : {cardItemData?.itemProvidedByName}
              </p>
              <div className="auction-lot-bid-title">
                {OKTION_VARIABLE?.OKTION_CURRENT_BID} :{' '}
                <CurrencyFormat
                  value={cardItemData?.currentBid !== null ? Number(cardItemData?.currentBid)?.toFixed(2) : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  decimalScale="2"
                  fixedDecimalScale={true}
                />
              </div>
              <div className="auction-lot-bid-title">
                {OKTION_VARIABLE?.OKTION_STARTING_BID} :{' '}
                <CurrencyFormat
                  value={cardItemData?.startBid !== null ? cardItemData?.startBid : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  decimalScale="2"
                  fixedDecimalScale={true}
                />
              </div>
              {cardItemData?.buyItNow !== 0 && cardItemData?.buyItNow !== null && (
                <div className="auction-lot-bid-title">
                  {OKTION_VARIABLE?.OKTION_BUYITNOW} :{' '}
                  <CurrencyFormat
                    value={cardItemData?.buyItNow !== null ? cardItemData?.buyItNow : '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${currency} ${currencySymbol}`}
                    thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </div>
              )}
              {cardItemData?.winner !== null ? (
                <div className="auction-lot-winner-title">
                  {cardItemData?.reserveMet
                    ? OKTION_VARIABLE?.OKTION_WINNING_BIDDER
                    : OKTION_VARIABLE?.OKTION_HIGHEST_BIDDER}{' '}
                  :{' '}
                  {cardItemData?.reserveMet
                    ? `${cardItemData?.winner?.firstName && cardItemData?.winner?.firstName} ${
                        cardItemData?.winner?.lastName ? cardItemData?.winner?.lastName : ''
                      }`
                    : OKTION_VARIABLE?.OKTION_RESERVE_NOT_MET}
                </div>
              ) : (
                <div className="auction-lot-winner-title">
                  {OKTION_VARIABLE?.OKTION_WINNING_BIDDER} : {OKTION_VARIABLE?.OKTION_NO_CURRENT_WINNER}
                </div>
              )}
            </div>
          ) : (
            <>
              {/* Event cards bidding button section */}
              <BiddingAmountCount
                handleSetMaxBid={handleSetMaxBid}
                handleBidNow={handleSubmitBidNow}
                handleBuyItNow={handleLoginUserBuyItNow}
                handleAdd={handleAddBidCount}
                handleMinus={handleMinusBidCount}
                count={count}
                currency={currency}
                currencySymbol={currencySymbol}
                singleItem={cardItemData}
                setQuickBid={true}
                auctionFeatureData={auctionFeatureData}
              />
            </>
          )}
        </div>
        <div>
          {!auctionFeatureData?.[0]?.isActive && (
            <Button
              fullWidth
              className="btn-primary"
              onClick={() => {
                setAddEditDetailsToggle(!addEditDetailsToggle);
                handleSetBuyItNow();
              }}>
              {OKTION_VARIABLE?.OKTION_LOT_DETAILS}
            </Button>
          )}
          {auctionFeatureData?.[0]?.isActive && isBid && (
            <Button
              fullWidth
              onClick={() => handleQuickBid()}
              className="btn-primary"
              style={{
                backgroundColor:
                  countToggle === true
                    ? '#393939'
                    : operationPerformed === true
                      ? '#0080FF'
                      : eventDetailsData?.primaryColour
                        ? eventDetailsData?.primaryColour
                        : '',
                borderColor:
                  countToggle === true
                    ? '#393939'
                    : operationPerformed === true
                      ? '#0080FF'
                      : eventDetailsData?.primaryColour
                        ? eventDetailsData?.primaryColour
                        : '',
                color:
                  countToggle === true
                    ? '#FFFFFF'
                    : eventDetailsData?.primaryTextColour
                      ? eventDetailsData?.primaryTextColour
                      : '',
                marginTop: cardItemData?.buyItNow === 0 || cardItemData?.buyItNow === null ? '0px' : '',
                marginBottom: cardItemData?.buyItNow === 0 || cardItemData?.buyItNow === null ? '0px' : '',
              }}
              disabled={!auctionFeatureData?.[0]?.isActive || whitelabel === true ? true : false}>
              {countToggle === false
                ? OKTION_VARIABLE?.OKTION_BID
                : operationPerformed === true
                  ? OKTION_VARIABLE?.OKTION_BUTTON_FINISH
                  : OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}{' '}
              <div className={`bid-arrow ${countToggle === false ? 'rotate-arrow-180' : 'bid-down-arrow'}`}>
                <DropDownIcon />
              </div>
            </Button>
          )}
        </div>
      </div>
      {isBid === true ? (
        <>
          {/* For event details auction modal */}
          <EventDetailsAuctionModal
            itemFollowing_isToggle={addEditDetailsToggle}
            itemFollowing_toggle={() => {
              setAddEditDetailsToggle(false);
            }}
            isfromWinningLot={!auctionFeatureData?.[0]?.isActive ? true : false}
            isGuestCheckout={!auctionFeatureData?.[0]?.isActive ? false : true}
            makePayment_toggle={() => paymenttoggle()}
            data={cardItemData}
            singleItem={cardItemData}
            categoryList={categoryList}
            currency={currency}
            currencySymbol={currencySymbol}
            eventDetailsData={eventDetailsData}
            auctionFeatureData={auctionFeatureData}
            buyitNowObj={buyitNowObj}
            userCards={userCards}
            handleSetBuyItNow={handleSetBuyItNow}
          />
        </>
      ) : (
        addEditDetailsToggle && (
          <>
            {/* For feature add auction lot */}
            <AddItemModal
              isToggle={addEditDetailsToggle}
              toggle={() => {
                setAddEditDetailsToggle(!addEditDetailsToggle);
              }}
              closeModal={() => setAddEditDetailsToggle(false)}
              isUpdate={true}
              data={cardItemData}
              categoryList={categoryList}
              index={index}
              getAuctionDetails={() => EventEmitter.dispatch('updateAuctionData')}
              currency={currency}
              isModal
              feturedData={feturedItem}
            />
          </>
        )
      )}

      {/* Login required modal */}
      <LogoutModal
        isToggle={isloginToggle}
        toggle={() => setIsLoginToggle(!isloginToggle)}
        data={cardItemData}
        handleClick={handleRedirect}
        isGuestCheckout={!auctionFeatureData?.[0]?.isActive ? false : true}
        isfromWinningLot={!auctionFeatureData?.[0]?.isActive ? true : false}
        singleItem={buyitNowObj}
        guestUserStatus={bidButtonStatus}
        bidType={bidType}
        bidCount={count}
      />

      {/* Card details required modal */}
      <DeleteFeatureModal
        isToggle={paymentToggle}
        toggle={handlePaymentModal}
        handleDeleteFeature={() => navigate('/user-profile?user-profile-card-details')}
        FROM_WHERE="contribution"
      />
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(ItemLotCards);
