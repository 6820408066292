import PopOver from 'library/common/components/PopOver/PopOver';
import { SocketContext } from 'main/context/socket';
import React, { useContext } from 'react';
import { Media } from 'reactstrap';
import '../styles.scss';

const EventTimeDetails = ({ img, address, startDate, endDate, isLocation, title, secondaryTitle }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <Media className="event-time-details-wrapper">
      <div className="event-time-detail-icon">
        <img src={img} alt="" />
      </div>
      <Media className="event-time-details-content">
        {!isLocation ? (
          <Media className="event-time-details-heading">
            <p className="event-time-details-title">{title}</p>
            <p className="event-time-details-value">
              {startDate} {endDate && '-'} {endDate}
            </p>
          </Media>
        ) : (
          <Media className="event-time-details-heading">
            <div className="event-time-details-title d-flex align-items-center">
              {title}{' '}
              {title === OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN && (
                <PopOver
                  title={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}
                  description={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN_SUBTEXT}
                  target="visibility"
                />
              )}
            </div>
            <p className="event-time-details-value">
              {Array.isArray(address) ? address?.map((line, index) => <span key={index}>{line}</span>) : address}
            </p>
            {secondaryTitle && (
              <>
                <p className="event-time-details-venu-title mt18">{secondaryTitle}</p>
                <p className="event-time-details-value">
                  {startDate} {endDate && '-'} {endDate}
                </p>
              </>
            )}
          </Media>
        )}
      </Media>
    </Media>
  );
};

export default EventTimeDetails;
