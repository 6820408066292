import React, { useState, useEffect, useContext } from 'react';
import { Button, Row, Col } from 'reactstrap';
import Notice from 'library/common/components/Notice';
import { images } from 'library/common/constants/ImageConstants';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { fetchFromStorage } from 'utility';
import { SocketContext } from 'main/context/socket';
import PageLoader from 'library/common/components/PageLoader';
import ItemDetailsModal from './PriceTab/ItemDetailsModal';
import SupplyItemCard from './PriceTab/SupplyItemCard';
import CustomPagination from 'library/common/components/Pagination/CustomPagination';

const AvailableTab = ({ isMonitor, tabValue }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [isToggle, setToggle] = useState(false);
  const [supplyItemList, setSupplyItemData] = useState([]);
  const [isView, setIsView] = useState('');
  const [newLot, setNewLot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [filterLotStatus, setFilterLotStatus] = useState('');
  const limit = 10;

  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  useEffect(() => {
    const is_view = fetchFromStorage(identifiers.is_view);
    setIsView(is_view);
  }, []);

  useEffect(() => {
    const handleSupplyLotUpdate = () => {
      fetchSupplyItem(offset, tabValue);
      setToggle(false);
    };

    EventEmitter.subscribe('updatesupplylotlist', handleSupplyLotUpdate);

    return () => {
      EventEmitter.unsubscribe('updatesupplylotlist', handleSupplyLotUpdate);
    };
  }, []);

  useEffect(() => {
    if (!isNewOrg) {
      fetchSupplyItem(offset, tabValue);
    }
  }, [newLot, isNewOrg]);
  const handleItemToggle = () => {
    setToggle(!isToggle);
    setNewLot(true);
  };
  const fetchSupplyItem = async (page = 1, lotStatus) => {
    setLoading(true);
    const filterSupplyItem = `${lotStatus}&offset=${page}&limit=${limit}`;
    setOffset(page);
    setFilterLotStatus(lotStatus);
    try {
      const { status, data } = await axiosInstance.get(URLS.getSupplyItemByStatus(filterSupplyItem));
      if (status === 200 || status === 201) {
        setSupplyItemData(data?.data?.Items);
        setTotalRecord(data?.data?.count);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleSelectChange = async e => {
    const newValue = e.target.value.toLowerCase();
    setFilterLotStatus(newValue);
    fetchSupplyItem(1, newValue);
  };
  // handle prev and next btn
  const handlePaginationButtonClick = async navDirection => {
    if (navDirection === 'prev') {
      if (offset > 1) {
        setOffset(offset - 1);
        fetchSupplyItem(offset - 1, filterLotStatus);
      }
    } else {
      if (offset < Math.ceil(totalRecord / limit)) {
        setOffset(offset + 1);
        fetchSupplyItem(offset + 1, filterLotStatus);
      }
    }
  };
  // handle page number
  const handlePaginationClick = async (event, page) => {
    if (offset !== page) {
      setOffset(Number(page));
      fetchSupplyItem(Number(page), filterLotStatus);
    }
  };
  const handleSupplayItem = () => {
    fetchSupplyItem(offset, filterLotStatus);
  };
  return (
    <div className="pricesForm">
      {loading && <PageLoader />}
      {tabValue === 'available' && (
        <>
          <Row>
            <Col sm="12">
              <Notice
                image={images.bulb}
                title={OKTION_VARIABLE?.OKTION_SUPPLY_LOTS}
                message={OKTION_VARIABLE?.OKTION_SUPPLY_LOT_HINT}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                EventEmitter.dispatch('removesupplylotimage');
                handleItemToggle();
              }}
              className="add-ticket-btn mt-4 mb-2 fs-12 fw-500"
              disabled={isView === true ? true : false}>
              {OKTION_VARIABLE?.OKTION_NEW_LOT}
            </Button>
          </div>
        </>
      )}
      {tabValue === 'sold' && (
        <div className="d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <label className="">{OKTION_VARIABLE?.OKTION_SORT_BY} : &nbsp;</label>
            <select
              name="select"
              className="form-select custom-select custom-select-md"
              id="exampleSelect"
              onChange={e => handleSelectChange(e)}>
              <option>{OKTION_VARIABLE?.OKTION_SOLD}</option>
              <option>{OKTION_VARIABLE?.OKTION_UNSOLD}</option>
              <option>{OKTION_VARIABLE?.OKTION_EXPIRED}</option>
            </select>
          </div>
        </div>
      )}
      <Row className="mt-3 mb-3">
        {supplyItemList && supplyItemList?.length > 0
          ? supplyItemList?.map((item, i) => (
              <Col className="mt-3" sm="6" xs="12" key={i}>
                <SupplyItemCard
                  data={item}
                  isMonitor={isMonitor}
                  is_view={isView}
                  getSupplyItem={handleSupplayItem}
                  tabValue={tabValue}
                />
              </Col>
            ))
          : !loading && <p className="no-data-found">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</p>}

        <CustomPagination
          paginationArray={supplyItemList}
          currentPage={offset}
          list={limit}
          totalRecord={totalRecord}
          handlePrevNextBtn={handlePaginationButtonClick}
          handleChangePage={handlePaginationClick}
        />
      </Row>
      {isToggle && <ItemDetailsModal isToggle={isToggle} toggle={handleItemToggle} newLot={newLot} />}
    </div>
  );
};

export default AvailableTab;
