import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from '@mui/material';
import { images } from '../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../main/context/socket';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';

const LogoutModal = ({
  isToggle,
  toggle,
  handleClick,
  singleItem,
  isGuestCheckout,
  guestUserStatus,
  isfromWinningLot,
  lotId,
  data,
  bidType,
  bidCount,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const params = useParams();

  const updateGuestCardList = () => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (singleItem?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...singleItem];
    } else if (singleItem) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(singleItem);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const handleGuestChekout = () => {
    if (guestUserStatus) {
      navigate('/guest-signup', {
        state: {
          eventId: params?.id,
          isAuction: true,
          singleItem: data,
          bidCount: bidCount,
          bidType: bidType,
          isAuctionLot: true,
        },
      });
    } else {
      updateGuestCardList(singleItem);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        className="modal-dialog add-price-modal logout-modal logout-modal-container"
        isOpen={isToggle}
        toggle={() => toggle(false)}>
        <ModalBody>
          <div className="logout-modal-details-wrapper">
            <div className="logout-modal-details-text-wrap">
              <div className="logout-modal-img">
                <img src={images.giftImage} alt="contribute" />
              </div>
              <div>
                <h3 className="logout-modal-details-text-title">{OKTION_VARIABLE?.OKTION_MODAL_SIGN_IN}</h3>
                <h3 className="logout-modal-details-text-description">
                  {isGuestCheckout
                    ? OKTION_VARIABLE?.OKTION_CONTINUE_AS_A_GUEST
                    : OKTION_VARIABLE?.OKTION_CONTINUE_AS_A_GUEST}
                </h3>
              </div>
            </div>
            <div>
              <img className="modal-close-icon cursor-pointer" onClick={toggle} src={images.close} alt="" />
            </div>
          </div>
          <div className="logout-modal-btn">
            <Button fullWidth className="btn-primary" onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_SIGN_IN}
            </Button>
            {isGuestCheckout && (
              <Button
                fullWidth
                className="b-btn-primary"
                onClick={() => {
                  handleGuestChekout();
                  // updateGuestCardList(singleItem);
                }}>
                {guestUserStatus
                  ? OKTION_VARIABLE?.OKTION_CONTINUE_AS_GUEST
                  : OKTION_VARIABLE?.OKTION_CONTINUE_AS_GUEST}
              </Button>
            )}
            {isfromWinningLot && (
              <Button
                fullWidth
                className="b-btn-primary"
                onClick={() => {
                  navigate(`/guest-as-email`, {
                    state: {
                      lotId: data?.id,
                      singleItem: singleItem,
                    },
                  });
                }}>
                {OKTION_VARIABLE?.OKTION_CHECKOUT_AS_GUEST}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LogoutModal;
