import React, { useState, useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { images } from 'library/common/constants/ImageConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { fetchFromStorage, CurrencyFormat } from 'utility';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { SocketContext } from 'main/context/socket';
import RelistingModal from './RelistingModal';

const ItemDetailsSmallModal = ({ isToggle, toggle, lotData, getSupplyItem }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();

  const [toggleModal, setToggleModal] = useState(false);

  const user_data = fetchFromStorage(identifiers.userDetail);

  //  handle winner contact
  const handleWinnerContact = data => {
    let obj = {
      fromUser: true,
      paramsData: {
        recipientId: data?.auctionItem?.winner?.id,
        firstName: data?.auctionItem?.winner?.firstName,
        lastName: data?.auctionItem?.winner?.lastName,
        userProfilePicture: data?.auctionItem?.winner?.userProfilePicture,
        referenceId: data?.id,
        chatType: 'supplyItem',
        referenceName: data?.name,
      },
    };
    navigate('/mail/' + data?.auctionItem?.winner?.id, obj);
  };
  // handle contact
  const handleConatct = data => {
    let obj = {
      fromUser: true,
      paramsData: {
        recipientId: data?.organiserUser?.id,
        firstName: data?.organiserUser?.firstName,
        lastName: data?.organiserUser?.lastName,
        userProfilePicture: data?.organiserUser?.userProfilePicture,
        referenceId: data?.id,
        chatType: 'supplyItem',
        referenceName: data?.name,
      },
    };
    navigate('/mail/' + data?.organiserUser?.id, obj);
  };
  // handle claimed
  const handleClaimed = async () => {
    var requestData = {
      images: lotData?.images,
      ...(lotData?.name !== '' && { name: lotData?.name }),
      ...(lotData?.aboutItme !== '' && { aboutItme: lotData?.aboutItme }),
      ...(lotData?.categoryId !== '' && { categoryId: lotData?.categoryId }),
      ...(lotData?.prizeValue !== '' && { value: lotData?.prizeValue }),
      ...(lotData?.startingBid !== '' && { startingBid: lotData?.startingBid }),
      ...(lotData?.bidIncrement !== '' && { bidIncrement: lotData?.bidIncrement }),
      ...(lotData?.reserveAmount !== '' && { reserveAmount: lotData?.reserveAmount }),
      ...(lotData?.buyItNow !== '' && { buyItNow: lotData?.buyItNow }),
      ...(lotData?.itemExpiryDate !== '' && { itemExpiryDate: moment(lotData.itemExpiryDate).toISOString() }),
      ...(lotData?.startingBid !== '' && { startingBid: lotData?.startingBid }),
      ...(lotData?.postageAndHandeling !== '' && { postageAndHandeling: lotData?.postageAndHandeling }),
      ...(lotData?.postageHandlingNote !== '' && { postageHandlingNote: lotData?.postageHandlingNote }),
      ...(lotData?.status !== '' && { status: lotData?.status }),
      ...(lotData?.itemStatus !== '' && { postageHandlingNote: lotData?.itemStatus }),
      ...(lotData?.currencyId !== '' && { currency: lotData?.currency?.id }),
      ...(lotData?.value !== '' && { value: lotData?.value }),
      ...(lotData?.markAsClaimed !== '' && { markAsClaimed: true }),
    };
    try {
      const { status } = await axiosInstance.put(URLS.updateSupplyItem(lotData?.id), requestData);

      if (status === 200 || status === 201 || status === 304) {
        getSupplyItem();
        toggle();
      }
    } catch (err) {
      toggle();
    }
  };
  // handle redirect event details
  const handleRedirectEvent = () => {
    navigate(`/event-details/${lotData?.event?.id}`);
  };
  const handleRelistLotToggle = () => {
    setToggleModal(!toggleModal);
  };

  return (
    <div className="AddPriceModal ">
      <Modal size="md" className="customModal add-price-modal" isOpen={isToggle}>
        <div className="text-end">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div className="addPriceModalForm itemDetailsSmallModal">
            <h5 className="mb-2">{lotData?.name}</h5>
            <div className="d-flex justify-content-between">
              <div>
                <span className="fs-12 smallBadge">{lotData?.itemStatus}</span>{' '}
                <span className="fs-16 ms-2">{lotData?.event?.eventName}</span>
              </div>
              <div>
                <button className="underline fs-16 contactBtn" onClick={handleRedirectEvent}>
                  {OKTION_VARIABLE?.OKTION_GOTO_EVENT}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 borderBottom pb-3">
              <div>
                <span className="fs-16 ms-2">
                  {lotData?.organiserUser?.firstName} {lotData?.organiserUser?.lastName}
                </span>
              </div>
              <div>
                {user_data?.id !== lotData?.organiserUser?.id ? (
                  <button onClick={() => handleConatct(lotData)} className="underline fs-16 contactBtn">
                    {OKTION_VARIABLE?.OKTION_CONTACT}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="mb-4 mt-4">
              <p className="fs-16 fw-500 mb-1">
                {lotData.hasOwnProperty('raffle') === true
                  ? OKTION_VARIABLE?.OKTION_PRIZE_DETAILS
                  : lotData.hasOwnProperty('auction') === true
                    ? OKTION_VARIABLE?.OKTION_LOT_DETAILS
                    : ''}{' '}
              </p>
              {lotData.hasOwnProperty('raffle') === true ? (
                <>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_PRIZE_VALUE} :
                    <CurrencyFormat
                      value={lotData?.value !== null ? lotData?.value : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        lotData?.currency !== null && `${lotData?.currency?.abbreviation} ${lotData?.currency?.symbol}`
                      }
                      thousandSpacing={lotData?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_PRIZE_PLACE} :
                    <CurrencyFormat
                      value={lotData?.raffleItem?.prizePlace !== null ? lotData?.raffleItem?.prizePlace : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        lotData?.currency !== null && `${lotData?.currency?.abbreviation} ${lotData?.currency?.symbol}`
                      }
                      thousandSpacing={lotData?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                </>
              ) : lotData.hasOwnProperty('auction') === true ? (
                <>
                  <p className="fs-16 mb-1">{OKTION_VARIABLE?.OKTION_BID_ZERO}</p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_CURRENT_BID} :
                    <CurrencyFormat
                      value={lotData?.auctionItem?.currentBid !== null ? lotData?.auctionItem?.currentBid : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        lotData?.currency !== null && `${lotData?.currency?.abbreviation} ${lotData?.currency?.symbol}`
                      }
                      thousandSpacing={lotData?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_RESERVE} :
                    <CurrencyFormat
                      value={lotData?.reserveAmount !== null ? lotData?.reserveAmount : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        lotData?.currency !== null && `${lotData?.currency?.abbreviation} ${lotData?.currency?.symbol}`
                      }
                      thousandSpacing={lotData?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_RESERVE_MET} :
                    {lotData?.auctionItem?.reserveMet === false
                      ? OKTION_VARIABLE?.OKTION_NO
                      : OKTION_VARIABLE?.OKTION_YES}
                  </p>
                </>
              ) : (
                ''
              )}
            </div>
            {lotData?.auctionItem?.winnerId !== null && (
              <>
                {lotData.hasOwnProperty('auction') === true && (
                  <div className="mb-4 mt-4">
                    <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_WINNER_DETAILS}</p>
                    <div className="d-flex justify-content-between">
                      <p className="fs-16 mb-1">
                        {OKTION_VARIABLE?.OKTION_WINNER} : {lotData?.auctionItem?.winner?.firstName}{' '}
                        {lotData?.auctionItem?.winner?.lastName}
                      </p>
                      <p>
                        {user_data?.id !== lotData?.organiserUser?.id ? (
                          <button onClick={() => handleWinnerContact(lotData)} className="underline fs-16 contactBtn">
                            {OKTION_VARIABLE?.OKTION_CONTACT}
                          </button>
                        ) : null}
                      </p>
                    </div>
                    <p className="fs-16 mb-1">
                      {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS} : {lotData?.auctionItem?.winner?.email}
                    </p>
                    <p className="fs-16 mb-1">
                      {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS} : {lotData?.auctionItem?.winner?.phoneNumber}
                    </p>
                  </div>
                )}
              </>
            )}
            {lotData?.raffleItem?.winner !== null && (
              <>
                {lotData.hasOwnProperty('raffle') === true && (
                  <div className="mb-4 mt-4">
                    <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_WINNER_DETAILS}</p>
                    <div className="d-flex justify-content-between">
                      <p className="fs-16 mb-1">
                        {OKTION_VARIABLE?.OKTION_WINNER} : {lotData?.raffleItem?.winner[0]?.firstName}{' '}
                        {lotData?.raffleItem?.winner[0]?.lastName}
                      </p>
                      <p>
                        {user_data?.id !== lotData?.organiserUser?.id ? (
                          <button onClick={() => handleConatct(lotData)} className="underline fs-16 contactBtn">
                            {OKTION_VARIABLE?.OKTION_CONTACT}
                          </button>
                        ) : null}
                      </p>
                    </div>
                    <p className="fs-16 mb-1">
                      {' '}
                      {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS_COLON} {lotData?.raffleItem?.winner[0]?.email}
                    </p>
                    <p className="fs-16 mb-1">
                      {OKTION_VARIABLE?.OKTION_MOBILE} : {lotData?.raffleItem?.winner[0]?.phoneNumber}
                    </p>
                  </div>
                )}
              </>
            )}
            {lotData.hasOwnProperty('auction') === true ? (
              <div className="mb-4 mt-4">
                <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_PAYMENT_STATUS}</p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_SALE_STATUS} :
                  <span className={lotData?.auctionItem?.markAsPaid === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_SOLD
                      : OKTION_VARIABLE?.OKTION_UNSOLD}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_PAYMENT_STATUS} :
                  <span className={lotData?.auctionItem?.markAsPaid === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_PAID
                      : OKTION_VARIABLE?.OKTION_UNPAID}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_CLAIM_STATUS} :
                  <span className={lotData?.auctionItem?.markAsClaimed === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsClaimed === true
                      ? OKTION_VARIABLE?.OKTION_CLAIMED
                      : OKTION_VARIABLE?.OKTION_UNCLAIMED}
                  </span>
                </p>
              </div>
            ) : (
              <div className="mb-4 mt-4">
                <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_CLAIM_STATUS}</p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_WIN_STATUS} :
                  <span className={lotData?.raffleItem?.winner !== null ? 'green-color' : 'red-color'}>
                    {lotData?.raffleItem?.winner !== null ? ' Won' : ' Unwon'}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_CLAIM_STATUS} :
                  <span className={lotData?.raffleItem?.markAsClaimed === true ? 'green-color' : 'red-color'}>
                    {lotData?.raffleItem?.markAsClaimed === true ? ' Claimed' : ' Unclaimed'}
                  </span>
                </p>
              </div>
            )}
            {lotData?.itemStatus === 'unsold' ? (
              <Button className="app-button mt-2 me-2 mWidth-100 main-button" onClick={handleRelistLotToggle}>
                {OKTION_VARIABLE?.OKTION_RELIST_LOT}
              </Button>
            ) : (
              <Button
                className={
                  lotData?.markAsClaimed
                    ? 'app-button mt-2 me-2 mWidth-100 claimedButton'
                    : 'app-button mt-2 me-2 mWidth-100 main-button'
                }
                onClick={handleClaimed}>
                {lotData?.markAsClaimed ? 'Claimed' : 'Unclaimed'}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>

      <RelistingModal isToggle={toggleModal} toggle={handleRelistLotToggle} itemData={lotData} />
    </div>
  );
};

export default ItemDetailsSmallModal;
