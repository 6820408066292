import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ReactComponent as Closed } from '../../../../../../../../../resources/images/close.svg';
import { SocketContext } from 'main/context/socket';
import './styles.scss';

const LotLimiteModal = ({ isToggle, closeModal }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <div>
      <Modal className="modal-dialog after-purchase-modal" isOpen={isToggle}>
        <Closed className="model-close" onClick={closeModal} />
        <ModalBody>
          <h5 className="text-center pb-2">{OKTION_VARIABLE?.OKTION_LOT_LIMIT}</h5>
          <p className="text-center pb-1 pt-1">{OKTION_VARIABLE?.OKTION_LOT_LIMIT_DESCRIPTION}</p>
          <Button className="btn-block h-45 main-button border-0 fw-500 buyBtn mt-3" onClick={closeModal}>
            {OKTION_VARIABLE?.OKTION_BACK_BUTTON}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LotLimiteModal;
