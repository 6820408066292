import React from 'react';
import { images } from 'library/common/constants/ImageConstants';
import '../../styles.scss';

const TicketSold = ({ totalTicket }) => {
  return (
    <div className="ticketSold">
      <div>
        <img className="me-3" src={images.auctionWallPriceImg} alt="" />
        {totalTicket?.ticketsSold && `${totalTicket?.ticketsSold} tickets sold`}
      </div>
    </div>
  );
};

export default TicketSold;
