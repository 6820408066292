import React, { useContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../../../main/context/socket';
import { Button } from '@mui/material';
import '../styles.scss';

const DeleteFeatureModal = ({ isToggle, toggle, handleDeleteFeature, FROM_WHERE, isEventStatus }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div>
      <Modal className="add-card-details-modal" isOpen={isToggle}>
        <div className="add-card-detail-heading">
          <h2 className="add-card-detail-title">
            {FROM_WHERE === 'contribution' ? OKTION_VARIABLE?.OKTION_UPDATE_CARD_DETAILS : ''}
          </h2>
          <img className="cursor-pointer modal-close-icon" onClick={toggle} src={images.modalCloseIcon} alt="" />
        </div>
        <ModalBody>
          <p className="add-card-detail-sub-title">
            {FROM_WHERE === 'contribution'
              ? OKTION_VARIABLE?.OKTION_UPDATE_CARDDETAILS
              : OKTION_VARIABLE?.OKTION_DELETE_FEATURE_MESSAGE}{' '}
          </p>
          <div className="add-card-detail-footer">
            <Button
              fullWidth
              className="btn-primary"
              disabled={isEventStatus === 'Closed' ? true : false}
              onClick={() => {
                handleDeleteFeature();
                toggle();
              }}>
              {FROM_WHERE === 'contribution'
                ? OKTION_VARIABLE?.OKTION_ADD_CARD_DETAILS
                : OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
            </Button>
            <Button fullWidth className="b-btn-primary" onClick={toggle}>
              {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteFeatureModal;
