export const images = {
  logo: require('resources/images/logo.svg').default,
  badge: require('resources/images/badge.svg').default,
  raffle: require('resources/images/raffle.svg').default,
  tickets: require('resources/images/tickets.svg').default,
  donation: require('resources/images/donation.svg').default,
  auction: require('resources/images/auction.svg').default,
  mainBg: require('resources/images/main-bg.png'),
  heroBg: require('resources/images/hero-bg.png'),
  mapMarkerBlack: require('resources/images/map-marker-black.svg').default,
  eventImage1: require('resources/images/event-image.png'),
  eventImage2: require('resources/images/event-image-2.png'),
  eventImage3: require('resources/images/event-image-3.png'),
  general: require('resources/images/general.svg').default,
  calendar: require('resources/images/calendar.svg').default,
  visaLogo: require('resources/images/visa-logo.png'),
  visaLogoBig: require('resources/images/visa-logo-big.png'),
  dgLogoBig: require('resources/images/dg-logo-big.png'),
  subscription: require('resources/images/subscription.png'),
  rafelIcon: require('resources/images/event-thumb.svg').default,
  draft: require('resources/images/draft.svg').default,
  price: require('resources/images/price.svg').default,
  ticket: require('resources/images/ticket.svg').default,
  auctionWithBorder: require('resources/images/auctionWithBorder.svg').default,
  donationWithBorder: require('resources/images/donationWithBorder.svg').default,
  causeWithBorder: require('resources/images/causeWithBorder.svg').default,
  giftWithBorder: require('resources/images/giftWithBorder.svg').default,
  priceWithBorder: require('resources/images/priceWithBorder.svg').default,
  up: require('resources/images/up.png'),
  bulb: require('resources/images/bulb.png'),
  deleteWithCircle: require('resources/images/deleteWithCircle.svg').default,
  ticketAuction: require('resources/images/ticketAuction.svg').default,
  uppArraow: require('resources/images/uppArrow.png'),
  downArrow: require('resources/images/downArrow.png'),
  down_arrow: require('resources/images/down_arrow.png'),
  ticketDonation: require('resources/images/ticketDonation.svg').default,
  close: require('resources/images/close.png'),
  draft_drawer: require('resources/images/draft_drawer.png'),
  launch: require('resources/images/launch.png'),
  wonPriceCard1: require('resources/images/wonPriceCard1.png'),
  raffleWallImg: require('resources/images/raffle_wall_img.png'),
  raffleWallCardImg2: require('resources/images/raffleWallCardImg2.png'),
  congratulation: require('resources/images/congratulation.png'),
  itemcardOverlayImage: require('resources/images/itemcardOverlayImage.png'),
  prizeIcon: require('resources/images/prizeIcon.svg').default,
  hamberger: require('resources/images/hamberger.png'),
  scanCode: require('resources/images/scanCode.png'),
  launchAuctionWallIMg1: require('resources/images/launchAuctionWallIMg1.png'),
  launchAuctionWallIMg2: require('resources/images/launchAuctionWallIMg2.png'),
  auctionWallPriceImg: require('resources/images/auctionWallPriceImg.png'),
  Menu: require('resources/images/Menu.png'),
  blackDelete: require('resources/images/black-del.png'),
  closeWithCircle: require('resources/images/closeWithCircle.png'),
  yellowTick: require('resources/images/yellowTick.png'),
  winInactive: require('resources/images/user-profile/winInactive.svg').default,
  winActive: require('resources/images/user-profile/winActive.svg').default,
  personalDetailActive: require('resources/images/user-profile/personal-detail-active.svg').default,
  personalDetailInActive: require('resources/images/user-profile/personal-detail-inactive.svg').default,
  cardDetailsActive: require('resources/images/user-profile/cardDetails-active.svg').default,
  cardDetailsInActive: require('resources/images/user-profile/cardDetails-inactive.svg').default,
  passwordActive: require('resources/images/user-profile/password-active.svg').default,
  passwordInActive: require('resources/images/user-profile/password-inactive.svg').default,
  subscriptionActive: require('resources/images/user-profile/subscription-active.svg').default,
  subscriptionInActive: require('resources/images/user-profile/subscription-inactive.svg').default,
  privacyPolicyActive: require('resources/images/user-profile/privacyPolicy-active.svg').default,
  privacyPolicyInActive: require('resources/images/user-profile/privacyPolicy-inactive.svg').default,
  eventHistoryActive: require('resources/images/user-profile/eventHistory-active.svg').default,
  eventHistoryInActive: require('resources/images/user-profile/eventHistory-inactive.svg').default,
  contributionInactive: require('resources/images/user-profile/contributionActive.svg').default,
  contributionActive: require('resources/images/user-profile/contributionInactive.svg').default,
  invoicesActive: require('resources/images/user-profile/invoiceactive.svg').default,
  invoicesInactive: require('resources/images/user-profile/invoiceInactive.svg').default,
  subscriptionImg: require('resources/images/user-profile/subscriptionImg.png'),
  subscriptionTopImg: require('resources/images/user-profile/SubscriptionTopImg.png'),
  subscriptionExicutiveImg: require('resources/images/user-profile/subscriptionExicutiveImg.png'),
  subscriptionOneOfEvent: require('resources/images/user-profile/subscriptionOneOfEvent.png'),
  winlossModalImg1: require('resources/images/user-profile/winlossModalImg1.png'),
  winlossModalImg2: require('resources/images/user-profile/winlossModalImg2.png'),
  winlossModalImg3: require('resources/images/user-profile/winlossModalImg3.png'),
  donationImg: require('resources/images/user-profile/donationImg.png'),
  circleWithYellowTick: require('resources/images/user-profile/circleWithYellowTick.png'),
  calendorWithCircle: require('resources/images/calendorWithCircle.png'),
  listview: require('resources/images/listview.png'),
  prevIcon: require('resources/images/prevIcon.png'),
  nextIcon: require('resources/images/nextIcon.png'),
  location: require('resources/images/location.png'),
  watch: require('resources/images/watch.png'),
  nikeImg: require('resources/images/nike.png'),
  winIcon: require('resources/images/winIcon.png'),
  admins_active: require('resources/images/admins_active.svg').default,
  admins_inActive: require('resources/images/admins_inActive.svg').default,
  attendees_active: require('resources/images/manage-event/attendees_active.svg').default,
  attendees_inActive: require('resources/images/manage-event/attendees_inActive.svg').default,
  table_active: require('resources/images/manage-event/table_active.svg').default,
  table_inActive: require('resources/images/manage-event/table_inActive.svg').default,
  event_dashboard_active: require('resources/images/manage-event/event_dashboard_active.svg').default,
  event_dashboard_inActive: require('resources/images/manage-event/event_dashboard_inActive.svg').default,
  event_details_active: require('resources/images/manage-event/event_details_active.svg').default,
  event_details_inActive: require('resources/images/manage-event/event_details_inActive.svg').default,
  feature_active: require('resources/images/manage-event/feature_active.svg').default,
  feature_inActive: require('resources/images/manage-event/feature_inActive.svg').default,
  sponsor_active: require('resources/images/manage-event/sponsor_active.svg').default,
  sponsor_inActive: require('resources/images/manage-event/sponsor_inActive.svg').default,
  whiteLabel_active: require('resources/images/manage-event/whiteLabel_active.svg').default,
  whiteLabel_inActive: require('resources/images/manage-event/whiteLabel_inActive.svg').default,
  search: require('resources/images/search.png'),
  checkIcon: require('resources/images/checkIcon.png'),
  warningIcon: require('resources/images/iconWarning.png'),
  infoIcon: require('resources/images/infoIcon.png'),
  DropdownDown: require('resources/images/DropdownDown.png'),
  bankingDetail: require('resources/images/bankingDetails.svg').default,
  building_without_bg: require('resources/images/general_building_icon.svg').default,
  grid_view: require('resources/images/grid.svg').default,
  yellowArrow: require('resources/images/rightArrow.png'),
  mastercardLogo: require('resources/images/user-profile/Mastercard-logo.png'),
  giftImage: require('resources/images/gift-image.png'),
  cart: require('resources/images/cart.png'),
  SendIcon: require('resources/images/send-icon.svg').default,
  Noprofile: require('resources/images/no-profile.png'),
  claimed: require('resources/images/claimed.png'),
  unClaimed: require('resources/images/unclaimed.png'),
  PlaceHolderImage: require('resources/images/PlaceHolderImage.png'),
  poweredBy: require('resources/images/powered-by.png'),
  donationChart: require('resources/images/donation-chart.svg').default,
  donationSmallChart: require('resources/images/donation-small-chart.svg').default,
  unionpayLogo: require('resources/images/unionpay-logo.png'),
  jcbLogo: require('resources/images/jcb-logo.png'),
  locationIcon: require('resources/images/location.svg').default,
  locationIconGray: require('resources/images/locationIconGray.svg').default,
  transferActive: require('resources/images/transfer-Active.svg').default,
  transferInactive: require('resources/images/transfer-Inactive.svg').default,
  bankActive: require('resources/images/bank-Active.svg').default,
  bankInactive: require('resources/images/bank-Inactive.svg').default,
  supplyActive: require('resources/images/supply-Active.svg').default,
  supplyInactive: require('resources/images/supply-Inactive.svg').default,
  menuIcon: require('resources/images/menu-icon.svg').default,
  closeIcon: require('resources/images/closeIcon.svg').default,
  drawer_icon: require('resources/images/drawer-icon.svg').default,
  cross_icon: require('resources/images/cross-icon.svg').default,
  calender_without_background: require('resources/images/calender_without_background.svg').default,
  app_logo: require('resources/images/ic_launcher.png'),
  eventHand: require('resources/images/event-hand.svg').default,
  eventGift: require('resources/images/event-gift.svg').default,
  eventTicket: require('resources/images/event-ticket.svg').default,
  deleteSponser: require('resources/images/edit_icon.svg').default,
  oktionCrashImg: require('resources/images/oktion-crash.png'),
  verificationActive: require('resources/images/verification_active.svg').default,
  verificationInactive: require('resources/images/verification_inactive.svg').default,
  IdentificationDocIcon: require('resources/images/Identification_doc_icon.svg').default,
  verificationNoIcon: require('resources/images/verification-no-icon.svg').default,
  verificationYesIcon: require('resources/images/verification-yes-icon.svg').default,
  verificationDeleteIcon: require('resources/images/verification-delete-icon.svg').default,
  verificationAlertIcon: require('resources/images/verification-alert.svg').default,
  verificationBusinessIcon: require('resources/images/business-verification-icon .svg').default,
  socailLinkInactiveIcon: require('resources/images/social-inactive-icon.svg').default,
  socailLinkActiveIcon: require('resources/images/social-active-icon.svg').default,
  loginBgImg: require('resources/images/auth/login_bg.png'),
  loginBgMobileImg: require('resources/images/auth/login_bg_mobile.png'),
  signUpBgImg: require('resources/images/auth/signup_bg.png'),
  signUpBgMobileImg: require('resources/images/auth/signup_bg_mobile.png'),
  verifyBgImg: require('resources/images/auth/verify_bg.png'),
  verifyBgMobileImg: require('resources/images/auth/verify_bg_mobile.png'),
  welcomeBgImg: require('resources/images/auth/welcome_bg.png'),
  welcomMobileImg: require('resources/images/auth/welcome_bg_mobile.png'),
  modalCloseIcon: require('resources/images/modal_close.svg').default,
  donationFeatureIcon: require('resources/images/donation_feature_icon.svg').default,
  auctionFeatureIcon: require('resources/images/auction_feature_icon.svg').default,
  raffleFeatureIcon: require('resources/images/raffle_feature_icon.svg').default,
  ticketFeatureIcon: require('resources/images/ticket_feature_icon.svg').default,
  causeFeatureIcon: require('resources/images/causeWithBorder.svg').default,
  premiumPlacementFeatureIcon: require('resources/images/premium_placement_feature_icon.svg').default,
  whiteLabelFeatureIcon: require('resources/images/whitelabel_addToCart.svg').default,
  cartItemFeatureIcon: require('resources/images/cartitemWithborder.svg').default,
  deleteIcon: require('resources/images/delete_bg_icon.svg').default,
  soldOutWithTextIcon: require('resources/images/sold_out_with_text.svg').default,
  ticketIcon: require('resources/images/yellow_tickets_icon.png'),
  dropdownGrayIcon: require('resources/images/dropdown_gray_icon.svg').default,
  dropdownBlueIcon: require('resources/images/dropdown_blue_icon.svg').default,
};
