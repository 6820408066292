import React, { useState, useEffect, useContext } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../../../../../library/common/components/PageLoader';
import EventCard from '../../../../../library/common/components/EventCard';
import { SocketContext } from 'main/context/socket';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import '../../styles.scss';

const TabWrapper = props => {
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  var orgId = params?.id;

  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [organizationEventList, setOrganizationEventList] = useState([]);
  const [offset, setOffset] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 10;

  const navigate = useNavigate();
  const eventTabValue = [
    { id: 1, name: 'Active Events' },
    { id: 3, name: 'Past Events' },
  ];

  useEffect(() => {
    fetchPublicOrganizationDetails(1, activeTab);
  }, []);

  // handle tab change
  const handleToggleTab = tab => {
    setActiveTab(tab);

    fetchPublicOrganizationDetails(1, tab);
  };

  // handle rdirect to event screen
  const handleRedirectToEvent = event => {
    navigate(`/event-details/${event?.id}`);
  };

  // handle fetch public organization details
  const fetchPublicOrganizationDetails = async (page, selectedTab, isUpdateData) => {
    setLoading(true);
    const filterEvent = `${orgId}?offset=${page}&limit=${limit}&sortBy=${selectedTab}`;
    try {
      const { status, data } = await axiosInstance.get(URLS.publicOrganizationList(filterEvent));
      if (status === 200 || status === 201 || status === 304) {
        const { organisations } = data.data;
        setTotalCount(organisations?.count);
        setOffset(page);
        props.setOrganizationData(organisations);

        if (isUpdateData === 'infiniteScroll') {
          setOrganizationEventList(prevData => {
            const updatedData = [...prevData];

            organisations.eventDetails.forEach(newItem => {
              const existingIndex = updatedData.findIndex(item => item?.id === newItem?.id); // Match by unique identifier
              if (existingIndex !== -1) {
                // Update existing item
                updatedData[existingIndex] = { ...updatedData[existingIndex], ...newItem };
              } else {
                // Add new item
                updatedData.push(newItem);
              }
            });

            return updatedData;
          });
        } else {
          setOrganizationEventList(organisations.eventDetails);
        }

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setOrganizationEventList([]);
    }
  };

  return (
    <div className="eventDetailsTab organization-profile-event">
      <div>{loading && <PageLoader />}</div>

      <Nav tabs className={props.white_label ? props.preview_color : 'event-tab border-bottom border-top'}>
        {eventTabValue?.map((item, i) => (
          <NavItem key={i} className={`${activeTab === `${item?.id}` && 'active'}`}>
            <NavLink
              className={props.white_label ? props.preview_color : ''}
              style={{ color: props.white_label ? props.preview_color : '' }}
              onClick={() => {
                handleToggleTab(`${item?.id}`);
              }}>
              {item?.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={activeTab === '3' ? '3' : '1'}>
          {organizationEventList?.length > 0 ? (
            <div className="col-sm-12 p-0">
              {organizationEventList && (
                <InfiniteScroll
                  dataLength={organizationEventList.length}
                  next={() => fetchPublicOrganizationDetails(offset + 1, activeTab, 'infiniteScroll')}
                  hasMore={organizationEventList.length < totalCount}
                  className="">
                  {organizationEventList?.map((item, index) => (
                    <div key={index} onClick={() => handleRedirectToEvent(item)} style={{ cursor: 'pointer' }}>
                      <EventCard event={item} isHorizontal isManaged={false} />
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          ) : (
            !loading && (
              <div className="d-flex justify-content-center mb-4 fw-normal">{OKTION_VARIABLE?.OKTION_NO_EVENT}</div>
            )
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TabWrapper;
