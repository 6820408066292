import React from 'react';
import { images } from 'library/common/constants/ImageConstants';
import '../../styles.scss';

const CongratulationOverlay = () => {
  return (
    <div className="congratulationOverlay">
      <img src={images.congratulation} alt="" />
    </div>
  );
};

export default CongratulationOverlay;
