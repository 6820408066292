import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { ReactComponent as DropDownIcon } from './../../../../resources/images/dropdown_arrow.svg';
import './customtabs.scss';

const CustomTabs = ({ tabs, setActiveTab, activeTab }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 599);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabChanges = activeVal => {
    setActiveTab(activeVal);
  };

  const MenuProps = {
    classes: {
      paper: 'custom-dropdown-tab-wrapper',
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null, // Ensures proper alignment
  };

  return (
    <div className="custom-tab-container no-scrollbar">
      {isMobile ? (
        <Select
          value={activeTab}
          onChange={e => handleTabChanges(e.target.value)}
          className="tab-dropdown"
          id="tab-menu"
          MenuProps={MenuProps}
          IconComponent={DropDownIcon}>
          {tabs?.map((tab, i) => (
            <MenuItem className="tab-dropdown-menu" key={i} value={tab?.id}>
              {tab?.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div className="custom-tab-wrapper">
          {tabs?.map((tab, i) => (
            <Button
              key={i}
              disableRipple
              className={`tab-btn ${tab?.id === activeTab ? 'active-tab-btn' : ' '}`}
              onClick={() => handleTabChanges(tab?.id)}>
              {tab?.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTabs;
