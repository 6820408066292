import React, { useContext } from 'react';
import moment from 'moment';
import { SocketContext } from '../../main/context/socket';
import Countdown from 'react-countdown';
import { CurrencyFormat } from 'utility';
import { EventEmitter } from 'library/common/constants/event';
import { Slider } from '@mui/material';

const EventCountDownDetails = ({ activeTab, eventFeatures, eventOutfit, loading }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const renderTime = ({ hours, minutes, days, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      EventEmitter.dispatch('updateauctionlotdata');
    }
    return (
      <>
        {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
          <>
            {activeTab === '2' ? (
              <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_AUCTION_HAS_ENDED}</h4>
            ) : activeTab === '3' ? (
              <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_DONATION_PERIOD_HAS_ENDED}</h4>
            ) : activeTab === '4' ? (
              <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_RAFFLE_HAS_ENDED}</h4>
            ) : activeTab === '5' ? (
              <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_TICKET_SALE_PERIOD_HAS_ENDED}</h4>
            ) : (
              ''
            )}
          </>
        ) : (
          <div>
            {days > 0 ? days : 0}{' '}
            {days === 0 || days === 1 ? OKTION_VARIABLE?.OKTION_DAY : OKTION_VARIABLE?.OKTION_DAYS}{' '}
            {hours > 0 ? hours : 0}{' '}
            {hours === 0 || hours === 1 ? OKTION_VARIABLE?.OKTION_HOUR : OKTION_VARIABLE?.OKTION_HOURS}{' '}
            {minutes > 0 ? minutes : 0}{' '}
            {minutes === 0 || minutes === 1 ? OKTION_VARIABLE?.OKTION_MINUTE : OKTION_VARIABLE?.OKTION_MINUTES}{' '}
            {OKTION_VARIABLE?.OKTION_LEFT}
          </div>
        )}
      </>
    );
  };

  const handleEventStartDate = activeTab => {
    var donationFeatureData = Array.isArray(eventFeatures) && eventFeatures?.filter(item => item?.type === 'donation');
    var auctionFeatureData = Array.isArray(eventFeatures) && eventFeatures?.filter(item => item?.type === 'auction');
    var raffleFeatureData = Array.isArray(eventFeatures) && eventFeatures?.filter(item => item?.type === 'raffle');

    let DATE_NOW = moment(new Date()).format('YYYY-MM-DD');
    let CURRENT_TIME = moment(new Date()).format('HHmm');
    let EVENT_END_DATE = moment(eventOutfit?.endDate).format('YYYY-MM-DD');
    let EVENT_END_TIME = moment(eventOutfit?.endDate).format('HHmm');

    switch (activeTab) {
      case '2':
        return (
          <>
            {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
              <div>
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_AUCITON}</p>
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {eventOutfit?.raisedAmountStatus === 1 && (
                    <>
                      <CurrencyFormat
                        value={eventOutfit?.auctionAmountRaised || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                          eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                        }`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </>
                  )}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
            <div>
              <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_AUCTION}</p>
              <h4
                className="event-count-time-value"
                style={{
                  color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                }}>
                {auctionFeatureData && auctionFeatureData[0]?.isActive === true && (
                  <>
                    <Countdown
                      date={auctionFeatureData && auctionFeatureData[0]?.endTime}
                      renderer={renderTime}
                      key={auctionFeatureData && auctionFeatureData[0]?.endTime}
                    />
                  </>
                )}
              </h4>{' '}
              {auctionFeatureData && auctionFeatureData[0]?.isActive === false ? (
                moment(auctionFeatureData && auctionFeatureData[0]?.startTime).isSameOrAfter(moment()) === false ? (
                  <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_AUCTION_HAS_ENDED}</h4>
                ) : (
                  <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_NOT_STARTED}</h4>
                )
              ) : null}
            </div>
          </>
        );
      case '3':
        return (
          <>
            {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
              <div>
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TOTAL_RAISED_DONATION}</p>
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {eventOutfit?.raisedAmountStatus === 1 && (
                    <>
                      {' '}
                      <CurrencyFormat
                        value={eventOutfit?.donationAmountRaised || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                          eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                        }`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </>
                  )}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
            <div>
              <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_DONATION}</p>
              <h4
                className="event-count-time-value"
                style={{
                  color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                }}>
                {donationFeatureData && donationFeatureData[0]?.isActive === true && (
                  <>
                    <Countdown
                      date={donationFeatureData && donationFeatureData[0]?.endTime}
                      renderer={renderTime}
                      key={donationFeatureData && donationFeatureData[0]?.endTime}
                    />
                  </>
                )}
              </h4>{' '}
              {donationFeatureData && donationFeatureData[0]?.isActive === false ? (
                moment(donationFeatureData && donationFeatureData[0]?.startTime).isSameOrAfter(moment()) === false ? (
                  <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_DONATION_PERIOD_HAS_ENDED}</h4>
                ) : (
                  <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_DONATION_NOT_STARTED}</h4>
                )
              ) : null}
            </div>
          </>
        );
      case '4':
        let today = moment(new Date()).format('YYYY-MM-DD');
        let currentTime = moment(new Date()).format('HHmm');
        let raffleDrawDate = moment(raffleFeatureData[0]?.startTime).format('YYYY-MM-DD');
        let raffleDrawTime = moment(raffleFeatureData[0]?.startTime).format('HHmm');

        return (
          <>
            {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
              <div>
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_RAFFLE}</p>
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {eventOutfit?.raisedAmountStatus === 1 && (
                    <>
                      {' '}
                      <CurrencyFormat
                        value={eventOutfit?.raffleAmountRaised || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                          eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                        }`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </>
                  )}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
            <div>
              <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_DASHBOARD_TIME_REMAINING} </p>
              {moment(raffleDrawDate).isAfter(today) ? (
                <>
                  <h4
                    className="event-count-time-value"
                    style={{
                      color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                    }}>
                    <Countdown
                      date={raffleFeatureData && raffleFeatureData[0]?.startTime}
                      renderer={renderTime}
                      key={raffleFeatureData && raffleFeatureData[0]?.startTime}
                    />
                  </h4>
                </>
              ) : (
                <>
                  {today === raffleDrawDate && raffleDrawTime > currentTime ? (
                    <h4
                      className="event-count-time-value"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      <Countdown
                        date={raffleFeatureData && raffleFeatureData[0]?.startTime}
                        renderer={renderTime}
                        key={raffleFeatureData && raffleFeatureData[0]?.startTime}
                      />
                    </h4>
                  ) : (
                    <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_RAFFLE_HAS_ENDED}</h4>
                  )}
                </>
              )}
            </div>
          </>
        );
      case '5':
        return (
          <>
            {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
              <div>
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_TICKET}</p>
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {eventOutfit?.raisedAmountStatus === 1 && (
                    <>
                      <CurrencyFormat
                        value={eventOutfit?.ticketAmountRaised || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                          eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                        }`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </>
                  )}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
            <div>
              <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_FOREVENT}</p>
              {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  <Countdown date={eventOutfit?.venueEndDate} renderer={renderTime} key={eventOutfit?.venueEndDate} />
                </h4>
              ) : (
                <>
                  {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                    <h4
                      className="event-count-time-value"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      <Countdown
                        date={eventOutfit?.venueEndDate}
                        renderer={renderTime}
                        key={eventOutfit?.venueEndDate}
                      />
                    </h4>
                  ) : (
                    <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_EVENT_HAS_FINISHED}</h4>
                  )}
                </>
              )}
            </div>
          </>
        );
      case '6':
        return (
          <>
            {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
              <div>
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TOTAL_AMOUNT_RAISED}</p>
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {eventOutfit?.raisedAmountStatus === 1 && (
                    <>
                      <CurrencyFormat
                        value={eventOutfit?.amountRaised || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                          eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                        }`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </>
                  )}

                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
            <div>
              <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TOTAL_TIME_REMAINING}</p>
              {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                <h4
                  className="event-count-time-value"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                </h4>
              ) : (
                <>
                  {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                    <h4
                      className="event-count-time-value"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                    </h4>
                  ) : (
                    <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_EVENT_HAS_FINISHED}</h4>
                  )}
                </>
              )}
            </div>
          </>
        );
      default:
        return (
          !loading &&
          eventOutfit && (
            <>
              {(eventOutfit?.raisedAmountStatus === 1 || eventOutfit?.targetAmountStatus === 1) && (
                <div>
                  <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TOTAL_AMOUNT_RAISED}</p>

                  <h4
                    className="event-count-time-value"
                    style={{
                      color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                    }}>
                    {eventOutfit?.raisedAmountStatus === 1 && (
                      <>
                        <CurrencyFormat
                          value={eventOutfit?.amountRaised || '0.00'}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                            eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                          }`}
                          thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />{' '}
                        {OKTION_VARIABLE?.OKTION_RAISED}
                      </>
                    )}
                    {eventOutfit?.targetAmountStatus === 1 && (
                      <>
                        {' '}
                        {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && '/'}{' '}
                        <CurrencyFormat
                          value={eventOutfit?.targetAmount || '0.00'}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                          thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />{' '}
                        {OKTION_VARIABLE?.OKTION_TARGET}
                      </>
                    )}
                  </h4>
                  {eventOutfit?.raisedAmountStatus === 1 && eventOutfit?.targetAmountStatus === 1 && (
                    <div>
                      <Slider
                        className="total-amt-raised-slider"
                        defaultValue={eventOutfit?.amountRaised}
                        max={eventOutfit?.targetAmount}
                        disabled
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="">
                <p className="event-count-time-title">{OKTION_VARIABLE?.OKTION_TOTAL_TIME_REMAINING}</p>
                {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                  <h4
                    className="event-count-time-value"
                    style={{
                      color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                    }}>
                    <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                  </h4>
                ) : (
                  <>
                    {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                      <h4
                        className="event-count-time-value"
                        style={{
                          color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                        }}>
                        <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                      </h4>
                    ) : (
                      <h4 className="event-close-text">{OKTION_VARIABLE?.OKTION_EVENT_HAS_FINISHED}</h4>
                    )}
                  </>
                )}
              </div>
            </>
          )
        );
    }
  };

  return handleEventStartDate(activeTab);
};

export default EventCountDownDetails;
