import React, { useContext, useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import QRCode from 'react-qr-code';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { images } from '../../../../library/common/constants/ImageConstants';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { SocketContext } from 'main/context/socket';
import { useReactToPrint } from 'react-to-print';
import SingleTicketPrintComponent from './SingleTicketPrintComponent';

const pageStyle = `
body {
  -webkit-print-color-adjust: exact;
}
@page { size: 350mm 400mm; }
`;

const TicketDataModal = ({ isToggle, toggle, ticketItemData, eventDetails, raffleDrawDate }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const componentRef = useRef(null);

  const qrString = JSON.stringify({
    ticketNumber: ticketItemData?.ticketNumber,
    ticketId: ticketItemData?.id,
  });

  const venueAddress = [
    eventDetails?.venue?.addressLine1,
    eventDetails?.venue?.addressLine2,
    eventDetails?.venue?.city,
    eventDetails?.venue?.state,
    eventDetails?.venue?.country,
    eventDetails?.venue?.postCode,
  ];

  const reactToPrintFn = useReactToPrint({ contentRef: componentRef, pageStyle: pageStyle });

  return (
    <Modal className="event-details-ticket-modal" isOpen={isToggle}>
      <div className="event-details-ticket-heading">
        <p className="event-details-ticket-title cursor-pointer" onClick={() => reactToPrintFn()}>
          {OKTION_VARIABLE?.OKTION_TICKETS_PRINT_TICKETS}
        </p>

        <img className="cursor-pointer modal-close-icon" onClick={toggle} src={images.modalCloseIcon} alt="" />
      </div>

      {/* It's single print component */}
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="your-tickets-print-view-container">
          <SingleTicketPrintComponent
            ticketItemData={ticketItemData}
            eventDetails={eventDetails}
            venueAddress={venueAddress}
          />
        </div>
      </div>

      <ModalBody>
        <div className="modal-your-tickets-wrapper">
          <div className="modal-your-tickets-left-side">
            <div className="modal-your-tickets-img-wrapper">
              <img
                src={
                  eventDetails?.eventImages !== null && identifiers.imageUrlConstant + eventDetails?.eventImages[0].name
                }
                alt={OKTION_VARIABLE?.OKTION_EVENT_IMAGE}
                className=""
                onError={e => ((e.target.src = images?.logo), (e.target.className = 'error-bg-img'))}
              />
            </div>
            <div className="modal-your-tickets-qr-code">
              <QRCode size={131} value={qrString} />
            </div>
            <div className="modal-your-tickets-details">
              <p className="modal-your-tickets-title">{OKTION_VARIABLE?.OKTION_TICKET_NUMBER}</p>
              <p className="modal-your-tickets-value">{ticketItemData?.ticketNumber}</p>
            </div>
          </div>
          <div className="modal-your-tickets-right-side">
            <div className="modal-your-ticket-event-name">
              {eventDetails?.eventName &&
                parse(
                  anchorme({
                    input: (eventDetails?.eventName).toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </div>
            <p className="modal-your-ticket-name"> {ticketItemData?.ticketName}</p>
            <div className="modal-your-ticket-time-continer">
              <div>
                <p className="modal-your-ticket-time-title">{OKTION_VARIABLE?.OKTION_FROM_CAPITAL}</p>
                <p className="modal-your-ticket-date">
                  {raffleDrawDate
                    ? raffleDrawDate
                    : moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format(
                        'DD MMMM YYYY',
                      )}
                </p>{' '}
                <p className="modal-your-ticket-time">
                  {raffleDrawDate
                    ? raffleDrawDate
                    : moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format('hh:mm A')}
                </p>
              </div>
              <div>
                <p className="modal-your-ticket-time-title">{OKTION_VARIABLE?.OKTION_VENUE_CAPITAL}</p>
                <p className="modal-your-ticket-date mb-0">
                  {Array.isArray(venueAddress)
                    ? venueAddress?.map((line, index) => <span key={index}>{line} </span>)
                    : venueAddress}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TicketDataModal;
