import React from 'react';
import { Modal, ModalBody, Collapse } from 'reactstrap';
import { images } from '../../../../library/common/constants/ImageConstants';
import PageLoader from '../../../../library/common/components/PageLoader';
import Notice from '../../../../library/common/components/Notice';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from '../../../../library/common/constants/function';
import { CustomTextField } from 'library/common/components/textfield';
import { Button } from '@mui/material';

class SendTicketModal extends React.Component {
  state = {
    isValidateCall: false,
    donationTicketBundle: [
      {
        amount: '',
        name: '',
        toggleBundle: true,
        status: '',
        mobile: '',
        email: '',
      },
    ],
    formData: {},
    labelVisible: false,
    loading: false,
    ErrorMessage: [],
  };
  componentDidMount() {
    this.setState({ donationTicketBundle: this.props.ticketData });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.ticketData !== this.props.ticketData) {
      this.setState({ donationTicketBundle: this.props.ticketData });

      // Maps the ticket data and updates the formData state
      var TICKET_DEFAULT_DATA = this.props.ticketData;
      TICKET_DEFAULT_DATA.map((item, i) => {
        let nameId = `name-${i}`,
          mobileId = `mobile-${i}`,
          emailId = `email-${i}`;

        // Creating form data object dynamically for each ticket
        const TEMPOBJ = {
          [nameId]: item?.name,
          [mobileId]: item?.phoneNumber,
          [emailId]: item?.email,
        };
        // Updating formData state with new ticket information
        this.setState(state => ({
          formData: {
            ...state.formData,
            ...TEMPOBJ,
          },
        }));
        return item;
      });
    }
  }

  // Validation function for form inputs
  formDataValidate = () => {
    let flag = true;
    let ErrorMessages = [];
    const { formData } = this.state;
    var donationTicketBundleLength = this.state.donationTicketBundle.length;
    let name = '';
    let email = '';
    let amount = '';
    let mobile = '';

    // Loop through each ticket bundle and validate its fields
    for (var i = 0; i < donationTicketBundleLength; i++) {
      let { OKTION_VARIABLE } = this.props;
      amount = `amount-${i}`;
      name = `name-${i}`;
      mobile = `mobile-${i}`;
      email = `email-${i}`;

      // Check if name is filled
      if (typeof formData[name] === 'undefined' || formData[name] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [name]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      }

      // Check if email is filled and valid
      if (typeof formData[email] === 'undefined' || formData[email] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      } else if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formData[email])) {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_EMAIL_VALID };
      }
      // Update error messages in state
      this.setState({ ErrorMessage: ErrorMessages });
    }
    return flag;
  };

  // Toggles the visibility of the ticket bundle's content
  handleToggleBundle = idx => {
    let donationTicketBundle = [...this.state.donationTicketBundle];
    for (let i = 0; i < donationTicketBundle.length; i++) {
      if (idx === i) {
        donationTicketBundle[i].toggleBundle = !donationTicketBundle[i].toggleBundle;
      }
    }
    this.setState({ donationTicketBundle });
  };

  // Handles input changes for the form and updates the formData state
  handleInputChange = (event, idx) => {
    let { name, value } = event.target;
    this.setState(state => ({
      formData: {
        ...state.formData,
        [name]: value,
      },
      labelVisible: true,
    }));

    this.setState(prevState => {
      const updatedBundle = [...prevState.donationTicketBundle];
      const fieldKey = name.split('-')[0]; // Extract 'name', 'mobile', or 'email'

      // Update the specific field for the correct item
      updatedBundle[idx] = {
        ...updatedBundle[idx],
        [fieldKey]: value, // Update name, mobile, or email dynamically
      };

      return {
        donationTicketBundle: updatedBundle,
      };
    });
  };

  // Handles changes specifically for ticket bundle inputs and triggers validation if needed
  handleChange = e => {
    const { isValidateCall } = this.state;
    if (['name form-control', 'amount form-control', 'email form-control'].includes(e.target.className)) {
      let donationTicketBundle = [...this.state.donationTicketBundle];
      donationTicketBundle[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value;
      this.setState({ donationTicketBundle }, () => {
        isValidateCall && this.formDataValidate();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  // Submits the form, sends the data to the API, and handles success/error responses
  handleSubmit = async () => {
    var { OKTION_VARIABLE } = this.props;
    if (this.formDataValidate()) {
      this.setState({ loading: true });
      var sendData = [];

      let name = '';
      let email = '';
      let amount = '';
      let mobile = '';

      // Prepares the data to be sent to the API based on the ticket bundle info
      sendData = this.state.donationTicketBundle.map((item, index) => {
        name = `name-${index}`;
        mobile = `mobile-${index}`;
        email = `email-${index}`;
        return {
          ticketId: item?.id,
          email: item?.email,
          mobileNumber: parseInt(item?.mobile),
          name: item?.name,
        };
      });

      try {
        const { status } = await axiosInstance.put(URLS.purchaseTicketUpdate, sendData);
        this.setState({ loading: false });
        if (status === 200 || status === 304) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_TICKET_SEND);

          setTimeout(() => {
            this.props.toggle();
          }, 4000);
        }
      } catch (err) {
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { OKTION_VARIABLE } = this.props;
    const { isToggle, toggle } = this.props;
    const { loading, donationTicketBundle, ErrorMessage, formData } = this.state;

    return (
      <>
        {loading && <PageLoader />}
        <div>
          <Modal className="sent-tickets-modal" isOpen={isToggle}>
            <div className="sent-tickets-heading">
              <h2 className="sent-tickets-title">{OKTION_VARIABLE?.OKTION_TICKET_PURCHASE}</h2>
              <img className="cursor-pointer modal-close-icon" onClick={toggle} src={images.modalCloseIcon} alt="" />
            </div>
            <ModalBody>
              <div className="sent-tickets-wrapper">
                {donationTicketBundle.length > 0 ? (
                  <>
                    <Notice
                      title={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST}
                      image={images.bulb}
                      message={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST_DETAILS}
                    />{' '}
                    <div className="sent-tickets-form-field-wrapper">
                      {donationTicketBundle.map((val, idx) => {
                        let nameId = `name-${idx}`,
                          mobileId = `mobile-${idx}`,
                          emailId = `email-${idx}`;

                        return (
                          <div className="sent-tickets-form-accordion" key={idx}>
                            <div
                              className="sent-tickets-form-accordion-title cursor-pointer"
                              onClick={() => this.handleToggleBundle(idx)}>
                              <h5>{`${val?.ticketNumber} ( ${val?.ticketName} )`}</h5>

                              <div className="accordian_arrow cursor-pointer">
                                <img className={`${!val.toggleBundle && 'down'}`} alt="" src={images.uppArraow} />
                              </div>
                            </div>
                            <div>
                              <Collapse isOpen={val.toggleBundle}>
                                <form onChange={this.handleChange}>
                                  <div className="sent-tickets-form-accordion-field">
                                    <CustomTextField
                                      fullWidth
                                      name={nameId}
                                      id={nameId}
                                      value={val.name || ''}
                                      className="custom-textfield"
                                      type="text"
                                      placeholder={OKTION_VARIABLE?.OKTION_TICKET_FULLNAME_REQUIRED}
                                      label={OKTION_VARIABLE?.OKTION_TICKET_FULLNAME_REQUIRED}
                                      onChange={e => this.handleInputChange(e, idx)}
                                      error={Boolean(ErrorMessage[nameId])}
                                      helperText={ErrorMessage[nameId]}
                                    />
                                  </div>
                                  <div className="sent-tickets-form-accordion-field">
                                    <CustomTextField
                                      fullWidth
                                      name={mobileId}
                                      id={mobileId}
                                      value={val.mobile || null}
                                      type="number"
                                      className="custom-textfield"
                                      placeholder={OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}
                                      label={OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}
                                      onChange={e => this.handleInputChange(e, idx)}
                                      error={Boolean(ErrorMessage[nameId])}
                                      helperText={ErrorMessage[nameId]}
                                    />
                                  </div>
                                  <div className="sent-tickets-form-accordion-field">
                                    <CustomTextField
                                      fullWidth
                                      name={emailId}
                                      id={emailId}
                                      value={val.email || ''}
                                      type="text"
                                      className="custom-textfield"
                                      placeholder={OKTION_VARIABLE?.OKTION_EMAIL_REQUIRED}
                                      label={OKTION_VARIABLE?.OKTION_EMAIL_REQUIRED}
                                      onChange={e => this.handleInputChange(e, idx)}
                                      error={Boolean(ErrorMessage[emailId])}
                                      helperText={ErrorMessage[emailId]}
                                    />
                                  </div>
                                </form>
                              </Collapse>
                            </div>
                          </div>
                        );
                      })}
                      <div className="ticket-btn">
                        <Button
                          fullWidth
                          className="btn-primary"
                          onClick={e => {
                            this.handleSubmit();
                            this.setState({ isValidateCall: true });
                          }}>
                          {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <h3 className="text-center mb-4">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</h3>
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

const SendTicketModalWithSocket = props => (
  <SocketContext.Consumer>
    {userSocket => <SendTicketModal {...props} userSocket={userSocket.socket} OKTION_VARIABLE={userSocket?.messages} />}
  </SocketContext.Consumer>
);

export default SendTicketModalWithSocket;
