import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import SearchBox from './Frame/Search/SearchBox';
import EventTimeDetails from '../EventTimeDetails';
import { images } from 'library/common/constants/ImageConstants';
import { getUserCards } from 'library/common/actions/UserCardsAction';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../../main/context/socket';
import PageLoader from 'library/common/components/PageLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select, { components } from 'react-select';
import { ReactComponent as DropDownIcon } from '../../../../resources/images/dropdown_arrow.svg';
import ItemLotCards from '../../../../library/common/components/ItemLotCards/ItemLotCards';
import './styles.scss';

const Auction = props => {
  const params = useParams();
  const location = useLocation();
  var eventId = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const token = fetchFromStorage(identifiers.token);
  const userData = fetchFromStorage(identifiers.userDetail);

  const [loading, setLoading] = useState(false);
  const [itemFollowingCardData, setItemFollowingCardData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isabout, setIsAbout] = useState(true);
  const [auctionData, setAuctionData] = useState([]);
  const [selectedCatValue, setSelectedCatValue] = useState('');
  const [sortVal, setSortVal] = useState('PO');
  const [searchVal, setSearchVal] = useState('');
  const [offset, setOffset] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 12;

  useEffect(() => {
    props.getUserCards();
    fetchCategoryList();
    fetchAuctionDetails(offset, sortVal, selectedCatValue, searchVal);
  }, []);

  const offsetRef = useRef(offset);

  useEffect(() => {
    offsetRef.current = offset; // Update the ref whenever offset changes
  }, [offset]);

  useEffect(() => {
    const handleSocketUpdate = event => {
      const newItem = event?.item;
      if (!newItem || !newItem?.id) return; // Skip if invalid data

      // Update `setItemFollowingCardData` with specific properties (myBidCount, myCurrentCount)
      setItemFollowingCardData(prevData => {
        const updatedData = prevData.map(item => {
          if (item?.id === newItem?.id) {
            // fetchAuctionDetails(offsetRef.current, sortVal, selectedCatValue, searchVal, 'isSocketUpdate');
            // Update only the relevant fields to bidding
            const isCurrentUser = userData?.id === newItem?.userBid?.userId;
            return {
              ...item,
              myCurrentBid: isCurrentUser ? (newItem?.userBid?.myCurrentBid ?? item.myCurrentBid) : item.myCurrentBid,
              myMaxBid: isCurrentUser ? (newItem?.userBid?.myMaxBid ?? item.myMaxBid) : item.myMaxBid,
              currentBid: newItem.currentBid ?? item.currentBid,
              reserveMet: newItem?.reserveMet ?? item.reserveMet,
              winner: {
                ...item.winner,
                ...newItem.winner,
              },
            };
          }
          return item; // Return unchanged item if ID doesn't match
        });
        // Optionally, dispatch the event if needed
        EventEmitter.dispatch('updateBiddingData', updatedData);

        return updatedData;
      });
    };

    EventEmitter.subscribe('socketUpdateBiddingData', handleSocketUpdate);

    return () => {
      EventEmitter.unsubscribe('socketUpdateBiddingData', handleSocketUpdate);
    };
  }, [sortVal, selectedCatValue, searchVal]);

  // Fetch category list for the auction event
  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getCategoryPublic(eventId));
      if (status === 200 || status === 201) {
        const categoryData =
          data?.data?.categories?.length > 0
            ? [
                { value: 'all', label: OKTION_VARIABLE?.OKTION_ALL_CATEGORY }, // Add your custom object here
                ...data?.data?.categories?.map(c => {
                  return { value: c?.id, label: c?.categoryName, ...c };
                }),
              ]
            : [];
        setCategoryList(categoryData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Function to get auction details for the event
  const fetchAuctionDetails = async (page, sortBy = 'PO', categoryBy, searchBy = '', isUpdateData) => {
    const previewStatus = location?.state?.previewStatus ?? 0;
    var queryString = `?token=${token?.token ? token?.token : ''}&previewStatus=${previewStatus}&sort=${sortBy}&category=${categoryBy}&search=${searchBy}&offset=${page}&limit=${limit}`;
    setOffset(page);
    if (isUpdateData !== 'isSocketUpdate') setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(URLS.auctionAll(eventId, queryString));
      if (status === 200 || status === 201) {
        setAuctionData(data);

        const newAuctionOptions = data?.data?.eventFeatures[0]?.auctionOptions;

        // Update auction data without duplicates
        if (isUpdateData === 'isSocketUpdate' || isUpdateData === 'infiniteScroll') {
          setItemFollowingCardData(prevData => {
            const updatedData = [...prevData];

            newAuctionOptions.forEach(newItem => {
              const existingIndex = updatedData.findIndex(item => item?.id === newItem?.id); // Match by unique identifier
              if (existingIndex !== -1) {
                // Update existing item
                updatedData[existingIndex] = { ...updatedData[existingIndex], ...newItem };
              } else {
                // Add new item
                updatedData.push(newItem);
              }
            });
            EventEmitter.dispatch('updateBiddingData', updatedData);
            return updatedData;
          });
        } else {
          EventEmitter.dispatch('searchFilterData');
          setItemFollowingCardData(newAuctionOptions);
        }

        setTotalCount(data?.data?.eventFeatures[0]?.auctionOptionsTotalRecords);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // Handle sort by for filtering auction items
  const handleSortByChange = e => {
    setSortVal(e.value);
    fetchAuctionDetails(1, e.value, selectedCatValue, searchVal);
  };

  // Handle category change
  const handleCategoryChange = value => {
    const selectCat = value !== undefined ? value : '';
    setSelectedCatValue(selectCat);
    fetchAuctionDetails(1, sortVal, selectCat, searchVal);
  };

  // Search auction items based on input value and category selection
  const handleOnSearchChange = value => {
    setSearchVal(value);
    fetchAuctionDetails(1, sortVal, selectedCatValue, value);
  };

  // Dropdown Indicator component for custom styling
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropDownIcon />
      </components.DropdownIndicator>
    );
  };

  const sortFilterOptions = [
    { id: 1, value: 'PO', label: OKTION_VARIABLE?.OKTION_PUBLISHED_ORDER },
    { id: 1, value: 'PVLTOH', label: OKTION_VARIABLE?.OKTION_PRICE_LOWTO_HIGH },
    { id: 1, value: 'PVHTOL', label: OKTION_VARIABLE?.OKTION_PRICE_HIGHTO_LOW },
    { id: 1, value: 'BHTOL', label: OKTION_VARIABLE?.OKTION_HOTTEST },
    { id: 1, value: 'FF', label: OKTION_VARIABLE?.OKTION_FEATURE_FIRST },
  ];

  return (
    <>
      {loading && <PageLoader />}

      <div className="event-about-container">
        {/* event time and venu details */}
        <div className="event-time-venu-details">
          <EventTimeDetails
            img={images.watch}
            title={OKTION_VARIABLE?.OKTION_AUCTION_DATE}
            startDate={moment(auctionData?.data?.eventFeatures?.[0]?.startTime).format('DD/MM/YYYY hh:mm A')}
            endDate={moment(auctionData?.data?.eventFeatures?.[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
            isLocation={false}
          />
        </div>
        <div className="event-about-details">
          {auctionData?.data?.eventFeatures?.[0]?.featureName && (
            <div
              className="d-flex align-items-center justify-content-between cursor-pointer mb18"
              onClick={() => {
                setIsAbout(!isabout);
              }}>
              <h4 className="event-about-title">{auctionData?.data?.eventFeatures?.[0]?.featureName}</h4>
              <img
                className={`ml18 mr18 ${!isabout ? 'collapse-closed' : ''}`}
                src={images?.uppArraow}
                alt="collapse arrow"
                width="15px"
              />
            </div>
          )}
          {auctionData?.data?.eventFeatures?.[0]?.featureAbout != null && (
            <Collapse isOpen={isabout}>
              <div>
                {auctionData?.data?.eventFeatures?.[0]?.featureAbout != null ? (
                  <div className="event-about-description">
                    {parse(
                      anchorme({
                        input: (auctionData?.data?.eventFeatures?.[0]?.featureAbout).toString().replace(/\n/g, '<br/>'),
                        options: {
                          attributes: {
                            target: '_blank',
                            class: 'detected',
                          },
                        },
                      }),
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Collapse>
          )}
        </div>

        <div className="event-details-auction-feature">
          <h4 className="feature-auction-title">{OKTION_VARIABLE?.OKTION_AUCTION_LOT}</h4>
          <SearchBox
            handleSelect={handleCategoryChange}
            onSearch={handleOnSearchChange}
            eventDetails={props?.eventDetails}
            categoryList={categoryList}
          />
          <div className="sort-filter-container">
            <p className="sort-by-text fs-16">{OKTION_VARIABLE?.OKTION_SORT_BY}: &nbsp;</p>

            <Select
              className="React sort-select"
              value={sortFilterOptions?.find(item => {
                return item?.value === sortVal;
              })}
              onChange={e => handleSortByChange(e)}
              options={sortFilterOptions}
              classNamePrefix="select"
              placeholder="Select"
              components={{ DropdownIndicator }}
            />
          </div>

          {itemFollowingCardData && itemFollowingCardData?.length > 0 ? (
            <>
              <InfiniteScroll
                dataLength={itemFollowingCardData.length}
                next={() => fetchAuctionDetails(offset + 1, sortVal, selectedCatValue, searchVal, 'infiniteScroll')}
                hasMore={itemFollowingCardData.length < totalCount}
                className="auction_lot_items overflow-x-hidden">
                <div className="auction-lot-card-container">
                  {itemFollowingCardData?.map((item, index) => (
                    <div key={index} className="auction-lot-card-wrapper">
                      <ItemLotCards
                        cardItemData={item}
                        isBid={true}
                        categoryList={categoryList}
                        index={index}
                        eventFeatures={auctionData?.data?.eventFeatures}
                        eventDetailsData={props?.eventDetails}
                        userCards={props?.userCards}
                        whitelabel={props?.whitelabel}
                        eventOutfit={props?.eventDetails}
                        isDragable={false}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <h3 className="no-data-found">{OKTION_VARIABLE?.OKTION_AUCTION_LOTNOT_FOUND}</h3>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ userCardsReducer }) => {
  return {
    userCards: userCardsReducer.userCards,
  };
};

export default connect(mapStateToProps, { getUserCards })(Auction);
