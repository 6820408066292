import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { Button } from '@mui/material';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import EventTimeDetails from '../EventTimeDetails';
import DonationAddCard from './DonationAddCard';
import LogoutModal from '../Raffle/LogoutModal';
import PageLoader from 'library/common/components/PageLoader';
import { SocketContext } from '../../../../main/context/socket';
import { images } from 'library/common/constants/ImageConstants';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from '../../../../main/axios';
import { setApiMessage } from 'library/common/constants/function';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { CustomTextField } from 'library/common/components/textfield';
import './styles.scss';

// Custom hook for forcing component updates
const useForceUpdate = () => {
  let [value, setState] = useState(true);
  return () => setState(!value);
};

const Donations = ({ eventFeatures, eventDetails, whitelabel, timeZone, isLoggedIn }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const [isToggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [donationAmountArray, setDonationAmountArray] = useState([]);
  const [donationAmountArrayOther, setDonationAmountArrayOther] = useState([]);
  const [cartItem, setClickItemData] = useState('');
  const [isabout, setIsAbout] = useState(true);

  var eventId = params?.id;

  const userDetail = fetchFromStorage(identifiers?.userDetail);

  // Initialize donation amounts on component mount
  useEffect(() => {
    initializeDonationAmounts();
  }, [eventFeatures]);

  // Initializes donation amounts from event features
  const initializeDonationAmounts = () => {
    if (!eventFeatures) return;

    const donationFeatureData = eventFeatures?.find(item => item?.type === 'donation');

    const donations = donationFeatureData?.donations || [];

    const { regular, other } = donations.reduce(
      (acc, element) => {
        const enrichedElement = {
          ...element,
          count: 1,
          otherAmount: '',
        };

        if (element?.amount === null) {
          acc.other.push(enrichedElement);
        } else {
          acc.regular.push(enrichedElement);
        }
        return acc;
      },
      { regular: [], other: [] },
    );

    setDonationAmountArray(regular);
    setDonationAmountArrayOther(other);
  };

  const toggle = () => {
    setToggle(!isToggle);
  };

  // Handles user navigation to login
  const handleLoginRedirect = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'donation',
        eventId: eventId,
      },
    });
  };

  // Validates donation form
  const handleValidate = () => {
    const otherDonation = donationAmountArrayOther[0];
    if (!otherDonation?.otherAmount || otherDonation?.otherAmount === 0) {
      setErrorMessage(true);
      return false;
    }
    return true;
  };

  // Handles adding items to cart
  const handleAddToCart = async (eventId, donationId) => {
    if (isLoggedIn && userDetail?.userType === 'normal') {
      // Prepares cart data for checkout
      let donationAmountArrayClone = donationAmountArray;
      let donationAmountArrayOtherClone = donationAmountArrayOther[0];
      var cartArrayClone = donationAmountArrayClone?.filter(item => item?.id === donationId);
      let cartArray = [];
      cartArrayClone?.map(element => {
        let obj = {
          type: 'donation',
          eventId: eventId,
          referenceId: null,
          quantity: element?.count,
          purchaseReferenceId: element?.id,
          isRaffleTicket: false,
        };
        cartArray.push(obj);
      });
      if (donationAmountArrayOtherClone?.otherAmount !== '' && donationId === '') {
        if (handleValidate()) {
          let obj2 = {
            type: 'donation',
            eventId: eventId,
            referenceId: null,
            isRaffleTicket: false,
            quantity: 1,
            purchaseReferenceId: donationAmountArrayOtherClone?.id,
            donationAmount: donationAmountArrayOtherClone?.otherAmount,
          };
          cartArray.push(obj2);
        }
      }

      // Processes checkout for normal users
      setLoading(true);
      try {
        const { status } = await axiosInstance.post(URLS.addToCart, cartArray);
        if (status === 201 || status === 200) {
          setLoading(false);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
        }
      } catch (err) {
        setLoading(false);
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
      }
    } else if (isLoggedIn && userDetail?.userType === 'guest') {
      // Processes checkout for guest users
      updateGuestCardList(donationId);
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    } else {
      // if (handleValidate()) {
      handleSetCartData(eventId, donationId);
      setToggle(true);
      // }
    }
  };

  // Handle set data in cart state for normal user
  const handleSetCartData = (eventId, donationId) => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone?.filter(item => item?.id === donationId);
    let cartArray = [];
    cartArrayClone?.map(element => {
      let obj = {
        type: 'donation',
        quantity: element?.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element?.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.otherAmount !== '' && donationId === '') {
      let obj2 = {
        type: 'donation',
        quantity: 1,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: donationAmountArrayOtherClone?.name,
        amount: donationAmountArrayOtherClone?.otherAmount,
        purchaseReferenceId: donationAmountArrayOtherClone?.id,
        isRaffleTicket: false,
        donationAmount: donationAmountArrayOtherClone?.otherAmount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: donationAmountArrayOtherClone,
        cardCharedAmount: 0,
      };
      cartArray.push(obj2);
    }

    setClickItemData(cartArray);
  };

  // Handle set data in eventemitter for guest user
  const updateGuestCardList = donationId => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone?.filter(item => item?.id === donationId);
    let cartArray = [];
    cartArrayClone?.map(element => {
      let obj = {
        type: 'donation',
        quantity: element?.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element?.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.otherAmount !== '' && donationId === '') {
      let obj2 = {
        type: 'donation',
        quantity: 1,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: donationAmountArrayOtherClone?.name,
        amount: donationAmountArrayOtherClone?.otherAmount,
        purchaseReferenceId: donationAmountArrayOtherClone?.id,
        isRaffleTicket: false,
        donationAmount: donationAmountArrayOtherClone?.otherAmount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: donationAmountArrayOtherClone,
        cardCharedAmount: 0,
      };
      cartArray.push(obj2);
    }

    let singleItem = cartArray;
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (singleItem?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...singleItem];
    } else if (singleItem) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(singleItem);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  // Hnadle check/uncheck donation amount
  const handleCheckedChange = (typeEdit, isMinus, count, id) => {
    let donationAmountArrayClone = donationAmountArray;
    if (typeEdit === 2) {
      donationAmountArrayClone?.map(element => {
        if (element?.id === id) {
          element.count = Number(count);
        }
      });
    } else if (typeEdit === 3) {
      donationAmountArrayClone?.map(element => {
        if (element?.id === id) {
          if (isMinus) {
            element.count = element?.count === 1 ? 1 : element?.count - 1;
          } else {
            element.count = element?.count + 1;
          }
        }
      });
    }
    setDonationAmountArray(donationAmountArrayClone);
    forceUpdate();
  };

  // Hnadle check/uncheck other donation amount
  const handleOtherCheckedChange = (typeEdit, count) => {
    let donationAmountArrayClone = donationAmountArrayOther[0];

    if (typeEdit === 2) {
      donationAmountArrayClone['otherAmount'] = Number(count);
      setErrorMessage(false);
    }
    setDonationAmountArrayOther([donationAmountArrayClone]);
    forceUpdate();
  };

  // Handle onkeydown to check value
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  var donationFeatureData = eventFeatures?.filter(item => item?.type === 'donation');
  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;
  var buttonActive = true;
  //  set active/inactive to cart button
  donationAmountArray?.map(element => {
    if (element.count) {
      buttonActive = false;
    }
  });
  donationAmountArrayOther?.map(element => {
    if (element.count) {
      buttonActive = false;
    }
  });

  return (
    <div className="event-about-container">
      {/* res-org-donation-amt */}
      {/* event time and venu details */}
      {timeZone !== undefined && (
        <div className="event-time-venu-details">
          <EventTimeDetails
            isLocation
            title={OKTION_VARIABLE?.OKTION_DONATION_FINISH_DATE}
            img={images.watch}
            address={moment(donationFeatureData[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
          />
        </div>
      )}
      <div className="event-about-details">
        {donationFeatureData?.[0]?.featureName && (
          <div
            className="d-flex align-items-center justify-content-between cursor-pointer mb18"
            onClick={() => {
              setIsAbout(!isabout);
            }}>
            <h4 className="event-about-title">{donationFeatureData?.[0]?.featureName}</h4>
            <img
              className={`ml18 mr18 ${!isabout ? 'collapse-closed' : ''}`}
              src={images?.uppArraow}
              alt="collapse arrow"
              width="15px"
            />
          </div>
        )}
        {donationFeatureData?.[0]?.featureAbout != null && (
          <Collapse isOpen={isabout}>
            <div>
              {donationFeatureData?.[0]?.featureAbout != null ? (
                <div className="event-about-description">
                  {parse(
                    anchorme({
                      input: (donationFeatureData?.[0]?.featureAbout).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </Collapse>
        )}
      </div>
      <div className="event-details-donation-feature">
        {donationAmountArray && donationAmountArray?.length > 0 && (
          <>
            <h4 className="feature-donation-title">{OKTION_VARIABLE?.OKTION_DONATION_AMOUNTS}</h4>
            <div className="donation-add-amount-card-container">
              {donationAmountArray
                ?.filter(element => {
                  return element?.amount !== 0;
                })
                ?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      pointerEvents: donationFeatureData?.[0]?.isActive === false ? 'none' : '',
                      opacity: donationFeatureData?.[0]?.isActive === false ? '0.4' : '1',
                    }}>
                    <DonationAddCard
                      donationCardData={item}
                      donationFeatureData={donationFeatureData}
                      eventDetails={eventDetails}
                      currency={currency}
                      symbol={symbol}
                      handleCheckedChange={handleCheckedChange}
                      handleAddToCart={handleAddToCart}
                      buttonActive={buttonActive}
                      whitelabel={whitelabel}
                    />
                  </div>
                ))}
            </div>
          </>
        )}

        {donationAmountArrayOther && donationAmountArrayOther.length > 0 && (
          <div
            className="other-donation-add-amount-card-container"
            style={{
              pointerEvents: donationFeatureData?.[0]?.isActive === false ? 'none' : '',
              opacity: donationFeatureData?.[0]?.isActive === false ? '0.4' : '1',
            }}>
            <h4 className="other-donation-amount-title">{OKTION_VARIABLE?.OKTION_CUSTOM_AMOUNT}</h4>
            <div className="mt18 mb18">
              <CustomTextField
                fullWidth
                className="custom-textfield other-donation-count-field"
                onChange={e => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                  e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                  handleOtherCheckedChange(2, e.target.value);
                }}
                onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                pattern="[0-9]*"
                type="number"
                onKeyDown={onKeyPress}
                label={OKTION_VARIABLE?.OKTION_ENTER_AN_AMOUNT}
                placeholder={OKTION_VARIABLE?.OKTION_ENTER_AN_AMOUNT}
                error={Boolean(errorMessage)}
                helperText={errorMessage ? OKTION_VARIABLE?.OKTION_PLEASE_ENTER_AMOUNT : ''}
              />
            </div>
            <Button
              disabled={donationAmountArrayOther?.[0]?.amount || buttonActive || whitelabel === true ? true : false}
              onClick={() => handleAddToCart(donationFeatureData?.[0].eventId, '')}
              className="btn-primary other-donation-add-to-cart-btn"
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          </div>
        )}
      </div>
      {/* Login required modal */}
      <LogoutModal
        isToggle={isToggle}
        toggle={toggle}
        handleClick={handleLoginRedirect}
        isGuestCheckout
        singleItem={cartItem}
      />
      {loading && <PageLoader />}
    </div>
  );
};
const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Donations);
